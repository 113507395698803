import { CaptureLocation } from 'utils/events'

import { KlaviyoEmailRequest, KlaviyoEmailResponse } from 'app/api/klaviyo_email/route'
import { PDPAbandomentResponse } from 'app/api/pdp_abandonment/route'
import { S2SErrorResponse, S2SResponse, S2SSuccessResponse } from 'app/api/s2s/route'
import { SMSSignupResponse } from 'app/api/sms_signup/route'
import { amEcapSubmitError } from 'events/amplitude'
import { datadogReportAPICall, datadogReportEvent } from 'events/datadog'
import { safeErrorMessage } from 'events/error.utils'
import { getProductShopPageData, productPages } from 'products/productPages'
import { extractRegionFromPath, RegionShort } from 'utils/internationalization'
import { getFbIds } from 'utils/reactPixel'

type Event = Record<string, string | object | number | null> | Record<'type', string>

export const sendFBEvents = async (events: Event[]): Promise<S2SResponse> => {
	const eventManagerTestID = sessionStorage.getItem('eventManagerTestID')
	const ids = getFbIds()
	const eventsWithIds: Event[] = []

	events.forEach((event) => {
		eventsWithIds.push({ ...event, ...ids })
	})

	try {
		const res = await fetch('/api/s2s/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
			},
			body: JSON.stringify({ events: eventsWithIds, eventManagerTestID }),
		})
		if (res.ok) {
			const json = (await res.json()) as S2SSuccessResponse

			for (const event of eventsWithIds) {
				const eventType = (event.type as string) ?? 'fbEvents'
				datadogReportEvent('success', eventType, event)
			}

			datadogReportAPICall(res.status, 'fbEvents', eventsWithIds)

			return json
		}

		const json = (await res.json()) as S2SErrorResponse
		return json
	} catch (e) {
		for (const event of eventsWithIds) {
			const eventType = (event.type as string) ?? 'fbEvents'
			datadogReportEvent('failure', eventType, e)
		}
		return {
			success: false,
			error: safeErrorMessage(e),
		}
	}
}

// changed to subscribe users to 2 lists: master and subcribe location
export const subscribeToKlaviyo = async (
	email: string,
	captureLocation: CaptureLocation,
	otherProperties: Partial<KlaviyoEmailRequest> | null = null
): Promise<{
	error: string | null
	body: KlaviyoEmailResponse | null
}> => {
	const region = extractRegionFromPath(window.location.pathname)
	// const fetchUrl = 'https://pz6e20kd92.execute-api.us-east-2.amazonaws.com/default/klaviyo_duplicate'
	const fetchUrl = '/api/klaviyo_email/'
	const body: Partial<KlaviyoEmailRequest> = {
		email,
		captureLocation,
		region,
		...otherProperties,
	}
	try {
		const response = await fetch(fetchUrl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		})
		const json = (await response.json()) as KlaviyoEmailResponse
		datadogReportEvent('success', 'subscribeToKlaviyo', body)
		datadogReportAPICall(response.status, 'subscribeToKlaviyo', body)
		return {
			error: null,
			body: json,
		}
	} catch (e) {
		amEcapSubmitError(JSON.stringify(e))
		datadogReportEvent('failure', 'subscribeToKlaviyo', body)
		return {
			error: safeErrorMessage(e),
			body: null,
		}
	}
}

export const subscribeSMSToKlaviyo = async (
	email: string,
	phone: string,
	code: string,
	amount: string,
	region: RegionShort
): Promise<{
	error: string | null
	body: SMSSignupResponse | null
}> => {
	const fetchUrl = '/api/sms_signup/'
	const body = {
		email,
		phone,
		amount,
		code,
		region,
	}
	try {
		const response = await fetch(fetchUrl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		})
		const json = (await response.json()) as SMSSignupResponse
		datadogReportEvent('success', 'subscribeToKlaviyoSMS', body)
		datadogReportAPICall(response.status, 'subscribeToKlaviyoSMS', body)
		return {
			error: null,
			body: json,
		}
	} catch (e) {
		datadogReportEvent('failure', 'subscribeToKlaviyoSMS', body)
		return {
			error: safeErrorMessage(e),
			body: null,
		}
	}
}

export const sendPDPAbandonmentToKlaviyo = async (
	email: string,
	code: string,
	amount: string,
	region: RegionShort,
	pageViewed: keyof typeof productPages,
	price: string,
	size: string
): Promise<{
	error: string | null
	body: PDPAbandomentResponse | null
}> => {
	const fetchUrl = '/api/pdp_abandonment/'
	const productData = getProductShopPageData(pageViewed)

	const body = {
		email,
		amount,
		code,
		region,
		...productData,
		size,
		price,
	}
	try {
		const response = await fetch(fetchUrl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		})
		const json = (await response.json()) as PDPAbandomentResponse
		datadogReportEvent('success', 'sendPDPAbandonmentToKlaviyo', body)
		datadogReportAPICall(response.status, 'sendPDPAbandonmentToKlaviyo', body)
		return {
			error: null,
			body: json,
		}
	} catch (e) {
		datadogReportEvent('failure', 'sendPDPAbandonmentToKlaviyo', body)
		return {
			error: safeErrorMessage(e),
			body: null,
		}
	}
}
