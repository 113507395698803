import * as AM from './amplitude'
import * as BG from './bing'
import * as FB from './facebook'
import * as GA from './ga'
import * as KY from './klaviyo'
import * as OTT from './ott'
import * as TW from './twitter'

import { KlaviyoEmailRequest } from 'app/api/klaviyo_email/route'
import { CartLineItem, LineItem } from 'cart/types'
import { AffirmLocations, PageViewedType, ProactiveInteractionType } from 'constants/events'
import {
	sendPDPAbandonmentToKlaviyo,
	subscribeSMSToKlaviyo,
	subscribeToKlaviyo,
} from 'eight_api/aws'
import { datadogReportError } from 'events/datadog'
import { safeError } from 'events/error.utils'
import { sha256 } from 'js-sha256'
import { FormattedPrices } from 'prices/types'
import { productPages } from 'products/productPages'
import { createCartLineItemFromLineItem } from 'products/utils'
import { loadExternalScript } from 'utils'
import { CaptureLocation, Product, removeHiddenCharsFromString } from 'utils/events'
import { RegionShort } from 'utils/internationalization'
import { trackFriendbuyEcap } from './friendbuy'

export const updateEmailInLocalStorage = (email: string): void => {
	localStorage.setItem('email', email)
	GA.setDataLayerVariable('customer_email', email)
	GA.setDataLayerVariable('hashed_customer_email', sha256(email))
}

export const trackPageView = (): void => {
	AM.amPageView()
	OTT.ottPageView()
	OTT.ottSiteVisit()
	FB.fbPageView()
	KY.kyPageView()
	TW.twPageView()

	// Pinterest Pageview Tracking
	window['pintrk']?.('track', 'pagevisit')
}

export const trackLinkClick = (to: string, htmlId: string): void => {
	GA.gaTrackClickLink(to)
	AM.amClickLink(to, htmlId)
}

export const trackClickSocialMedia = (site: string): void => {
	GA.gaClickSocialMedia(site)
	AM.amClickSocialMedia(site)
}

export const trackFooterLink = (str: string): void => {
	AM.amClickFooter(str)
}

export const trackClickSupport = (str: string): void => {
	AM.amClickSupport(str)
}

export const trackSubscribeEmail = async (
	email: string,
	location: CaptureLocation
): Promise<void> => {
	GA.gaTrackEmailSubscribe(location)
	AM.amSubscribeEmail(email, location)
	AM.amAddEmailUserProperty(email)
	await subscribeToKlaviyo(email, location)
	OTT.ottEmailConversion(email)
	// send FB event only for all locations (Updated 03/23/2020)
	if (location !== CaptureLocation.CHECKOUT) {
		//FB.fbSubscribe(email, location);
		FB.fbSubscribeEmail(email, location)
		GA.GTMEmailSubscribe()
	}
	updateEmailInLocalStorage(email)
	KY.kyEmailAvailableHandler(email, location)
	AM.setUserIdAsHashedEmail(email)
}

export const trackSubscribeEmailAfterKlaviyoLambda = (
	email: string,
	location: CaptureLocation
): void => {
	GA.gaTrackEmailSubscribe(location)
	AM.amSubscribeEmail(email, location)
	AM.amAddEmailUserProperty(email)
	OTT.ottEmailConversion(email)
	// send FB event only for all locations (Updated 03/23/2020)
	if (location !== CaptureLocation.CHECKOUT) {
		//FB.fbSubscribe(email, location);
		FB.fbSubscribeEmail(email, location)
		GA.GTMEmailSubscribe()
	}
	updateEmailInLocalStorage(email)
	KY.kyEmailAvailableHandler(email, location)
	AM.setUserIdAsHashedEmail(email)
}

export const trackExpandQA = (question: string): void => {
	AM.amClickFaq(question)
	GA.gaExpandFAQ(question)
	FB.fbClickFaq(question)
	KY.kyClickedFAQ(question)
}

export const trackCartOpen = (): void => {
	GA.gaExpandCart()
	AM.amOpenCart()
}

export const trackCartClose = (): void => {
	AM.amCloseCart()
}

export const trackVideoInteraction = (): void => {
	GA.gaPlayVideo()
	AM.amWatchedVideo()
}

export const trackAddToCart = (item: CartLineItem): void => {
	FB.fbAddToCart(item)
	GA.gaAddToCart(item)
	KY.kyAddToCart(item)
	TW.twAddToCart(item)

	// Pinterest Add to Cart Tracking
	window['pintrk']?.('track', 'addtocart', {
		value: item.price,
		order_quantity: item.quantity,
	})

	const productTitle = removeHiddenCharsFromString(item.productTitle)
	const variantTitle = removeHiddenCharsFromString(item.variantTitle)
	const product = {
		name: productTitle + variantTitle,
		quantity: item.quantity,
		price: item.price,
		sku: parseInt(item.id),
		totalAmount: item.quantity * item.price,
		addMembership: false,
	}
	if ('addMembership' in item) {
		product.addMembership = item.addMembership as boolean
	}

	AM.amAddToCartV1(product, true)
}

export const AMamAddToCartV1 = (product: Product): void => {
	AM.amAddToCartV1(product, false)
}

export const trackRemoveFromCart = (item: LineItem, prices: FormattedPrices): void => {
	const cartItem = createCartLineItemFromLineItem(item, prices)
	if (!cartItem) return

	const productTitle = removeHiddenCharsFromString(cartItem.productTitle)
	const variantTitle = removeHiddenCharsFromString(cartItem.variantTitle)
	const product = {
		name: productTitle + variantTitle,
		quantity: cartItem.quantity,
		price: cartItem.price / 100,
		sku: parseInt(cartItem.id),
		totalAmount: cartItem.quantity * cartItem.price,
	}

	AM.removeFromCart(product)
}

export const trackUpdateCartItem = (
	item: LineItem,
	prices: FormattedPrices,
	action: string
): void => {
	const cartItem = createCartLineItemFromLineItem(item, prices)
	if (!cartItem) return

	const productTitle = removeHiddenCharsFromString(cartItem.productTitle)
	const variantTitle = removeHiddenCharsFromString(cartItem.variantTitle)
	const product = {
		name: productTitle + variantTitle,
		quantity: cartItem.quantity,
		price: cartItem.price / 100,
		sku: parseInt(cartItem.id),
		totalAmount: cartItem.quantity * cartItem.price,
		old_quantity: action === 'Increase' ? cartItem.quantity - 1 : cartItem.quantity + 1,
	}

	AM.updateItemInCart(product, action)
}

export const trackABtests = (): void => {
	AM.amABTests()
}

export const trackInitiateCheckout = (lineItems: CartLineItem[]): void => {
	const itemNames = lineItems.map((li) => li.productTitle)
	const variantIds = lineItems.map((li) => li.variantId)
	AM.amInitiateCheckout(itemNames)
	GA.gaCheckoutInitiated()
	GA.GTMCheckoutInitiated()
	OTT.ottCheckoutInitiated(variantIds)
	FB.fbInitiateCheckout(itemNames)
	TW.twInitiateCheckout(lineItems)
	AM.amClickedButton('Checkout', 'cart-checkout-button')
}

export const trackFirstPageVisit = (): void => {
	try {
		const hasFirstLandingPageVisit = localStorage.getItem('firstVisitEpoch')
		if (hasFirstLandingPageVisit === null) {
			AM.amFirstLandingPageVisit()
			localStorage.setItem('firstVisitEpoch', Date.now().toString())
		}
	} catch (e) {
		datadogReportError(safeError(e), 'trackFirstPageVisit')
	}
}

export const trackCoverShopPageVisit = async (properties: {
	code: string
	amount: string
	region: RegionShort
	price: string
	size: string
	model: keyof typeof productPages
}): Promise<ReturnType<typeof sendPDPAbandonmentToKlaviyo> | undefined> => {
	const email = localStorage.getItem('email')
	if (email === null) {
		return undefined
	}
	const { code, amount, region, price, size, model } = properties
	const res = await sendPDPAbandonmentToKlaviyo(email, code, amount, region, model, price, size)
	return res
}

export const trackAccessoryShopPageVisit = async (
	properties: { code: string; amount: string; region: RegionShort; price: string; size: string },
	path: keyof typeof productPages
): Promise<ReturnType<typeof sendPDPAbandonmentToKlaviyo> | undefined> => {
	const email = localStorage.getItem('email')
	if (email === null) {
		return undefined
	}
	const { code, amount, region, price, size } = properties
	const res = await sendPDPAbandonmentToKlaviyo(email, code, amount, region, path, price, size)
	return res
}

// used for fb page views with time requirement for 1+ pages
export const trackPageViewed = (eventName: string, pageType: PageViewedType): void => {
	FB.fbPageView()
	AM.amViewedPageSet(eventName, pageType)
}

export const trackAffirmLearnMoreClick = (location: AffirmLocations): void => {
	let _location = location

	if (!Object.values(AffirmLocations).includes(_location)) {
		_location = AffirmLocations.OTHER
	}

	FB.fbClickAffirmLearnMore(_location)
	AM.amClickAffirmLearnMore(_location)
}

// used for amplitude user property to create cohorts based on blog and site
export const trackWebsiteVisit = (): void => {
	AM.trackHasVisitedSite()
}

export const trackClickedChat = (): void => {
	// check for kustomer body message
	try {
		const messageBody = document.querySelector('#kustomerIconMessageBody')
		if (messageBody) {
			AM.amTrackProactiveChatInteraction(ProactiveInteractionType.CLICKED)
		}
	} catch (e) {
		datadogReportError(safeError(e), 'trackClickedChat')
	}

	AM.amClickedChat()
}

export const trackViewedEmailCap = (modalName: string): void => {
	AM.amViewedEmailCap(modalName)
}

export const trackEmailModalFloatingButtonPress = (): void => {
	AM.amTrackEmailModalFloatingButtonPress()
}

export const trackEmailModalDismissFloatingButtonPress = (): void => {
	AM.amTrackEmailModalDismissFloatingButtonPress()
}

export const trackSubscribeViaEmailCap = async (
	properties: Partial<KlaviyoEmailRequest> & { email: string; location: CaptureLocation },
	type?: string
): Promise<void> => {
	const {
		email,
		location,
		answer,
		emailCapType,
		reasons,
		vanity,
		shouldUseVanityFlow,
		vanityQuote,
		vanityImage,
		vanityName,
		vanityDiscount,
		vanityLink,
		captureLocation,
		image,
		description,
		vanityCode,
		totalVanityDiscount,
		totalDiscount,
		discountCode,
	} = properties
	await subscribeToKlaviyo(email, location, {
		answer,
		emailCapType,
		reasons,
		vanity,
		shouldUseVanityFlow,
		vanityQuote,
		vanityImage,
		vanityName,
		vanityDiscount,
		vanityLink,
		captureLocation,
		image,
		description,
		vanityCode,
		totalVanityDiscount,
		totalDiscount,
		discountCode,
	})
	AM.amAddEmailUserProperty(email)
	AM.amSubscribedViaEmailCap(properties)
	GA.gaTrackEmailSubscribe(location)
	//FB.fbSubscribe(email, location);
	FB.fbSubscribeEmail(email, location)
	if (type !== 'checkout') updateEmailInLocalStorage(email)
	KY.kyEmailAvailableHandler(email, location)
	AM.setUserIdAsHashedEmail(email)
	GA.gaEmailCapSubscribeGoalCompletion()
	GA.GTMEmailSubscribe()
	BG.trackEmailSubscribe(email)
	loadExternalScript(
		'https://tag.simpli.fi/sifitag/4eb86340-b517-013a-9a1b-0cc47abd0334',
		'ott_email_subscribe'
	)
}

export const trackQuizShown = (): void => {
	AM.amEcapQuizShown()
}

export const trackQuizAnswer = (answer: string, question: string): void => {
	AM.amEcapQuizAnswered(answer, question)
}

export const trackSecondaryQuizEcapAnswer = (answer: string, question: string): void => {
	AM.amSecondaryQuizEcapAnswered(answer, question)
}

export const trackSubscribeReferralCap = async (properties: {
	email: string
	name: string
	referralCode: string
}): Promise<void> => {
	const { email, name, referralCode } = properties
	const obj: Partial<KlaviyoEmailRequest> = {
		name,
		answer: name,
		description: 'referred friend',
		referral_modal: 'true',
	}
	await subscribeToKlaviyo(email, CaptureLocation.REFERRAL, obj)
	if (referralCode) {
		obj.referralCode = referralCode
	}
	AM.amSubscribeReferralEmail(email, 'friendbuy ecap', referralCode)
	trackFriendbuyEcap(email)
}

export type KlaviyoSMSRequest = ReturnType<typeof subscribeSMSToKlaviyo>

export const trackSubscribeViaSMSCap = async (properties: {
	phone: string
	email: string
	code: string
	amount: string
	region: RegionShort
}): Promise<KlaviyoSMSRequest> => {
	const { phone, email, code, amount, region } = properties
	const correctPhone = phone.startsWith('+11') ? phone.replace('+11', '+1') : phone
	const smsRes = await subscribeSMSToKlaviyo(email, correctPhone, code, amount, region)
	AM.amSubscribedViaSMSCap({ ...properties, phone: correctPhone })
	return smsRes
}

export const trackAddThermoCoverToCart = (): void => {
	FB.fbAddThermoCoverToCart()
}

export const trackClickFinancingModalAffirm = (location: string): void => {
	// location should only be set to 'Cart' or null
	AM.amClickFinancingModalAffirm(location)
}

export const trackInteractedWithAllLayers = (mattress: string): void => {
	AM.amInteractedWithAllComfortLayersForMattress(mattress)
}

export const trackInteractedWithAllMattressesInComfortLayers = (): void => {
	AM.amInteractedWithAllMattressesComfortLayers()
}

export const trackSwitchProductSize = (name: string): void => {
	AM.amSwitchProduct(name)
}

export const trackClickAwardWinningPressLink = (source: string): void => {
	AM.amClickAwardWinningPressLink(source)
}

export const trackClickNavLink = (type: AM.NavLinkType, target: string): void => {
	AM.amClickNavLink(type, target)
}

export const trackCheckoutCanadaWarning = (itemNames: string[]): void => {
	AM.amCheckoutCanadaWarning(itemNames)
}

export const trackClickedSleepCoachingEmailCap = (): void => {
	AM.amClickedSleepCoachingEmailCap()
}

export const trackClickedButton = (name: string, htmlId: string): void => {
	AM.amClickedButton(name, htmlId)
}

export const updateUserUTMs = (utmMedium: string, utmSource: string): void => {
	AM.amUpdateUserUTMs(utmMedium, utmSource)
}

export const emailCapShown = (): void => {
	AM.amEmailCapShown()
	KY.emailCapShown()
}
