import { useCallback, useMemo } from 'react'
import { useStore } from 'stores/StoreProvider'

/**
 * @deprecated Use only for debugging
 */
export const useTimerStoreDump = () => {
	const events = useStore((state) => state.timer.events)

	return useMemo(() => {
		return {
			events,
		}
	}, [events])
}

/**
 * Stops the inactivity modal timer
 */
export const useTimerStop = () => {
	const events = useStore((state) => state.timer.events)

	return useCallback(() => {
		clearTimeout(events.InactivityModalEvent?.timer)
	}, [events])
}
