import { datadogReportError } from 'events/datadog'
import { safeError } from 'events/error.utils'

export const trackFriendbuyEcap = (email: string): void => {
	try {
		window.friendbuyAPI.push([
			'track',
			'customer',
			{
				email: email,
				id: email,
				referral_modal: 'true',
			},
		])
	} catch (e: unknown) {
		datadogReportError(safeError(e), 'Friendbuy Ecap', { email })
	}
}
