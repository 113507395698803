import { Currency, CurrencyMap, PricingDataFull, ProductPriceMap } from 'prices/types'
import { PriceManager } from 'prices/priceManager'
import { Product, ProductCore } from 'products/types'
import { RegionShort } from 'utils/internationalization'

export const getVariantPrices = (variantId: string | number, allPrices: ProductPriceMap, priceManager: PriceManager): CurrencyMap => {
	return priceManager.getVariantPrices(allPrices, variantId)
}

export const getProductWithPrices = (
	product: ProductCore,
	storeValues: {
		priceManager: PriceManager
		allPrices: ProductPriceMap
		currency: Currency
	}
): Product => {
	const { priceManager, allPrices, currency } = storeValues
	return priceManager.withPrices(product, allPrices, currency)
}

export const getCurrency = (region: RegionShort): Currency => {
	return PriceManager.getRegionCurrency(region)
}

export const getCountryCodeFromCurrency = (currency: Currency): string => {
	switch (currency) {
		case 'USD':
			return 'US'
		case 'CAD':
			return 'CA'
		case 'GBP':
			return 'GB'
		case 'EUR':
			return 'DE'
		case 'AUD':
			return 'AU'
		case 'SEK':
			return 'SE'
		case 'DKK':
			return 'DK'
		case 'AED':
			return 'AE'
		default:
			return 'US'
	}
}

export const getPrices = (currencyMap: CurrencyMap, currentRegion: RegionShort): PricingDataFull => {
	const currency = getCurrency(currentRegion)

	const prefferedCurrency = PriceManager.getPreferredCurrency(currencyMap, currency)

	// TODO: I don't love the name "priceNumber". It's more just to differentiate from priceFormatted.
	// If we were using cents I'd use priceCents vs priceFormatted, but we're not.
	const priceNumber = Math.ceil(parseFloat(currencyMap[prefferedCurrency].price ?? '0'))
	const comparePriceNumber = Math.ceil(parseFloat(currencyMap[prefferedCurrency].comparePrice ?? '0'))
	const discountNumber = Math.max(comparePriceNumber - priceNumber, 0)
	const priceFormatted = PriceManager.formatPriceToCurrencyNoDecimal(priceNumber, prefferedCurrency)
	const comparePriceFormatted = PriceManager.formatPriceToCurrencyNoDecimal(comparePriceNumber, prefferedCurrency)
	const discountFormatted = PriceManager.formatPriceToCurrencyNoDecimal(discountNumber, prefferedCurrency)

	const monthlyPrice = PriceManager.getAffirmFinancingAmount(priceNumber)
	const monthlyPriceFormatted = PriceManager.formatPriceToCurrencyNoDecimal(monthlyPrice, prefferedCurrency)
	const maxMonthsAtZeroInterest = PriceManager.getAffirmMaxMonths(priceNumber * 100)

	const klarnaMonthlyPrice = PriceManager.getKlarnaFinancingAmount(priceNumber)
	const klarnaMonthlyPriceFormatted = PriceManager.formatPriceToCurrencyNoDecimal(klarnaMonthlyPrice, prefferedCurrency)
	const klarnaMaxMonthsAtZeroInterest = PriceManager.getKlarnaMaxMonths()

	return {
		priceNumber,
		comparePriceNumber,
		discountNumber,
		priceFormatted,
		comparePriceFormatted,
		discountFormatted,
		monthlyPrice,
		monthlyPriceFormatted,
		maxMonthsAtZeroInterest,
		klarnaMonthlyPrice,
		klarnaMonthlyPriceFormatted,
		klarnaMaxMonthsAtZeroInterest,
	}
}
