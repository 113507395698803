import { lens } from '@dhmk/zustand-lens'
import type { RootState } from '../root.types'
import { ModalSlice } from './modal.types'
import * as eventNames from './modal.constants'
import { amExitIntentModalShown } from 'events/amplitude'
import { setPageScroll } from 'utils'
import { getEcapExcludedPages } from './modal.constants'

export const createModalSlice = () =>
	lens<ModalSlice, RootState>((set, get) => ({
		// State
		emailCapModalVisible: false,
		showEmailCapModalShortcut: false,
		assistedSalesModalVisible: false,
		referralWelcomeModalVisible: false,
		localizeModalVisible: false,
		showQuizEcap: false,
		showExitIntent: false,
		exitIntentCount: 0,

		// Sleep Quiz Modal
		showSleepQuizModal: false,

		// ModalEvent properties integrated directly
		ecapModalHasBeenTriggered: false,
		ecapModalCanBeTriggered: false,
		ecapModalTimers: { mobile: 2, desktop: 2 },

		// Actions
		displayEmailCapModal: () => {
			// here set and check session storage to prevent showing the same modal
			if (!sessionStorage.getItem(eventNames.emailCapModalTriggerDone) && sessionStorage.getItem('hide_ecap') !== 'true' && sessionStorage.getItem('block-modals') !== 'true') {
				set(
					{
						emailCapModalVisible: true,
						showEmailCapModalShortcut: false,
					},
					undefined,
					'modal/displayEmailCapModal'
				)
				setPageScroll(false)
			}
		},

		toggleQuizEcap: (flag: boolean) => {
			set({ showQuizEcap: flag }, undefined, 'modal/toggleQuizEcap')
		},

		dismissQuizEcap: () => {
			set({ showQuizEcap: false }, undefined, 'modal/dismissQuizEcap')
			localStorage.setItem('quizEcapDone', 'true')
		},

		forcedisplayEmailCapModal: () => {
			set(
				{
					emailCapModalVisible: true,
					showEmailCapModalShortcut: false,
				},
				undefined,
				'modal/forcedisplayEmailCapModal'
			)
			setPageScroll(false)
		},

		toggleExitIntenEcap: (flag: boolean) => {
			const { exitIntentCount } = get()
			if (exitIntentCount < 1) {
				set(
					{
						emailCapModalVisible: flag,
						showExitIntent: flag,
						exitIntentCount: exitIntentCount + 1,
					},
					undefined,
					'modal/toggleExitIntenEcap'
				)

				if (flag) {
					setPageScroll(false)
					amExitIntentModalShown()
				}
			}
		},

		hideEmailCapModal: () => {
			set(
				{
					emailCapModalVisible: false,
					showExitIntent: false,
					showEmailCapModalShortcut: true,
				},
				undefined,
				'modal/hideEmailCapModal'
			)
			setPageScroll(true)
		},

		forceDisplayEmailCapModalShortcut: () => {
			set(
				{
					emailCapModalVisible: false,
					showEmailCapModalShortcut: true,
				},
				undefined,
				'modal/forceDisplayEmailCapModalShortcut'
			)
			if (typeof window !== 'undefined') {
				sessionStorage.setItem(eventNames.emailCapModalTriggerDone, 'true')
			}
		},

		preventDisplayEmailCapModalShortcut: () => {
			set({ showEmailCapModalShortcut: false }, undefined, 'modal/preventDisplayEmailCapModalShortcut')
			sessionStorage.setItem(eventNames.emailCapShortcutButtonTriggerDone, 'true')
		},

		toggleLocalizeModal: () => {
			const { localizeModalVisible } = get()
			set({ localizeModalVisible: !localizeModalVisible }, undefined, 'modal/toggleLocalizeModal')
		},

		displayLocalizeModal: () => {
			set({ localizeModalVisible: true }, undefined, 'modal/displayLocalizeModal')
		},

		// ModalEvent actions integrated directly
		ecapRunTrigger: () => {
			const { ecapModalHasBeenTriggered, ecapUpdateCanTrigger, displayEmailCapModal, ecapSetTriggered } = get()

			if (ecapModalHasBeenTriggered) {
				set({ ecapModalCanBeTriggered: false }, undefined, 'modal/ecapRunTrigger')
				return
			}

			const canTrigger = ecapUpdateCanTrigger()

			if (canTrigger) {
				const emailAvailable = !!localStorage.getItem('email')
				if (!ecapModalHasBeenTriggered && !emailAvailable) {
					// Instead of dispatching an event, directly call the action
					displayEmailCapModal()
					ecapSetTriggered()
				}
			}
		},

		ecapUpdateCanTrigger: (): boolean => {
			const { ecapModalHasBeenTriggered, ecapGetHasBeenTriggered } = get()

			const currentPage = window.location.pathname
			const onShopPage = currentPage.includes('product/')
			const onCheckoutPage = currentPage.includes('/checkout/')
			const done = ecapGetHasBeenTriggered() || sessionStorage.getItem(eventNames.emailCapModalTriggerDone) || localStorage.getItem('email')

			if (done) {
				return false
			}

			const reffrb = sessionStorage.getItem('appliedDiscountCode') === 'REFFRB'

			if (reffrb) {
				return false
			}

			// if region not US, dont show ecap until the localize modal has been shown and closed
			if (
				localStorage.getItem('confirmedRegion') == null &&
				window.Localize &&
				sessionStorage.getItem('automated') !== 'true' &&
				(currentPage.startsWith('/uk/') ||
					currentPage.startsWith('/ca/') ||
					currentPage.startsWith('/au/') ||
					currentPage.startsWith('/se/') ||
					currentPage.startsWith('/dk/') ||
					currentPage.startsWith('/ae/') ||
					currentPage.startsWith('/ch/') ||
					currentPage.startsWith('/mx/') ||
					currentPage.startsWith('/eu/'))
			) {
				return false
			}

			const firstVisitEpoch = parseInt(localStorage.getItem('firstVisitEpoch'))
			const TEN_MINUTES = 10 * 60 * 1000
			const isFirstTimeVisitor = Date.now() - firstVisitEpoch < TEN_MINUTES

			// Normalize currentPage to ensure consistent comparison with excluded pages
			const normalizedCurrentPage = currentPage.endsWith('/') ? currentPage : `${currentPage}/`
			let viewingExcludedPage = getEcapExcludedPages().some((page) => normalizedCurrentPage === page) || onShopPage || onCheckoutPage

			if (isFirstTimeVisitor && onShopPage) {
				viewingExcludedPage = false
			}

			const isAppVisitor = typeof window !== 'undefined' && window && window.location.href.includes('utm_source=app')
			const isEmail = typeof window !== 'undefined' && window && window.location.href.includes('utm_source=klaviyo')
			const isSMS = typeof window !== 'undefined' && window && window.location.href.includes('utm_medium=sms')

			if (viewingExcludedPage || isAppVisitor || isEmail || isSMS) {
				set({ ecapModalHasBeenTriggered: true }, undefined, 'modal/ecapRunTrigger')
			}

			if (!ecapModalHasBeenTriggered) {
				set({ ecapModalCanBeTriggered: true }, undefined, 'modal/ecapRunTrigger')
			}

			return get().ecapModalCanBeTriggered
		},

		ecapSetTriggered: () => {
			if (sessionStorage.getItem('block-modals') === 'true') {
				return
			}

			set(
				{
					ecapModalHasBeenTriggered: true,
					ecapModalCanBeTriggered: false,
				},
				undefined,
				'modal/ecapSetTriggered'
			)

			try {
				sessionStorage.setItem(eventNames.emailCapModalTriggerDone, JSON.stringify(Date.now()))
				sessionStorage.setItem(eventNames.lastEventTime, JSON.stringify(Date.now()))
			} catch (e) {
				console.log(e)
			}
		},

		ecapGetHasBeenTriggered: () => {
			const { ecapModalHasBeenTriggered } = get()
			const sessionItem = sessionStorage.getItem(eventNames.emailCapModalTriggerDone)
			const alreadyTriggered = (sessionItem && sessionItem.length) || ecapModalHasBeenTriggered || sessionStorage.getItem(eventNames.referralWelcomeTriggerDone)
			return !!alreadyTriggered
		},

		toggleSleepQuizModal: () => {
			const { showSleepQuizModal } = get()
			set({ showSleepQuizModal: !showSleepQuizModal }, undefined, 'modal/toggleSleepQuizModal')
		},

		markSleepQuizModalAsDone: () => {
			if (typeof window !== 'undefined') {
				localStorage.setItem('sleepQuizEcapDone', 'true')
			}
		},
	}))
