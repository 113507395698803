import { StackItemConnectedProps, VisualProps } from 'components/Phantom/_shop/Visuals'
import { VariantKey } from 'products/types'
import { getLanguageAssets } from './localization'

export const getSizeVisuals = (metric = false, isPod3: boolean, languageCode: string): VisualProps => {
	if (!metric) {
		if (isPod3) {
			return getPod3SizeVisuals()
		}
		return getPod4SizeVisuals(languageCode)
	}

	return getNonUSSizeVisuals(isPod3, languageCode)
}

export const getPod4SizeVisuals = (languageCode: string): VisualProps => {
	return {
		type: 'stack',
		associatedSelectorKeys: ['size'],
		data: [
			{
				type: 'image',
				data: {
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/carousel-ab/Full+Size+US+-+Desktop.png',
					alt: 'Full Size',
					mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/carousel-ab/Full+Size+US+-+Mobile.png',
				},
				associatedSelections: [
					{
						key: 'size',
						value: 'full',
					},
				],
			},
			{
				type: 'image',
				data: {
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/carousel-ab/Queen+US+-+Desktop.png',
					alt: 'Queen Size',
					mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/carousel-ab/Queen+US+-+Mobile.png',
				},
				associatedSelections: [
					{
						key: 'size',
						value: 'queen',
					},
				],
			},
			{
				type: 'image',
				data: {
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/carousel-ab/King+US+-+Desktop.png',
					alt: 'King Size',
					mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/carousel-ab/King+US+-+Mobile.png',
				},
				associatedSelections: [
					{
						key: 'size',
						value: 'king',
					},
				],
			},
			{
				type: 'image',
				data: {
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/carousel-ab/Cali+King+US+-+Desktop.png',
					alt: 'Cali King Size',
					mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/carousel-ab/Cali+King+US+-+Mobile.png',
				},
				associatedSelections: [
					{
						key: 'size',
						value: 'caliking',
					},
				],
			},
			{
				type: 'image',
				data: {
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/carousel-ab/No+Selection+-+Desktop.png',
					alt: 'Pod 4 Size',
					mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/carousel-ab/No+Selection+-+Mobile.png',
				},
				associatedSelections: [
					{
						key: 'size',
						value: '*',
					},
				],
			},
		],
	}
}

export const getPod3SizeVisuals = (): VisualProps => {
	return {
		type: 'stack',
		associatedSelectorKeys: ['size'],
		data: [
			{
				type: 'image',
				data: {
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sizes_new/shop_pod3_mattress_size_full.png',
					alt: 'Full Size',
					mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sizes_new/shop_pod3_mattress_size_full_mobile.png',
				},
				associatedSelections: [
					{
						key: 'size',
						value: 'full',
					},
				],
			},
			{
				type: 'image',
				data: {
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sizes_new/shop_pod3_mattress_size_queen.png',
					alt: 'Queen Size',
					mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sizes_new/shop_pod3_mattress_size_queen_mobile.png',
				},
				associatedSelections: [
					{
						key: 'size',
						value: 'queen',
					},
				],
			},
			{
				type: 'image',
				data: {
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sizes_new/shop_pod3_mattress_size_king.png',
					alt: 'King Size',
					mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sizes_new/shop_pod3_mattress_size_king_mobile.png',
				},
				associatedSelections: [
					{
						key: 'size',
						value: 'king',
					},
				],
			},
			{
				type: 'image',
				data: {
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sizes_new/shop_pod3_mattress_size_caliking.png',
					alt: 'Cali King Size',
					mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sizes_new/shop_pod3_mattress_size_caliking_mobile.png',
				},
				associatedSelections: [
					{
						key: 'size',
						value: 'caliking',
					},
				],
			},
			{
				type: 'image',
				data: {
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sizes_new/shop_pod3_mattress_size_generic.png',
					alt: 'Pod 3 Size',
					mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sizes_new/shop_pod3_mattress_size_generic_mobile.png',
				},
				associatedSelections: [
					{
						key: 'size',
						value: '*',
					},
				],
			},
		],
	}
}

const emperorVariant = (variant: VariantKey): StackItemConnectedProps => {
	return {
		type: 'image',
		data: {
			src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/carousel-ab/Cali+King+(Emperor)+Non-US+-+Desktop.png',
			alt: 'Emperor size',
			mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/carousel-ab/Cali+King+(Emperor)+Non-US+-+Mobile.png',
		},
		associatedSelections: [
			{
				key: 'size',
				value: variant,
			},
		],
	}
}

const doubleVariant = (variant: VariantKey): StackItemConnectedProps => {
	return {
		type: 'image',
		data: {
			src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/carousel-ab/Full+Size+(Double)+Non-US+-+Desktop.png',
			alt: 'Double size',
			mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/carousel-ab/Full+Size+(Double)+Non-US+-+Mobile.png',
		},
		associatedSelections: [
			{
				key: 'size',
				value: variant,
			},
		],
	}
}

const superKingVariant = (variant: VariantKey): StackItemConnectedProps => {
	return {
		type: 'image',
		data: {
			src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/carousel-ab/King+(Super+King)+Non-US+-+Desktop.png',
			alt: 'Super King size',
			mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/carousel-ab/King+(Super+King)+Non-US+-+Mobile.png',
		},
		associatedSelections: [
			{
				key: 'size',
				value: variant,
			},
		],
	}
}

const largeDoubleVariant = (variant: VariantKey): StackItemConnectedProps => {
	return {
		type: 'image',
		data: {
			src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/carousel-ab/Queen+(Large+Double)+Non-US+-+Desktop.png',
			alt: 'Large Double size',
			mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/carousel-ab/Queen+(Large+Double)+Non-US+-+Mobile.png',
		},
		associatedSelections: [
			{
				key: 'size',
				value: variant,
			},
		],
	}
}

export const getNonUSSizeVisuals = (isPod3: boolean, languageCode: string): VisualProps => {
	if (isPod3) {
		return {
			type: 'stack',
			associatedSelectorKeys: ['size'],
			data: [
				{
					type: 'image',
					data: {
						src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sizes_new/shop_pod3_international_mattress_size_generic.png',
						alt: 'Pod 3 Size',
						mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sizes_new/shop_pod3_international_mattress_size_generic_mobile.png',
					},
					associatedSelections: [
						{
							key: 'pod-model',
							value: 'pod_3_cover_perfect',
						},
					],
				},
			],
		}
	}

	return {
		type: 'stack',
		associatedSelectorKeys: ['size'],
		data: [
			doubleVariant('cafull'),
			largeDoubleVariant('caqueen'),
			superKingVariant('caking'),
			emperorVariant('ukemperor'),
			superKingVariant('uksuperking'),
			doubleVariant('ukdouble'),
			largeDoubleVariant('ukking'),
			emperorVariant('euemperor'),
			superKingVariant('eusuperking'),
			doubleVariant('eudouble'),
			largeDoubleVariant('eulargedouble'),
			emperorVariant('seemperor'),
			emperorVariant('dkemperor'),
			superKingVariant('sesuperking'),
			superKingVariant('dksuperking'),
			doubleVariant('sedouble'),
			doubleVariant('dkdouble'),
			doubleVariant('audouble'),
			largeDoubleVariant('auqueen'),
			superKingVariant('auking'),
			{
				type: 'image',
				data: {
					src: getLanguageAssets('size-explanation-desktop', languageCode),
					alt: 'Pod 4 Size',
					mobileSrc: getLanguageAssets('size-explanation-mobile', languageCode),
				},
				associatedSelections: [
					{
						key: 'pod-model',
						value: '*',
					},
				],
			},
		],
	}
}
