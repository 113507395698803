import { CartLineItem } from 'cart/types'
import { AffirmLocations } from 'constants/events'
import { sendFBEvents } from 'eight_api/aws'
import { datadogReportError } from 'events/datadog'
import { safeError } from 'events/error.utils'
import { sha256 } from 'js-sha256'
import ReactPixel from 'utils/reactPixel'

export interface AdvancedMatchingParams {
	ct?: string
	country?: string
	em?: string
	external_id?: string
	fn?: string
	ln?: string
	ph?: string
	st?: string
	zp?: number
}

export const getEventIdHash = (): string => {
	const uuid = localStorage.getItem('eight-uuid')
	const now = Date.now()
	if (uuid !== null) {
		return sha256(uuid + now.toString())
	} else {
		return sha256(now.toString())
	}
}

const fbEventIdentification = {
	AddToCart: () => {
		return {
			eventID: 'event.AddToCart.' + getEventIdHash(),
			event: 'AddToCart',
		}
	},
	PageView: () => {
		return {
			eventID: 'event.PageView.' + getEventIdHash(),
			event: 'PageView',
		}
	},
	ViewContent: () => {
		return {
			eventID: 'event.ViewContent.' + getEventIdHash(),
			event: 'ViewContent',
		}
	},
	SubscribeEmail: () => {
		return {
			eventID: 'event.SubscribeEmail.' + getEventIdHash(),
			event: 'SubscribeEmail',
		}
	},
	ClickFAQ: () => {
		return {
			eventID: 'event.ClickFAQ.' + getEventIdHash(),
			event: 'ClickFAQ',
		}
	},
	ClickAffirmLearnMore: () => {
		return {
			eventID: 'event.ClickAffirmLearnMore.' + getEventIdHash(),
			event: 'ClickAffirmLearnMore',
		}
	},
	Subscribe: () => {
		// Should be used for product subscriptions, not email signup
		return {
			eventID: 'event.Subscribe.' + getEventIdHash(),
			event: 'Subscribe',
		}
	},
	ClickCheckoutPayment: () => {
		return {
			eventID: 'event.ClickCheckoutPayment.' + getEventIdHash(),
			event: 'ClickCheckoutPayment',
		}
	},
	CompleteContactInformation: () => {
		return {
			eventID: 'event.CompleteContactInformation.' + getEventIdHash(),
			event: 'CompleteContactInformation',
		}
	},
	CompletePaymentMethodSelection: () => {
		return {
			eventID: 'event.CompletePaymentMethodSelection.' + getEventIdHash(),
			event: 'CompletePaymentMethodSelection',
		}
	},
	InitiateCheckout: () => {
		return {
			eventID: 'event.InitiateCheckout.' + getEventIdHash(),
			event: 'InitiateCheckout',
		}
	},
	CheckoutPaymentSelect: () => {
		return {
			eventID: 'event.CheckoutPaymentSelect.' + getEventIdHash(),
			event: 'CheckoutPaymentSelect',
		}
	},
	ViewedPodShop2xIn90Days: () => {
		return {
			eventID: 'event.ViewedPodShop2xIn90Days.' + getEventIdHash(),
			event: 'ViewedPodShop2xIn90Days',
		}
	},
	ViewedPodShop2xIn28Days: () => {
		return {
			eventID: 'event.ViewedPodShop2xIn28Days.' + getEventIdHash(),
			event: 'ViewedPodShop2xIn28Days',
		}
	},
	AddThermoCoverToCart: () => {
		return {
			eventID: 'event.AddThermoCoverToCart.' + getEventIdHash(),
			event: 'AddThermoCoverToCart',
		}
	},
}

// AddToCart
export const fbAddToCart = (item: Partial<CartLineItem>): void => {
	const eventIdentification = fbEventIdentification.AddToCart()

	ReactPixel.track(
		'AddToCart',
		{
			content_ids: [item.productId?.toString()],
			content_name: item.productTitle,
			content_type: 'product',
			contents: item.productTitle,
			currency: 'USD',
			value: (item.price ?? 0) / 100,
			quantity: 1,
		},
		eventIdentification.eventID
	)

	// legacy custom event
	ReactPixel.trackCustom(
		'Add to Cart',
		{
			content_ids: [item?.productId?.toString()],
			content_name: item.productTitle,
			content_type: 'product',
			contents: item.productTitle,
			currency: 'USD',
			value: (item.price ?? 0) / 100,
			quantity: 1,
		},
		eventIdentification.eventID
	)

	const data = {
		type: 'AddToCart',
		content_ids: item.productId,
		content_name: item.productTitle,
		content_type: 'product',
		contents: item.productTitle,
		currency: 'USD',
		value: (item.price ?? 0) / 100,
		quantity: 1,
		url: window.location.href,
		...eventIdentification,
	}

	sendFBEvents([data])?.catch((error: unknown) => {
		datadogReportError(safeError(error), 'Facebook AddToCart', data)
	})
}

export const fbPageView = (): void => {
	const eventIdentification = fbEventIdentification.PageView()
	ReactPixel?.pageView(eventIdentification.eventID)

	const data = {
		type: 'PageView',
		url: window.location.href,
		...eventIdentification,
	}

	sendFBEvents([data])?.catch((error: unknown) => {
		datadogReportError(safeError(error), 'Facebook PageView', data)
	})
}

export const fbViewContent = (item?: { content_ids: string; content_name: string; content_type: string; contents: string; currency: string; value: number }): void => {
	const eventIdentification = fbEventIdentification.ViewContent()
	ReactPixel.track('ViewContent', { ...item, content_ids: [item?.content_ids] }, eventIdentification.eventID)

	const data = {
		type: 'ViewContent',
		...item,
		url: window.location.href,
		...eventIdentification,
	}

	sendFBEvents([data])?.catch((error: unknown) => {
		datadogReportError(safeError(error), 'Facebook ViewContent', data)
	})
}

// SubscribeEmail
export const fbSubscribeEmail = (email: string, location: string): void => {
	const eventIdentification = fbEventIdentification.SubscribeEmail()
	ReactPixel.track(
		'SubscribeEmail',
		{
			location,
		},
		eventIdentification.eventID
	)

	const data = {
		type: 'SubscribeEmail',
		location,
		url: window.location.href,
		email,
		...eventIdentification,
	}

	sendFBEvents([data])?.catch((error: unknown) => {
		datadogReportError(safeError(error), 'Facebook SubscribeEmail', data)
	})
}

export const fbSelectPaymentMethod = (paymentMethod: string): void => {
	const eventIdentification = fbEventIdentification.CheckoutPaymentSelect()
	ReactPixel.track(
		'CheckoutPaymentSelect',
		{
			paymentMethod,
		},
		eventIdentification.eventID
	)

	const data = {
		type: 'CheckoutPaymentSelect',
		paymentMethod,
		url: window.location.href,
		...eventIdentification,
	}

	sendFBEvents([data])?.catch((error: unknown) => {
		datadogReportError(safeError(error), 'Facebook CheckoutPaymentSelect', data)
	})
}

export const fbInitiatedPayment = (paymentMethod: string): void => {
	const eventIdentification = fbEventIdentification.ClickCheckoutPayment()
	ReactPixel.track(
		'ClickCheckoutPayment',
		{
			paymentMethod,
		},
		eventIdentification.eventID
	)

	const data = {
		type: 'ClickCheckoutPayment',
		paymentMethod,
		url: window.location.href,
		...eventIdentification,
	}

	sendFBEvents([data])?.catch((error: unknown) => {
		datadogReportError(safeError(error), 'Facebook ClickCheckoutPayment', data)
	})
}

// ClickFaq
export const fbClickFaq = (question: string): void => {
	const eventIdentification = fbEventIdentification.ClickFAQ()
	ReactPixel.track(
		'Click - FAQ',
		{
			question,
		},
		eventIdentification.eventID
	)

	const data = {
		type: 'Click - FAQ',
		question,
		url: window.location.href,
		...eventIdentification,
	}

	sendFBEvents([data])?.catch((error: unknown) => {
		datadogReportError(safeError(error), 'Facebook ClickFAQ', data)
	})
}

// ClickAffirmLearnMore
export const fbClickAffirmLearnMore = (location: AffirmLocations): void => {
	const eventIdentification = fbEventIdentification.ClickAffirmLearnMore()
	ReactPixel.track(
		'Click - Learn more Affirm',
		{
			location,
		},
		eventIdentification.eventID
	)

	const data = {
		type: 'Click - Learn more Affirm',
		location,
		url: window.location.href,
		...eventIdentification,
	}

	sendFBEvents([data])?.catch((error: unknown) => {
		datadogReportError(safeError(error), 'Facebook ClickAffirmLearnMore', data)
	})
}

// Subscribe
export const fbSubscribe = (email: string, location: string): void => {
	const eventIdentification = fbEventIdentification.Subscribe()
	ReactPixel.track(
		'Subscribe',
		{
			'Subscribe Location': location,
		},
		eventIdentification.eventID
	)

	const data = {
		type: 'Subscribe',
		location,
		url: window.location.href,
		email,
		...eventIdentification,
	}

	sendFBEvents([data])?.catch((error: unknown) => {
		datadogReportError(safeError(error), 'Facebook Subscribe', data)
	})
}

// InitiateCheckout
export const fbInitiateCheckout = (itemNames: string[]): void => {
	const eventIdentification = fbEventIdentification.InitiateCheckout()
	ReactPixel.trackCustom(
		`Checkout - Initiated`,
		{
			'Source Page URL': window.location.href,
			itemNames,
		},
		eventIdentification.eventID
	)

	const data = {
		type: 'Checkout - Initiated',
		itemNames,
		url: window.location.href,
		...eventIdentification,
	}

	sendFBEvents([data])?.catch((error: unknown) => {
		datadogReportError(safeError(error), 'Facebook InitiateCheckout', data)
	})
}

// CompleteContactInformation
export const fbCompleteContactInformation = (advancedConfig: AdvancedMatchingParams): void => {
	const eventIdentification = fbEventIdentification.CompleteContactInformation()
	ReactPixel.trackCustom(`Checkout - Complete Contact Info`, {}, eventIdentification.eventID)

	const data = {
		type: 'Checkout - Complete Contact Info',
		...eventIdentification,
		...advancedConfig,
		url: window.location.href,
	}

	sendFBEvents([data])?.catch((error: unknown) => {
		datadogReportError(safeError(error), 'Facebook CompleteContactInformation', data)
	})
}

// ClickContinueToPaymentProvider
export const fbClickContinueToPaymentProvider = (name: string, itemNames: string[], advancedConfig: AdvancedMatchingParams): void => {
	const event1Identification = fbEventIdentification.ClickCheckoutPayment()
	ReactPixel.trackCustom(`Initiated Payment - ${name}`, { 'Source Page URL': window.location.href }, event1Identification.eventID)

	const event2Identification = fbEventIdentification.CompletePaymentMethodSelection()
	const formattedPaymentName = name === 'recharge' ? 'pay now - recharge' : name
	ReactPixel.trackCustom(
		`Checkout - Completed Payment Method`,
		{
			paymentMethod: formattedPaymentName,
			itemNames,
		},
		event2Identification.eventID
	)

	const initiatedPaymentData = {
		type: `Initiated Payment - ${name}`,
		url: window.location.href,
		...event1Identification,
		...advancedConfig,
	}

	const completedPaymentMethodData = {
		type: 'Checkout - Completed Payment Method',
		paymentMethod: formattedPaymentName,
		itemNames,
		url: window.location.href,
		...event2Identification,
		...advancedConfig,
	}

	sendFBEvents([initiatedPaymentData, completedPaymentMethodData])?.catch((error: unknown) => {
		datadogReportError(safeError(error), 'Facebook ClickContinueToPaymentProvider - Initiated Payment', initiatedPaymentData)
		datadogReportError(safeError(error), 'Facebook ClickContinueToPaymentProvider - Completed Payment Method', completedPaymentMethodData)
	})
}

// ViewedPodShop2xIn90Days
export const fbViewedPodShop2xIn90Days = (): void => {
	const eventIdentification = fbEventIdentification.ViewedPodShop2xIn90Days()
	ReactPixel.trackCustom('Viewed Pod Shop 2x in 90 days', {}, eventIdentification.eventID)

	const data = {
		type: 'Viewed Pod Shop 2x in 90 days',
		url: window.location.href,
		...eventIdentification,
	}

	sendFBEvents([data])?.catch((error: unknown) => {
		datadogReportError(safeError(error), 'Facebook ViewedPodShop2xIn90Days', data)
	})
}

// ViewedPodShop2xIn28Days
export const fbViewedPodShop2xIn28Days = (): void => {
	const eventIdentification = fbEventIdentification.ViewedPodShop2xIn28Days()
	ReactPixel.trackCustom('Viewed Pod Shop 2x in 28 days', {}, eventIdentification.eventID)

	const data = {
		type: 'Viewed Pod Shop 2x in 28 days',
		url: window.location.href,
		...eventIdentification,
	}

	sendFBEvents([data])?.catch((error: unknown) => {
		datadogReportError(safeError(error), 'Facebook ViewedPodShop2xIn28Days', data)
	})
}

// AddThermoCoverToCart
export const fbAddThermoCoverToCart = (): void => {
	const eventIdentification = fbEventIdentification.AddThermoCoverToCart()
	ReactPixel.trackCustom('Add to Cart - Thermo Cover', {}, eventIdentification.eventID)

	const data = {
		type: 'Add to Cart - Thermo Cover',
		url: window.location.href,
		...eventIdentification,
	}

	sendFBEvents([data])?.catch((error: unknown) => {
		datadogReportError(safeError(error), 'Facebook AddThermoCoverToCart', data)
	})
}
