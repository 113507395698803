import { lens } from '@dhmk/zustand-lens'
import { RootState } from '../root.types'
import { PromoSlice } from './promo.types'
import { getDiscount, getPromoBar, getPromoBarFromCurrentPath } from './promo.utils'
import { getUrlPromoSettings } from 'config/url_promos'
import { RegionShort } from 'utils/internationalization'
import { PromoBar, SiteSettings } from 'config/types'

export const createPromoSlice = (currentPage: string, settings: SiteSettings, currentRegion: RegionShort) => {
	const initPromo = getPromoBar(currentPage, settings, currentRegion)
	const initDiscountCode = typeof window !== 'undefined' ? sessionStorage.getItem('appliedDiscountCode') : ''

	return lens<PromoSlice, RootState>((set, get, api) => ({
		discountCode: initDiscountCode,
		promoBarTitle: initPromo?.promoMessage,
		promoBarLink: initPromo?.promoLink,
		showTimer: initPromo?.showTimer,

		initPromo: () => {
			const sessionCode = sessionStorage.getItem('appliedDiscountCode')
			set({ discountCode: sessionCode }, undefined, 'promo/initPromo')
		},

		setPromo: (code: string | null) => {
			if (!code) {
				return
			}
			set({ discountCode: code }, undefined, 'promo/setPromo')

			const isVanity = api.getState().settings.isVanity
			const priceManager = api.getState().prices.priceManager
			const syncPrices = api.getState().prices.syncPrices
			if (priceManager && syncPrices) {
				priceManager.appliedDiscount = getDiscount(code)
				syncPrices()
			}

			if (typeof window !== 'undefined') {
				const appliedDiscountCode = sessionStorage.getItem('appliedDiscountCode')
				const urlPromoSettings = getUrlPromoSettings(window.location.search)
				if (!appliedDiscountCode || isVanity || (appliedDiscountCode !== get().discountCode && urlPromoSettings)) {
					sessionStorage.setItem('appliedDiscountCode', get().discountCode)
				}
			}
		},

		setPromoBar: (currentPage: string) => {
			const settings = api.getState().settings.settings
			const currentRegion = api.getState().settings.currentRegion

			const promoBar = getPromoBar(currentPage, settings, currentRegion)
			if (promoBar) {
				set({
					...promoBar,
				})
			}
		},

		removePromo: () => {
			set({ discountCode: '' }, undefined, 'promo/removePromo')
			sessionStorage.removeItem('appliedDiscountCode')
			localStorage.removeItem('vanity')

			const priceManager = api.getState().prices.priceManager
			const syncPrices = api.getState().prices.syncPrices
			if (priceManager && syncPrices) {
				priceManager.appliedDiscount = undefined
				syncPrices()
			}
		},

		setPromoBarCurrentPath: (promoMessages: PromoBar, currentPage: string, currentRegion: RegionShort) => {
			const { promoMessage, promoLink, showTimer } = getPromoBarFromCurrentPath(promoMessages, currentPage, currentRegion)
			set(
				{
					promoBarTitle: promoMessage,
					promoBarLink: promoLink,
					showTimer: showTimer != null ? showTimer : true,
				},
				undefined,
				'promo/setPromoBarCurrentPath'
			)
		},
	}))
}
