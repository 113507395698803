import { CartLineItem } from 'cart/types'
import { datadogReportError } from 'events/datadog'
import { safeError } from 'events/error.utils'
import { event, plugin } from 'react-ga'
import ReactGA from 'react-ga4'

export const gaAddToCart = (item: CartLineItem): void => {
	plugin.execute('ec', 'addProduct', {
		id: item.id,
		name: item.productTitle,
		variant: item.variantTitle,
		price: item.price,
	})
	plugin.execute('ec', 'setAction', 'add')

	event({ category: 'Ecommerce', action: 'Add to Cart', label: 'Add to Cart' })
	ReactGA.event({ category: 'Ecommerce', action: 'Add to Cart', label: 'Add to Cart' })

	window.dataLayer?.push({
		event: 'AddToCartDL',
		atcCount: 1,
		atcPrice: item.price,
		atcPriceId: item.id,
		atcProductName: item.productTitle,
		atcProductVariant: item.variantTitle,
	})
}

export const gaClickSocialMedia = (site: string): void => {
	event({
		category: 'Social Media',
		action: `Clicked on ${site} icon in footer`,
	})
	ReactGA.event({
		category: 'Social Media',
		action: `Clicked on ${site} icon in footer`,
	})
}

export const gaTrackClickLink = (to: string): void => {
	event({
		category: 'Click',
		action: 'Clicked Button on: ' + window.location.href + ' - to:' + to,
	})
	ReactGA.event({
		category: 'Click',
		action: 'Clicked Button on: ' + window.location.href + ' - to:' + to,
	})
}

export const gaTrackEmailSubscribe = (location: string): void => {
	event({
		category: 'Email',
		action: 'Email subscribed at ' + location,
	})
	ReactGA.event({
		category: 'Email',
		action: 'Email subscribed at ' + location,
	})
}

export const gaExpandFAQ = (question: string): void => {
	event({
		category: 'FAQ',
		action: 'Expanded for: ' + question,
	})
	ReactGA.event({
		category: 'FAQ',
		action: 'Expanded for: ' + question,
	})
}

export const gaExpandCart = (): void => {
	event({
		category: 'Cart',
		action: 'Cart opened on: ' + window.location.href,
	})
	ReactGA.event({
		category: 'Cart',
		action: 'Cart opened on: ' + window.location.href,
	})
}

export const gaPlayVideo = (): void => {
	event({
		category: 'Video',
		action: 'Played video on: ' + window.location.href,
	})
	ReactGA.event({
		category: 'Video',
		action: 'Played video on: ' + window.location.href,
	})
}

export const gaCheckoutInitiated = (): void => {
	event({ category: 'Ecommerce', action: 'Checkout', label: 'Initiated' })
	ReactGA.event({ category: 'Ecommerce', action: 'Checkout', label: 'Initiated' })
}

export const GTMCheckoutInitiated = (): void => {
	try {
		window.dataLayer?.push({ event: 'react.checkout_intiated' })
	} catch (e: unknown) {
		datadogReportError(safeError(e), 'GTM Checkout Initiated')
	}
}

export const GTMEmailSubscribe = (): void => {
	try {
		window.dataLayer?.push({ event: 'react.email_subscribe' })
	} catch (e: unknown) {
		datadogReportError(safeError(e), 'GTM Email Subscribe')
	}
}

export const gaEmailCapSubscribeGoalCompletion = (): void => {
	event({
		category: 'Email',
		action: 'Subscribe',
		label: 'Email Cap',
		value: 1,
	})
}

export const checkoutComplete = (): void => {
	try {
		window.dataLayer?.push({ event: 'shopify.checkout_complete' })
	} catch (e: unknown) {
		datadogReportError(safeError(e), 'GTM Checkout Complete')
	}
}

export const setDataLayerVariable = (key: string, value: string): void => {
	try {
		window.dataLayer?.push({ [key]: value })
	} catch (e: unknown) {
		datadogReportError(safeError(e), 'GTM Set Data Layer Variable')
	}

	try {
		if (window['dataLayerObj']) {
			window['dataLayerObj'].key = value
		}
	} catch (e: unknown) {
		datadogReportError(safeError(e), 'GTM Set Data Layer Object Variable')
	}
}
