import { lens } from '@dhmk/zustand-lens'
import AssistedSaleModalEvent from 'timerevents/AssistedSaleModalEvent'
import InactivityModalEvent from 'timerevents/InactivityModalEvent'
import ReferralWelcomeEvent from 'timerevents/ReferralWelcomeEvent'
import type { RootState } from '../root.types'
import { TimerSlice } from './timer.types'

export const createTimerSlice = () =>
	lens<TimerSlice, RootState>((set) => ({
		events: {},

		clearEvents: () => {
			set({ events: {} }, undefined, 'timer/clearEvents')
		},

		registerEvents: () => {
			set(
				{
					events: {
						assistedSaleModalEvent: new AssistedSaleModalEvent(),
						ReferralWelcomeEvent: new ReferralWelcomeEvent(),
						InactivityModalEvent: new InactivityModalEvent(),
					},
				},
				undefined,
				'timer/registerEvents'
			)
		},
	}))
