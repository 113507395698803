import { Currency } from 'prices/types'
import { VariantKey } from 'products/types'

export const currencyDefaultSizes: { [key in Currency]: VariantKey } = {
	USD: 'queen',
	CAD: 'caqueen',
	AUD: 'auqueen',
	EUR: 'eusuperking',
	GBP: 'uksuperking',
	SEK: 'sesuperking',
	DKK: 'dksuperking',
	AED: 'aeking',
	CHF: 'chsuperking',
	MXN: 'mxqueen',
}
