import { PriceManager } from 'prices'
import { CurrencyMap } from 'prices/types'
import { ProductCore } from 'products/types'
import { useMemo } from 'react'
import { useStore } from 'stores/StoreProvider'
import { getProductWithPrices, getCurrency, getPrices } from './prices.utils'

/**
 * @deprecated Use only for debugging
 */
export const usePriceStoreDump = () => {
	const coverPrices = useCoverPrices()
	const pod4Prices = usePod4Prices()
	const pod4UltraPrices = usePod4UltraPrices()
	const podPrices = usePodPrices()
	const sleepEssentialsBundlePrices = useSleepEssentialsBundlePrices()

	return useMemo(() => {
		return {
			coverPrices,
			pod4Prices,
			pod4UltraPrices,
			podPrices,
			sleepEssentialsBundlePrices,
		}
	}, [coverPrices, pod4Prices, pod4UltraPrices, podPrices, sleepEssentialsBundlePrices])
}

/**
 * Returns the 2-letter country code from the currency
 */
export const useCountryCodeFromCurrency = () => {
	const currency = useCurrency()

	return useMemo(() => {
		switch (currency) {
			case 'USD':
				return 'US'
			case 'CAD':
				return 'CA'
			case 'GBP':
				return 'GB'
			case 'EUR':
				return 'DE'
			case 'AUD':
				return 'AU'
			case 'SEK':
				return 'SE'
			case 'DKK':
				return 'DK'
			case 'AED':
				return 'AE'
			default:
				return 'US'
		}
	}, [currency])
}

/**
 * Returns the currency for the current region
 */
export const useCurrency = () => {
	const currentRegion = useStore((state) => state.settings.currentRegion)
	return getCurrency(currentRegion)
}

/**
 * Returns a product with prices
 */
export const useProductWithPrices = (product: ProductCore) => {
	const priceManager = useStore((state) => state.prices.priceManager)
	const allPrices = useStore((state) => state.prices.allPrices)
	const currency = useCurrency()

	return getProductWithPrices(product, { priceManager, allPrices, currency })
}

/**
 * Returns the variant prices for a given variant id
 */
export const useVariantPrices = (variantId: number) => {
	const allPrices = useStore((state) => state.prices.allPrices)
	const priceManager = useStore((state) => state.prices.priceManager)

	const variantPrices = useMemo(() => {
		return priceManager.getVariantPrices(allPrices, variantId)
	}, [allPrices, priceManager, variantId])

	return variantPrices
}

/**
 * Returns the prices for a given currency map. Used privately by the price store.
 */
const usePrices = (currencyMap: CurrencyMap) => {
	const currentRegion = useStore((state) => state.settings.currentRegion)

	return useMemo(() => {
		return getPrices(currencyMap, currentRegion)
	}, [currencyMap, currentRegion])
}

/**
 * Prices for Pod 3 Perfect Fit
 */
export const useCoverPrices = () => {
	const coverPrices = useStore((state) => state.prices.coverPrices)
	return usePrices(coverPrices)
}

/**
 * Prices for Pod 4
 */
export const usePod4Prices = () => {
	const pod4Prices = useStore((state) => state.prices.pod4Prices)
	return usePrices(pod4Prices)
}

/**
 * Prices for Pod 4 Ultra
 */
export const usePod4UltraPrices = () => {
	const pod4UltraPrices = useStore((state) => state.prices.pod4UltraPrices)
	return usePrices(pod4UltraPrices)
}

/**
 * Prices for Mattress (Pod)
 */
export const usePodPrices = () => {
	const podPrices = useStore((state) => state.prices.podPrices)
	return usePrices(podPrices)
}

/**
 * Prices for Sleep Essentials Bundle
 */
export const useSleepEssentialsBundlePrices = () => {
	const sleepEssentialsBundlePrices = useStore((state) => state.prices.sleepEssentialsBundlePrices)
	return usePrices(sleepEssentialsBundlePrices)
}

/**
 * Provides the financing provider for the current currency
 */
export const useFinancingProvider = () => {
	const currency = useCurrency()

	return useMemo(() => {
		if (currency === 'USD' || currency === 'CAD') {
			return 'affirm'
		} else if (currency === 'GBP' || currency === 'EUR') {
			return 'klarna'
		} else {
			return 'none'
		}
	}, [currency])
}

/**
 * This is a shorthand hook for getting the biggest discount possible in a currency.
 * For example, in the US, you can get a discount on the pod4 ultra, mattress (pod), and sleep essentials bundle.
 * @returns Discount number and formatted discount number
 */
export const useAllProductsDiscount = () => {
	const currency = useCurrency()
	const discountCode = useStore((state) => state.promo.discountCode)

	const pod4UltraPrices = usePod4UltraPrices()
	const sleepEssentialsBundleDiscountNumber = useSleepEssentialsBundlePrices().discountNumber
	const podDiscountNumber = usePodPrices().discountNumber

	return useMemo(() => {
		const currentDiscountCode = discountCode?.toLowerCase()
		const isMember = currentDiscountCode === 'members'
		const isReferral = currentDiscountCode === 'reffrb'
		if (isMember || isReferral) {
			return {
				number: pod4UltraPrices.discountNumber,
				formatted: pod4UltraPrices.discountFormatted,
			}
		}

		const discountNumber = pod4UltraPrices.discountNumber + sleepEssentialsBundleDiscountNumber + podDiscountNumber
		return {
			number: discountNumber,
			formatted: PriceManager.formatPriceToCurrencyNoDecimal(discountNumber, currency),
		}
	}, [pod4UltraPrices, sleepEssentialsBundleDiscountNumber, podDiscountNumber, discountCode, currency])
}

/**
 * Some legacy hooks from fringe computed values
 */

/**
 * @deprecated Use `usePod4UltraPrices` instead
 */
export const useTotalProductDiscount = () => {
	const currency = useCurrency()
	const pod4UltraDiscountNumber = usePod4UltraPrices().discountNumber

	return useMemo(() => {
		return {
			number: pod4UltraDiscountNumber,
			formatted: PriceManager.formatPriceToCurrencyNoDecimal(pod4UltraDiscountNumber, currency),
		}
	}, [pod4UltraDiscountNumber, currency])
}

/**
 * @deprecated Use `usePod4UltraPrices` instead
 */
export const useGetUpToDiscountNumber = () => {
	const totalProductDiscountNumber = useTotalProductDiscount().number
	return useMemo(() => {
		return totalProductDiscountNumber
	}, [totalProductDiscountNumber])
}

/**
 * @deprecated This hook is poorly defined/named. I can't find a use case for it.
 */
export const useDiscountFormatted = () => {
	const region = useStore((state) => state.settings.currentRegion)
	const totalProductDiscountFormatted = useTotalProductDiscount().formatted
	const pod4DiscountFormatted = usePod4Prices().discountFormatted

	return useMemo(() => {
		if (region === '' || region === 'us' || region === 'ca') {
			return totalProductDiscountFormatted
		}
		return pod4DiscountFormatted
	}, [totalProductDiscountFormatted, pod4DiscountFormatted, region])
}
