import { withLenses } from '@dhmk/zustand-lens'
import { create } from 'zustand'
import { createPriceSlice } from './prices'
import { createTimerSlice } from './timer'
import { createModalSlice } from './modal/modal.slice'
import { createProductSlice } from './product'
import { createCartSlice } from './cart'
import { createNavSlice } from './nav'
import { createPromoSlice } from './promo'
import { PriceManager } from 'prices/priceManager'
import { SiteSettings, VanityData } from 'config/types'
import { RegionShort } from 'utils/internationalization'
import { createSettingsSlice } from './settings'
import { createShopSlice } from './shop'
import { Currency } from 'prices/types'
import { ProductPriceMap } from 'prices/types'
import { devtools } from 'zustand/middleware'

export interface RootStoreInitData {
	currentRegion: RegionShort
	currency: Currency
	priceManager: PriceManager
	allPrices: ProductPriceMap
	isVanity: boolean
	pathname: string
	currentPage: string
	t1Param?: string

	siteSettings: {
		siteSettings: SiteSettings
		activeSiteSale: SiteSettings
		globalPromoCode: string
		isVanity: boolean
		vanity: VanityData
	}
}

export const createStore = (initData: RootStoreInitData) => {
	return create(
		devtools(
			withLenses({
				settings: createSettingsSlice(initData.currentRegion, initData.siteSettings),
				timer: createTimerSlice(),
				prices: createPriceSlice(initData.currentRegion, initData.priceManager, initData.allPrices),
				modal: createModalSlice(),
				product: createProductSlice(initData.currentRegion, initData.priceManager),
				cart: createCartSlice(),
				nav: createNavSlice(),
				promo: createPromoSlice(initData.currentPage, initData.siteSettings.siteSettings, initData.currentRegion),
				shop: createShopSlice({ pathname: initData.pathname, priceStoreData: { priceManager: initData.priceManager, allPrices: initData.allPrices, currency: initData.currency } }),
			}),
			{
				name: 'RootStore',
				enabled: !process.env.NEXT_PUBLIC_IS_PROD,
			}
		)
	)
}
