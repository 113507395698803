import { lens } from '@dhmk/zustand-lens'
import { SiteSettings, VanityData } from 'config/types'
import { setVanityData } from 'config/vanity'
import { getUnitForRegion } from 'constants/units'
import { RegionShort } from 'utils/internationalization'
import { RootState } from '../root.types'
import { SettingsSlice } from './settings.types'
import { processSiteSettings } from './settings.utils'

export const createSettingsSlice = (
	initRegion: RegionShort,
	initSiteSettings: {
		siteSettings: SiteSettings
		activeSiteSale: SiteSettings
		globalPromoCode: string
		isVanity: boolean
		vanity: VanityData
	}
) => {
	const store = lens<SettingsSlice, RootState>((set, get, api) => ({
		currentRegion: initRegion,
		currentCountry: '',
		currentPage: '/',
		currentQuery: '',
		settings: initSiteSettings.siteSettings,
		windowHistoryLength: 0,
		windowLocationHref: '',
		isPodProductPageFirstLoad: true,
		siteSaleIsActive: !!initSiteSettings.activeSiteSale,
		globalPromoCode: initSiteSettings.globalPromoCode,
		navBarTransparency: true,
		isVanity: initSiteSettings.isVanity,
		coverStickyHeight: 0,
		localizeReady: false,
		localizeLanguage: null,

		handleCouponReceived: () => {
			get().loadSettings()
		},

		removeCouponReceivedListener: () => {
			if (typeof window !== 'undefined') {
				window.removeEventListener('coupon_received', get().handleCouponReceived)
			}
		},

		addCouponReceivedListener: () => {
			if (typeof window !== 'undefined') {
				window.addEventListener('coupon_received', get().handleCouponReceived)
			}
		},

		setRegion: (region?: string) => {
			if (region == null) {
				return
			}

			let _region: RegionShort

			switch (region) {
				case 'eu':
				case 'de':
				case 'es':
				case 'it':
				case 'mc':
				case 'nl':
				case 'fr':
				case 'fi':
				case 'at':
					_region = 'eu'
					break
				case 'se':
					_region = 'se'
					break
				case 'dk':
					_region = 'dk'
					break
				case 'gb':
				case 'uk':
					_region = 'uk'
					break
				case 'ca':
					_region = 'ca'
					break
				case 'uae':
				case 'ae':
					_region = 'ae'
					break
				case 'au':
					_region = 'au'
					break
				case 'ch':
					_region = 'ch'
					break
				case 'mx':
					_region = 'mx'
					break
				default:
					_region = ''
			}

			set({ currentCountry: region, currentRegion: _region }, undefined, 'settings/setRegion')
			api.getState().product.refreshProducts()
		},

		setCurrentPage: (page: string) => {
			set({ currentPage: page }, undefined, 'settings/setCurrentPage')
		},

		setNavBarTransparency: (transparency: boolean) => {
			set({ navBarTransparency: transparency }, undefined, 'settings/setNavBarTransparency')
		},

		setCoverStickyHeight: (height: number) => {
			set({ coverStickyHeight: height }, undefined, 'settings/setCoverStickyHeight')
		},

		setLocalizeReady: () => {
			set({ localizeReady: true, localizeLanguage: typeof window !== 'undefined' ? window.Localize?.getLanguage() ?? null : null }, undefined, 'settings/setLocalizeReady')
		},

		convertUnit: (text: string) => {
			const region = get().currentRegion
			return getUnitForRegion(text, region)
		},

		setWindowHistoryLength: (length: number) => {
			set({ windowHistoryLength: length }, undefined, 'settings/setWindowHistoryLength')
		},

		setWindowLocationHref: (href: string) => {
			set({ windowLocationHref: href }, undefined, 'settings/setWindowLocationHref')
		},

		loadSettings: (vanity_data?: VanityData, return_settings?: boolean): SiteSettings => {
			const referral_session_code = typeof window !== 'undefined' ? sessionStorage.getItem('referral_session_code') : null
			if (typeof window !== 'undefined' && !referral_session_code) {
				get().removeCouponReceivedListener()
				get().addCouponReceivedListener()
			}

			const currentRegion = get().currentRegion
			const { siteSettings, activeSiteSale, globalPromoCode, isVanity, vanity } = processSiteSettings(currentRegion, return_settings, referral_session_code, vanity_data)

			set({ settings: siteSettings, siteSaleIsActive: !!activeSiteSale, globalPromoCode }, undefined, 'settings/loadSettings')

			api.getState().promo.setPromoBar(get().currentPage)
			api.getState().promo.setPromo(siteSettings.promoCode)

			set({ isVanity }, undefined, 'settings/setIsVanity')
			if (isVanity) {
				setVanityData(vanity.page)
			}

			if (return_settings) {
				return siteSettings
			}

			return null
		},
	}))

	return store
}
