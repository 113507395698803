import { useCurrency, useFinancingProvider } from 'stores/prices'

import { determineSubscriptionType, SubscriptionType } from 'components/_utils/subscriptionUtils'
import { BenefitProps } from 'components/_utils/utilityTypes'
import { SelectorKey } from 'components/Phantom/_shop/Selectors'
import { VisualProps } from 'components/Phantom/_shop/Visuals'
import { getShippingText } from 'config/shipping_timelines'
import { PriceManager } from 'prices'
import { MembershipProducts } from 'products/allProducts'
import { isMembershipProductId } from 'products/utils'
import { getMembershipTooltip } from 'shop/selectorData/autopilotProps'
import { ItemizedSelection, SelectionKey } from 'shop/Shop.types'
import { FragilePilotPrices } from 'shop/utils/fragilePilotPrices'
import { getAccessoriesVisuals, getAutopilotVisuals, getEssentialBundleVisuals, getLegKitVisuals, getMattressVisuals, getModelVisuals, getSizeVisuals, getSummaryVisuals } from 'shop/visualData'
import { getTheBaseVisuals } from 'shop/visualData/theBaseVisual'
import { useLocalizeLanguage, useMetricRegion } from 'stores/settings'
import { useStore } from 'stores/StoreProvider'
import { PropData } from './shop.types'
import {
	getAllAccessoriesProps,
	getAssociatedProduct,
	getAutoPilotFragileProps,
	getAutoPilotProps,
	getCurrentSelectionEntries,
	getCurrentSelectors,
	getEightMattressProps,
	getEssentialsBundleProps,
	getFilteredUpsellSelections,
	getLegKitProps,
	getLegKitUpsellData,
	getPodModelFragileProps,
	getPodModelProps,
	getPodSizeProps,
	getPodVariant,
	getSelectedUpsells,
	getSelectedVariant,
	getStripeLineItems,
	getSummaryProps,
	getTheBaseProps,
} from './shop.utils'
/**
 * @deprecated Debug only
 */
export const useShopStoreDump = () => {
	const paymentOption = useStore((state) => state.shop.paymentOption)
	const podData = useStore((state) => state.shop.podData)
	const selectedSelections = useStore((state) => state.shop.selectedSelections)
	const error = useStore((state) => state.shop.error)
	const activeModal = useStore((state) => state.shop.activeModal)
	const highlightedSelector = useStore((state) => state.shop.highlightedSelector)
	const subscriptionInformation = useStore((state) => state.shop.subscriptionInformation)
	const goingToCheckout = useStore((state) => state.shop.goingToCheckout)
	const showShopBanner = useStore((state) => state.shop.showShopBanner)

	return {
		paymentOption,
		podData,
		selectedSelections,
		error,
		activeModal,
		highlightedSelector,
		subscriptionInformation,
		goingToCheckout,
		showShopBanner,
	}
}

export const useSelectedVariant = () => {
	const selectedSize = useStore((state) => state.shop.selectedSelections).size
	const currency = useCurrency()
	return getSelectedVariant(currency, selectedSize)
}

export const useCurrentSelectors = (): SelectorKey[] => {
	const selectedSelections = useStore((state) => state.shop.selectedSelections)
	const paymentOption = useStore((state) => state.shop.paymentOption)

	return getCurrentSelectors(selectedSelections['pod-model'], paymentOption)
}

export const usePodVariant = () => {
	const podData = useStore((state) => state.shop.podData)
	const selectedVariant = useSelectedVariant()

	return getPodVariant(podData, selectedVariant)
}

export const useCurrentSelectionEntries = () => {
	const selectedSelections = useStore((state) => state.shop.selectedSelections)
	return getCurrentSelectionEntries(selectedSelections)
}

const useShouldHideAutopilotSection = () => {
	const subscriptionInformation = useStore((state) => state.shop.subscriptionInformation)
	const subscriptionType = determineSubscriptionType(subscriptionInformation)
	return subscriptionType === SubscriptionType.MANDATORY_ERA_ENHANCED || subscriptionType === SubscriptionType.VIP
}

export const useFilteredUpsellSelections = () => {
	const selectedSelections = useStore((state) => state.shop.selectedSelections)
	const selections = useCurrentSelectionEntries()
	const shouldHideAutopilotSection = useShouldHideAutopilotSection()

	return getFilteredUpsellSelections(selections, selectedSelections, shouldHideAutopilotSection)
}

export const useSelectedUpsells = () => {
	const selections = useFilteredUpsellSelections()
	const selectedSelections = useStore((state) => state.shop.selectedSelections)
	const podData = useStore((state) => state.shop.podData)

	const priceManager = useStore((state) => state.prices.priceManager)
	const allPrices = useStore((state) => state.prices.allPrices)
	const currency = useCurrency()

	const storeData = {
		priceManager,
		allPrices,
		currency,
	}

	return getSelectedUpsells(selections, selectedSelections, podData, storeData)
}

export const useStripeLineItems = () => {
	const podVariant = usePodVariant()
	const subInfo = useStore((state) => state.shop.subscriptionInformation)
	const selectedUpsells = useSelectedUpsells()
	const selectedVariant = useSelectedVariant()

	return getStripeLineItems(podVariant, subInfo, selectedUpsells, selectedVariant)
}

export const useShowAutopilotPricing = () => {
	const subInfo = useStore((state) => state.shop.subscriptionInformation)
	const selectedUpsells = useSelectedUpsells()

	for (const upsell of selectedUpsells) {
		if (subInfo && (upsell.handle === MembershipProducts.EightsleepProMembership.handle || upsell.handle === MembershipProducts.AutopilotStandard.handle)) {
			return false
		}
	}

	return true
}

export const usePodPrices = () => {
	const podVariant = usePodVariant()
	const price = podVariant?.prices.price
	const comparePrice = podVariant?.prices.comparePrice
	return {
		price,
		comparePrice,
	}
}

export const useItemizedSelections = () => {
	const podPrices = usePodPrices()
	const selectedUpsells = useSelectedUpsells()
	const podData = useStore((state) => state.shop.podData)
	const selectedVariant = useSelectedVariant()
	const selectedSelections = useStore((state) => state.shop.selectedSelections)

	const itemizedSelections: ItemizedSelection[] = []

	itemizedSelections.push({
		productTitle: podData.name,
		price: podPrices.price,
		comparePrice: podPrices.comparePrice,
		fragileText: `$${FragilePilotPrices[selectedVariant]}/mo`,
	})

	selectedUpsells.forEach((upsell) => {
		const upsellItem = selectedVariant in upsell.variants ? upsell.variants[selectedVariant] : upsell.variants.standard
		const item = {
			productTitle: upsell.name,
			price: upsellItem.prices.price,
			comparePrice: upsellItem.prices.comparePrice,
			subscription: isMembershipProductId(upsell.id),
			tooltip: isMembershipProductId(upsell.id) && getMembershipTooltip(selectedSelections['auto-pilot'] === 'autopilot_enhanced' ? 5 : 2),
			fragileText: isMembershipProductId(upsell.id) ? `included` : undefined,
		}

		itemizedSelections.push(item)
	})

	return itemizedSelections
}

export const useUpsellTotalPrices = () => {
	const selectedUpsells = useSelectedUpsells()
	const subInfo = useStore((state) => state.shop.subscriptionInformation)
	const selectedVariant = useSelectedVariant()

	let upsellPrice = 0
	let upsellComparePrice = 0

	for (const upsell of selectedUpsells) {
		const upsellItem = selectedVariant in upsell.variants ? upsell.variants[selectedVariant] : upsell.variants.standard

		if (subInfo && (upsell.handle === MembershipProducts.EightsleepProMembership.handle || upsell.handle === MembershipProducts.AutopilotStandard.handle)) {
			continue
		}

		upsellPrice += upsellItem.prices.price
		upsellComparePrice += upsellItem.prices.comparePrice
	}

	return {
		price: upsellPrice,
		comparePrice: upsellComparePrice,
		savings: Math.max(upsellComparePrice - upsellPrice, 0),
	}
}

export const useShopTotal = () => {
	const currency = useCurrency()
	const paymentOption = useStore((state) => state.shop.paymentOption)
	const selectedVariant = useSelectedVariant()
	const podPrices = usePodPrices()
	const upsellPrices = useUpsellTotalPrices()

	if (paymentOption === 'fragile') {
		return {
			price: FragilePilotPrices[selectedVariant],
			comparePrice: FragilePilotPrices[selectedVariant],
			priceString: `$${FragilePilotPrices[selectedVariant]}/mo`,
			comparePriceString: `$${FragilePilotPrices[selectedVariant]}/mo`,
			savingsAmount: 0,
			savingsAmountString: '',
		}
	}

	const price = podPrices.price + upsellPrices.price
	const comparePrice = podPrices.comparePrice + upsellPrices.comparePrice

	const priceString = PriceManager.formatPriceToCurrencyNoDecimal(price / 100, currency)
	const comparePriceString = PriceManager.formatPriceToCurrencyNoDecimal(comparePrice / 100, currency)

	const savingsAmount = comparePrice > price ? comparePrice - price : 0
	const savingsAmountString = PriceManager.formatPriceToCurrencyNoDecimal(savingsAmount / 100, currency) + ' off'

	return {
		price,
		comparePrice,
		priceString,
		comparePriceString,
		savingsAmount,
		savingsAmountString,
	}
}

export const useShippingText = () => {
	const podVariant = usePodVariant()
	const selectedSelections = useStore((state) => state.shop.selectedSelections)
	const currentRegion = useStore((state) => state.settings.currentRegion)

	return getShippingText(podVariant, currentRegion, selectedSelections['eight-mattress'] as 'yes_mattress' | 'no_mattress', selectedSelections['the-base'] as 'standard' | 'none')
}

export const useMembershipProduct = () => {
	const selectedSelections = useStore((state) => state.shop.selectedSelections)
	const podData = useStore((state) => state.shop.podData)
	const storeData = {
		priceManager: useStore((state) => state.prices.priceManager),
		allPrices: useStore((state) => state.prices.allPrices),
		currency: useCurrency(),
	}

	return getAssociatedProduct('auto-pilot', selectedSelections, podData, storeData)
}

export const useTotalPriceFinancing = () => {
	const currency = useCurrency()
	const shopTotal = useShopTotal()

	return {
		price: PriceManager.formatPriceToCurrencyNoDecimal(PriceManager.getAffirmFinancingAmount(shopTotal.price / 100), currency),
		compare: PriceManager.formatPriceToCurrencyNoDecimal(PriceManager.getAffirmFinancingAmount(shopTotal.comparePrice / 100), currency),
	}
}

export const useTotalPriceFinancingNoMembership = (): { price: string; compare: string } => {
	const paymentOption = useStore((state) => state.shop.paymentOption)
	const shouldHideAutopilotSection = useShouldHideAutopilotSection()
	const membershipProduct = useMembershipProduct()
	const currency = useCurrency()
	const shopTotal = useShopTotal()

	const { price: totalPriceFinancing, compare: totalPriceFinancingCompare } = useTotalPriceFinancing()

	if (paymentOption === 'fragile') {
		return {
			price: '',
			compare: '',
		}
	}

	if (shouldHideAutopilotSection) {
		return {
			price: totalPriceFinancing,
			compare: totalPriceFinancingCompare,
		}
	}

	const membershipPrice = membershipProduct.variants.standard.prices.price
	const priceWithoutMembership = shopTotal.price - membershipPrice

	const membershipComparePrice = membershipProduct.variants.standard.prices.comparePrice
	const comparePriceWithoutMembership = shopTotal.comparePrice - membershipComparePrice

	return {
		price: PriceManager.formatPriceToCurrencyNoDecimal(PriceManager.getAffirmFinancingAmount(priceWithoutMembership / 100), currency),
		compare: PriceManager.formatPriceToCurrencyNoDecimal(PriceManager.getAffirmFinancingAmount(comparePriceWithoutMembership / 100), currency),
	}
}

export const useHasAccessory = () => {
	const selectedUpsells = useSelectedUpsells()
	const upsells = selectedUpsells.filter((it) => !isMembershipProductId(it.id))
	return upsells.length > 0
}

export const useSelectedPodVariantFinancing = () => {
	const currency = useCurrency()
	const podPrices = usePodPrices()

	return {
		list: PriceManager.formatPriceToCurrencyNoDecimal(PriceManager.getAffirmFinancingAmount(podPrices.price / 100), currency),
		compare: PriceManager.formatPriceToCurrencyNoDecimal(PriceManager.getAffirmFinancingAmount(podPrices.comparePrice / 100), currency),
	}
}

export const useSelectorProps = (selectorKeys: SelectorKey[]) => {
	const podVariant = usePodVariant()
	const paymentOption = useStore((state) => state.shop.paymentOption)
	const selectedPodVariantFinancing = useSelectedPodVariantFinancing()
	const currency = useCurrency()

	const podData = useStore((state) => state.shop.podData)
	const selectedSelections = useStore((state) => state.shop.selectedSelections)
	const selectedVariant = useSelectedVariant()

	const subscriptionInformation = useStore((state) => state.shop.subscriptionInformation)

	const checkout = useStore((state) => state.shop.checkout)

	const priceStoreData = {
		priceManager: useStore((state) => state.prices.priceManager),
		allPrices: useStore((state) => state.prices.allPrices),
		currency,
	}

	const shopTotal = useShopTotal()
	const goingToCheckout = useStore((state) => state.shop.goingToCheckout)
	const financingProvider = useFinancingProvider()

	const currentRegion = useStore((state) => state.settings.currentRegion)
	const totalPriceFinancing = useTotalPriceFinancing()
	const orderItemsString = useOrderItemsString()
	const benefits = useBenefits()

	const propData: PropData = {
		podVariant,
		paymentOption,
		selectedPodVariantFinancing,
		currency,
		podData,
		selectedSelections,
		selectedVariant,
		priceStoreData,
		subscriptionInformation,
		goingToCheckout,
		shopTotal,
		currentRegion,
		financingProvider,
		totalPriceFinancing: totalPriceFinancing.price,
		orderItemsString,
		benefits,
	}

	return selectorKeys
		.map((selectorKey) => {
			switch (selectorKey) {
				case 'pod-model':
					return getPodModelProps(propData)
				case 'size':
					return getPodSizeProps(propData)
				case 'leg-kit':
					return getLegKitProps(propData)
				case 'auto-pilot':
					return getAutoPilotProps(propData)
				case 'essentials-bundle':
					return getEssentialsBundleProps(propData)
				case 'the-base':
					return getTheBaseProps(propData, false)
				case 'eight-mattress':
					return getEightMattressProps(propData)
				case 'accessories':
					return getAllAccessoriesProps(propData)
				case 'summary':
					return getSummaryProps(propData, checkout)
				case 'pod-model-fragile':
					return getPodModelFragileProps(propData)
				case 'auto-pilot-fragile':
					return getAutoPilotFragileProps()
				default:
					return undefined
			}
		})
		.filter((x) => !!x)
}

export const useProductName = () => {
	const podData = useStore((state) => state.shop.podData)
	const podVariant = usePodVariant()
	return `${podData.name} (${podVariant.name})`
}

export const useOrderItemsString = () => {
	const productName = useProductName()
	const selectedUpsells = useSelectedUpsells()
	return [productName, ...selectedUpsells.map((u) => u.name)].join(' + ')
}

export const useWarrantyText = () => {
	const selectedSelections = useStore((state) => state.shop.selectedSelections)
	let warrantyText = '2-year warranty'
	if (selectedSelections['auto-pilot'] === 'autopilot_enhanced') {
		warrantyText = '5-year warranty'
	}
	return warrantyText
}

export const useBenefits = (): BenefitProps[] => {
	const benefits: BenefitProps[] = []
	const paymentOption = useStore((state) => state.shop.paymentOption)
	const warrantyText = useWarrantyText()

	if (paymentOption === 'fragile') {
		return [
			{
				icon: 'CalendarLight',
				text: 'Rent as long as you’d like',
			},
			{
				icon: 'CloseCircleLight',
				text: 'Cancel anytime',
			},
			{
				icon: 'EightBoxLight',
				text: 'Included upgrades',
			},
			{
				icon: 'WarrantyLight',
				text: 'Ongoing warranty*',
			},
		]
	}

	benefits.push(
		{
			icon: 'ShieldCheckLight',
			text: warrantyText,
		},
		{
			icon: 'MoonLight',
			text: '30-day risk-free trial',
		},
		{
			icon: 'EightBoxLight',
			text: 'Free returns',
		},
		{
			icon: 'DeliveryLight',
			text: 'Free shipping',
		}
	)

	return benefits
}

export const useSelectionKeys = () => {
	const keys: SelectionKey[] = []
	const filteredUpsellSelections = useFilteredUpsellSelections()
	const selectedSelections = useStore((state) => state.shop.selectedSelections)
	const podData = useStore((state) => state.shop.podData)
	const priceStoreData = {
		priceManager: useStore((state) => state.prices.priceManager),
		allPrices: useStore((state) => state.prices.allPrices),
		currency: useCurrency(),
	}

	for (const selection of filteredUpsellSelections) {
		const product = getAssociatedProduct(selection.key, selectedSelections, podData, priceStoreData)
		if (product) {
			keys.push(selection.key)
		}
	}

	return keys
}

export const useCurrentSelections = () => {
	return useStore((state) => state.shop.selectedSelections)
}

export const useCurrentVisuals = (): VisualProps[] => {
	const visuals: VisualProps[] = []
	const localizeLanguage = useLocalizeLanguage()
	const metricRegion = useMetricRegion()
	const selectedSelections = useCurrentSelections()
	const paymentOption = useStore((state) => state.shop.paymentOption)

	const isPod3 = selectedSelections['pod-model'] === 'pod_3_cover_perfect'
	const podName = selectedSelections['pod-model'].replace(/[-_]/g, '').replace('coverperfect', '')
	const warrantyLength = selectedSelections['auto-pilot'] === 'autopilot_enhanced' ? 5 : 2
	const languageCode = localizeLanguage

	const selectionKeys = useSelectionKeys()

	visuals.push(
		getModelVisuals(selectedSelections['pod-model'], warrantyLength, metricRegion, languageCode, paymentOption),
		getSizeVisuals(metricRegion, isPod3, languageCode),
		getLegKitVisuals(metricRegion),
		getAutopilotVisuals(metricRegion, languageCode),
		getEssentialBundleVisuals(),
		getMattressVisuals(),
		getAccessoriesVisuals(),
		getSummaryVisuals(podName, selectionKeys),
		getTheBaseVisuals()
	)

	return visuals
}

export const useLegKitUpsellData = () => {
	const priceStoreData = {
		priceManager: useStore((state) => state.prices.priceManager),
		allPrices: useStore((state) => state.prices.allPrices),
		currency: useCurrency(),
	}

	return getLegKitUpsellData(priceStoreData)
}
