import { COVER_PRICE_PLACEHOLDER, POD_PRICE_PLACEHOLDER } from 'config/default'
import { ShopPages } from 'products/types'
import { useMemo } from 'react'
import { usePod4UltraPrices } from 'stores/prices'
import { useStore } from 'stores/StoreProvider'
import { getShopPageProducts } from './product.utils'

export const useGetShopPageProducts = (shop: ShopPages) => {
	const coverShop = useStore((state) => state.product.coverShop)
	const currentRegion = useStore((state) => state.settings.currentRegion)
	const productManager = useStore((state) => state.product.productManager)

	return getShopPageProducts(coverShop, currentRegion, productManager, shop)
}

export const useGetMemberAccessoriesProducts = (page: string, membersQueryParam: string) => {
	const region = useStore((state) => state.settings.currentRegion)
	const saleName = useStore((state) => state.settings.settings.saleName)
	const productManager = useStore((state) => state.product.productManager)
	const pod4UltraPrices = usePod4UltraPrices()

	return useMemo(() => {
		const data = productManager.getPageProducts(page, region, saleName)

		data.forEach((section) =>
			section.items.forEach((it) => {
				it.buttonQuery = membersQueryParam
				it.productDescription = it.productDescription.map((p) => p.replace(COVER_PRICE_PLACEHOLDER, pod4UltraPrices.discountFormatted))
				it.mobileDescription = it.mobileDescription?.map((p) => p.replace(COVER_PRICE_PLACEHOLDER, pod4UltraPrices.discountFormatted))
				it.ctaText = it.ctaText?.replace(POD_PRICE_PLACEHOLDER, pod4UltraPrices.discountFormatted).replace(COVER_PRICE_PLACEHOLDER, pod4UltraPrices.discountFormatted)
			})
		)
		return data
	}, [page, region, saleName, productManager, pod4UltraPrices, membersQueryParam])
}

export const useGetAccessoriesProducts = (page: string) => {
	const region = useStore((state) => state.settings.currentRegion)
	const saleName = useStore((state) => state.settings.settings.saleName)
	const productManager = useStore((state) => state.product.productManager)
	const pod4UltraPrices = usePod4UltraPrices()

	return useMemo(() => {
		const data = productManager.getPageProducts(page, region, saleName)

		data.forEach((section) =>
			section.items.forEach((it) => {
				it.productDescription = it.productDescription.map((p) => p.replace(COVER_PRICE_PLACEHOLDER, pod4UltraPrices.discountFormatted))
				it.mobileDescription = it.mobileDescription?.map((p) => p.replace(COVER_PRICE_PLACEHOLDER, pod4UltraPrices.discountFormatted))
				it.ctaText = it.ctaText?.replace(POD_PRICE_PLACEHOLDER, pod4UltraPrices.discountFormatted).replace(COVER_PRICE_PLACEHOLDER, pod4UltraPrices.discountFormatted)
			})
		)
		return data
	}, [page, region, saleName, productManager, pod4UltraPrices])
}

export const useRegionSellsBundle = () => {
	const region = useStore((state) => state.settings.currentRegion)

	return region === ''
}

export const useRegionSellsMattress = () => {
	const region = useStore((state) => state.settings.currentRegion)

	return region === 'ca' || region === ''
}
