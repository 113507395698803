// Declare fbq on window
declare global {
	interface Window {
		fbq: (type: 'track' | 'trackCustom', event: string, data?: Record<string, any>, eventID?: { eventID: string }) => void
	}
}

interface FacebookIds {
	fbc?: string
	fbp?: string
}

export const fbqTrack = (...args: Parameters<typeof window.fbq>) => {
	if (typeof window !== 'undefined' && window.fbq) {
		window.fbq(...args)
	} else {
		console.warn('Facebook Pixel not initialized yet.')
	}
}

export const getFbIds = (): FacebookIds => {
	const cookiePairs = document.cookie
		.split(';')
		.map((cookiesStr) => cookiesStr.split('='))
		.map((cookiePair) => cookiePair.map((keyValue) => keyValue.trim()))
		.filter((pair) => pair.length === 2) as [string, string][]
	const cookies = new Map(cookiePairs)
	const fbp = cookies.get('_fbp')
	const fbc = cookies.get('_fbc')

	const result: FacebookIds = {}

	if (fbc) {
		result.fbc = fbc
	}
	if (fbp) {
		result.fbp = fbp
	}

	return result
}

const fbEvents = {
	track(title: string, data?: Record<string, unknown>, eventID?: string) {
		const ids = getFbIds()
		const data2 = {
			...data,
			...ids,
			event_id: eventID,
		}
		fbqTrack('track', title, data2)
	},

	trackCustom(event: string, data?: Record<string, unknown>, eventID?: string) {
		const ids = getFbIds()
		const data2 = {
			...data,
			...ids,
			event_id: eventID,
		}
		fbqTrack('trackCustom', event, data2)
	},

	pageView(eventID?: string) {
		const ids = getFbIds()
		const data2 = {
			...ids,
			event_id: eventID,
		}
		fbqTrack('track', 'PageView', data2)
	},
}

export default fbEvents
