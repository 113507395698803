import { CartLineItem } from 'cart'
import { trackAddThermoCoverToCart, trackAddToCart, trackClickedButton } from 'events/index'
import { isPodCoverProductId } from 'products/utils'

export const sendTrackingEvents = (item: CartLineItem, buttonID: string) => {
	trackAddToCart(item)
	trackClickedButton('Add to cart', buttonID)
	const coverProduct = item.productId && isPodCoverProductId(item.productId)
	if (coverProduct) {
		trackAddThermoCoverToCart()
	}
}
