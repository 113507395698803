import { lens } from '@dhmk/zustand-lens'
import { RootState } from '../root.types'
import { NavSlice } from './nav.types'

export const createNavSlice = () => {
	return lens<NavSlice, RootState>((set, get, api) => ({
		stickyAtcVisible: false,
		route: '/',
		collapsed: false,

		setStickyAtcVisible: (show: boolean) => set({ stickyAtcVisible: show }, undefined, 'nav/setStickyAtcVisible'),
		setRoute: () => set({ route: window.location.pathname }, undefined, 'nav/setRoute'),
		toggle: (b?: boolean) => set({ collapsed: b ?? !get().collapsed }, undefined, 'nav/toggle'),
	}))
}
