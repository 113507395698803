import { Attribute, LineItem } from 'cart'
import { determineSubscriptionType, getSubscriptionFeatures, getSubscriptionYearlyCost, SubscriptionResponse, SubscriptionType } from 'components/_utils/subscriptionUtils'
import { CardSelectionItemProps } from 'components/Phantom/_shop/CardSelectionItem/types'
import { PricingInfoProps } from 'components/Phantom/_shop/PricingInfo/types'
import { SelectorKey, SingleCardProps, StandardCardProps, StandardCheckboxProps, SummaryProps } from 'components/Phantom/_shop/Selectors'
import { getShippingText } from 'config/shipping_timelines'
import { trackAccessoryShopPageVisit, trackCoverShopPageVisit } from 'events/index'
import { jwtDecode } from 'jwt-decode'
import { PriceManager } from 'prices/priceManager'
import { Currency, PricingDataFull, ProductPriceMap } from 'prices/types'
import { BundleProducts, MattressProducts, MembershipProducts, Pod4Accessories, PodCoverProducts, TheBaseProducts } from 'products/allProducts'
import { Product, ProductCore, ProductVariant, VariantCore, VariantKey } from 'products/types'
import { getVariantDimensions } from 'products/utils'
import { getAccessoriesProps } from 'shop/selectorData/accessoriesProps'
import { getEnhancedPlan, getStandardPlan } from 'shop/selectorData/autopilotProps'
import { getMattressCheckboxProps } from 'shop/selectorData/mattressProps'
import { getModelDescription, getModelOptions } from 'shop/selectorData/modelProps'
import { MembershipPricing, PaymentOption, PlanData, PricingData, SelectionEntry, SelectionKey, Selections } from 'shop/Shop.types'
import { currencyDefaultSizes } from 'shop/utils/currencyDefaultSizes'
import { FragilePilotPrices } from 'shop/utils/fragilePilotPrices'
import { isSelectionAllowed, isSelectionKeyUpsell } from 'shop/utils/selectionFiltering'
import { getProductWithPrices } from 'stores/prices/prices.utils'
import { RegionShort } from 'utils/internationalization'
import { PodModel, PriceStoreData, PropData, ShopSelection } from '.'
import { getTokenFromBrowser } from 'components/_hooks/useGetMemberDataFromAppInjection'

const POD_4_ULTRA_PATHS = ['pod4ultra', 'pod-compare', 'cooling-sleep']

export const getDefaultProductId = (asPath?: string): PodModel => {
	if (POD_4_ULTRA_PATHS.some((path) => asPath?.includes(path))) return 'pod_4_ultra'
	return 'pod_4'
}

export const shouldShowShopBanner = (asPath?: string) => {
	if (asPath && asPath.includes('pod-cover-email')) return true
	return false
}

export const getPodProductCore = (podModel: PodModel) => {
	switch (podModel) {
		case 'pod_3_cover_perfect':
			return PodCoverProducts.Pod3CoverPerfect

		case 'pod_4':
			return PodCoverProducts.Pod4

		case 'pod_4_ultra':
			return PodCoverProducts.Pod4Ultra

		default:
			return PodCoverProducts.Pod4Ultra
	}
}

export const addProductPrices = (productCore: ProductCore, storeData: PriceStoreData) => {
	const productPriceData = getProductWithPrices(productCore, storeData)
	const actualPrices: { [key in VariantKey]?: ProductVariant } = {}

	Object.values(productPriceData.variants).forEach((val) => {
		if (val.id && val.prices.price > 0) {
			actualPrices[val.key] = val
		}
	})

	productPriceData.variants = actualPrices

	return productPriceData
}

export const getSelectedVariant = (currency: Currency, selectedSize?: VariantKey) => {
	return selectedSize ?? currencyDefaultSizes[currency]
}

export const trackViewShopPage = (data: { pod4UltraPrices: PricingDataFull; model: PodModel; selectedVariant: VariantKey; currentRegion: RegionShort; globalPromoCode: string; podData: Product }) => {
	const { pod4UltraPrices, model, selectedVariant, currentRegion, globalPromoCode, podData } = data

	trackCoverShopPageVisit({
		code: globalPromoCode,
		amount: pod4UltraPrices.discountNumber,
		region: currentRegion,
		size: selectedVariant,
		price: podData.variants[selectedVariant].prices.priceString,
		model: model,
	})
}

export const getCurrentSelectors = (selectedPodModel: PodModel, paymentOption: PaymentOption): SelectorKey[] => {
	const selections: SelectorKey[] = []

	switch (selectedPodModel) {
		case 'pod_3_cover_perfect':
		case 'pod_4':
			selections.push('pod-model', 'size', 'auto-pilot', 'essentials-bundle', 'eight-mattress')
			break
		case 'pod_4_ultra':
			selections.push('pod-model', 'size', 'leg-kit', 'auto-pilot', 'essentials-bundle', 'eight-mattress')
			break
		default:
			break
	}

	if (paymentOption === 'fragile') {
		return ['pod-model-fragile', 'size', 'auto-pilot-fragile']
	}

	return selections
}

export const getAssociatedProduct = (key: SelectionKey, selectedSelections: Selections, podData: Product, storeData: PriceStoreData) => {
	const selection = selectedSelections[key]

	if (!selection) {
		return undefined
	}

	// TODO abstract these checks like "selection === 'adjustable'" to better check the "truth value" of the selection.
	// Really these truth values should just be binary, but for now we're just checking for the string value.
	switch (key) {
		case 'pod-model':
			return podData
		case 'size':
			break
		case 'leg-kit':
			if (selection === 'adjustable') return getLegKitProduct(storeData)
			break
		case 'auto-pilot':
			if (selection === 'autopilot_standard') return addProductPrices(MembershipProducts.AutopilotStandard, storeData)
			if (selection === 'autopilot_enhanced') return addProductPrices(MembershipProducts.AutopilotEnhanced, storeData)
			break
		case 'essentials-bundle':
			if (selection === 'white') return getEssentialsProduct(storeData)
			break
		case 'the-base':
			if (selection === 'standard') return getBaseProduct(false, storeData)
			break
		case 'eight-mattress': {
			if (selection === 'yes_mattress') return getMattressProduct(storeData)?.product
			break
		}
		default:
			break
	}

	return undefined
}

export const getLineItemAttributes = (variant: VariantCore, subInfo: SubscriptionResponse) => {
	const attributes: Attribute[] = []

	if (variant.id === MembershipProducts.AutopilotEnhanced.variants.standard.id) {
		attributes.push({ key: 'warranty', value: 'extended warranty' })
	}

	if (variant.id === MembershipProducts.AutopilotEnhanced.variants.standard.id || variant.id === MembershipProducts.AutopilotStandard.variants.standard.id) {
		if (subInfo?.email) {
			const authToken = getTokenFromBrowser()
			let userId = ''
			try {
				const jwt = jwtDecode(authToken)
				userId = jwt?.sub || ''
				attributes.push({ key: 'upgrade_eight_user_id', value: userId })
			} catch (e) {
				console.error('Error decoding JWT', e)
			}
		}
	}
	return attributes
}

export const getPodVariant = (podData: Product, selectedVariant: VariantKey) => {
	return podData.variants[selectedVariant]
}

export const getShouldHideAutopilotSection = (subscriptionInformation: SubscriptionResponse) => {
	const subscriptionType = determineSubscriptionType(subscriptionInformation)
	return subscriptionType === SubscriptionType.MANDATORY_ERA_ENHANCED || subscriptionType === SubscriptionType.VIP
}

export const getFilteredUpsellSelections = (selections: SelectionEntry[], selectedSelections: Selections, shouldHideAutopilotSection: boolean) => {
	const entries: SelectionEntry[] = []
	for (const selection of selections) {
		if (isSelectionKeyUpsell(selection.key) && isSelectionAllowed(selection.key, selectedSelections, shouldHideAutopilotSection)) {
			entries.push(selection)
		}
	}

	return entries
}

export const getCurrentSelectionEntries = (selectedSelections: Selections) => {
	const entries: SelectionEntry[] = []
	for (const _key in selectedSelections) {
		const key = _key as SelectionKey
		const value = selectedSelections[key]
		if (value) {
			entries.push({ key, value })
		}
	}
	entries.sort((a, b) => a.key.localeCompare(b.key))
	return entries
}

export const getSelectedUpsells = (
	selections: SelectionEntry[],
	selectedSelections: Selections,
	podData: Product,
	storeData: { priceManager: PriceManager; allPrices: ProductPriceMap; currency: Currency }
) => {
	const products: Product[] = []

	for (const selection of selections) {
		const product = getAssociatedProduct(selection.key, selectedSelections, podData, storeData)
		if (product) {
			products.push(product)
		}
	}

	return products
}

export const getStripeLineItems = (podVariant: ProductVariant, subInfo: SubscriptionResponse, selectedUpsells: Product[], selectedVariant: VariantKey) => {
	const podItem: LineItem = {
		quantity: 1,
		variantId: podVariant.id,
		attributes: [{ key: 'size', value: podVariant.name }],
	}
	const lines: LineItem[] = []

	for (const upsell of selectedUpsells) {
		if (subInfo && (upsell.handle === MembershipProducts.EightsleepProMembership.handle || upsell.handle === MembershipProducts.AutopilotStandard.handle)) {
			continue
		}
		const variant = selectedVariant in upsell.variants ? upsell.variants[selectedVariant] : upsell.variants['standard']
		const line: LineItem = {
			quantity: 1,
			variantId: variant.id,
			attributes: getLineItemAttributes(variant, subInfo),
		}
		lines.push(line)
	}
	lines.push(podItem)
	return lines
}

export const getLegKitProduct = (storeData: PriceStoreData): Product | undefined => {
	const product = addProductPrices(Pod4Accessories.Pod4UltraLegKit, storeData)
	if (!product || !product.variants['standard']) {
		return undefined
	}
	return product
}

export const getEssentialsProduct = (storeData: PriceStoreData): Product | undefined => {
	const bundleWhite = addProductPrices(BundleProducts.SleepEssentialBundle, storeData)
	const bundleGray = addProductPrices(BundleProducts.SleepEssentialBundleGray, storeData)

	const numVariantsWhite = Object.keys(bundleWhite.variants).length
	const numVariantsGray = Object.keys(bundleGray.variants).length

	if (numVariantsWhite + numVariantsGray < 1) return undefined
	return numVariantsWhite > 0 ? bundleWhite : bundleGray
}

export const getBaseProduct = (member: boolean, storeData: PriceStoreData): Product | undefined => {
	if (!member) {
		return addProductPrices(TheBaseProducts.TheBaseNonMember, storeData)
	}
	return addProductPrices(TheBaseProducts.TheBase, storeData)
}

export const getMattressProduct = (storeData: PriceStoreData): { numLayers: number; product: Product } | undefined => {
	const _5LayerMattress = addProductPrices(MattressProducts.FiveLayerDiscounted, storeData)
	const _3LayerMattress = addProductPrices(MattressProducts.ThreeLayer, storeData)

	const numVariants5Layers = Object.keys(_5LayerMattress.variants).length
	const numVariants3Layers = Object.keys(_3LayerMattress.variants).length

	if (numVariants5Layers + numVariants3Layers < 1) return undefined

	const numLayers = numVariants5Layers > 0 ? 5 : 3
	const product = numVariants5Layers > 0 ? _5LayerMattress : _3LayerMattress
	return { numLayers, product }
}

export const getPodModelProps = (data: PropData): StandardCardProps | undefined => {
	const { podVariant, paymentOption, selectedPodVariantFinancing, currency } = data
	const price = podVariant.prices
	const amountOff = price.comparePrice - price.price

	const displayPricing: PricingInfoProps = {
		price: '',
		comparePrice: '',
		discountText: '',
	}

	displayPricing.discountText = PriceManager.formatPriceToCurrencyNoDecimal(amountOff / 100, currency) + ' off'

	if (paymentOption === 'affirm') {
		displayPricing.price = selectedPodVariantFinancing.list + '/mo*'
		displayPricing.comparePrice = selectedPodVariantFinancing.compare + '/mo'
	} else {
		displayPricing.price = price.priceString
		displayPricing.comparePrice = price.comparePriceString
	}

	return {
		description: null,
		smallDescription: getModelDescription(paymentOption, selectedPodVariantFinancing.list + '/mo', price.price, currency),
		type: 'standard-cards',
		selectorKey: 'pod-model',
		title: 'Model',
		displayPricing: displayPricing,
		modalButtons: [
			{
				modalKey: 'model-modal',
				text: 'Need help choosing a model?',
			},
		],
		data: {
			selectionKey: 'pod-model',
			options: getModelOptions(paymentOption),
		},
	}
}

export const getPodSizeProps = (data: PropData): StandardCardProps | undefined => {
	const { podData, selectedSelections } = data
	const productVariants = Object.values(podData.variants)
	if (!productVariants) return undefined
	if (!productVariants.length) return undefined

	const theBaseModel = selectedSelections['the-base'] as 'standard' | 'none'

	const Pod4UltraVariants = Object.entries(PodCoverProducts.Pod4Ultra.variants)
		.filter((entry) => !!entry[1].id)
		.map((entry) => entry[0]) as VariantKey[]

	const options = productVariants.map((variant): CardSelectionItemProps => {
		return {
			key: variant.key,
			type: 'standard',
			data: {
				title: variant.name,
				description: getVariantDimensions(variant.key as VariantKey),
				disabled: theBaseModel === 'standard' && !Pod4UltraVariants.includes(variant.key as VariantKey),
			},
		}
	})

	return {
		title: 'Pod Size',
		description: 'What size mattress will you be placing the Pod on?',

		selectorKey: 'size',

		type: 'standard-cards',
		data: {
			selectionKey: 'size',
			options,
		},

		modalButtons: [
			{
				modalKey: 'size-modal',
				text: 'Need help choosing a size?',
			},
		],
	}
}

export const toPriceString = (unitAmount: number, currency: Currency) => {
	return PriceManager.formatPriceToCurrencyNoDecimal(unitAmount / 100, currency)
}

export const getPricingData = (variant: ProductVariant, currency: Currency, discountText?: string): PricingData => {
	let discount = ''
	if (variant.prices.comparePrice && variant.prices.price < variant.prices.comparePrice) {
		const diff = variant.prices.comparePrice - variant.prices.price
		discount = toPriceString(diff, currency) + ' off'
	}
	return {
		price: toPriceString(variant.prices.price, currency),
		strikePrice: toPriceString(variant.prices.comparePrice, currency),
		discountText: discountText ? discountText : discount,
	}
}

export const getLegKitData = (data: PropData): PricingData | undefined => {
	const product = getLegKitProduct(data.priceStoreData)
	return getPricingData(product.variants['standard'], data.currency)
}

export const getLegKitUpsellData = (priceStoreData: PriceStoreData): ShopSelection => {
	const product = addProductPrices(Pod4Accessories.Pod4UltraLegKit, priceStoreData)
	const upsellData: ShopSelection = {
		id: 'leg-kit',
		title: 'Optional leg kit',
		subtitle:
			"The Base doesn't replace your existing bed frame, it fits over it. However, if you'd like to replace your bed frame with the Base, add the 12 piece leg kit to have it stand on its own.",
		images: [
			{
				type: 'image',
				data: {
					src: 'https://eightsleep.imgix.net/pod4_ultra_legkit_2024_2.png',
					alt: 'Leg Ket display',
				},
			},
		],
		modalButtons: [
			{
				modalKey: 'legkit-modal',
				text: 'Do I need a leg kit?',
			},
		],
		// displayPricing: this.getDisplayPricing(product.variants['standard']),
		options: [
			{
				id: 'none',
				type: 'standard',
				data: {
					title: 'No leg kit',
					description: 'Keep your current bed frame',
				},
			},
			{
				id: 'adjustable',
				type: 'standard',
				data: {
					product: product,
					title: 'Add leg kit',
					subtitle: getPricingData(product.variants['standard'], priceStoreData.currency)?.price,
					description: 'Replace your current bed frame with the Base',
				},
			},
		],
	}

	// @deprecated
	// TODO: This is basically to support an old version of the shop. Once we remove the old shop, we can remove this.
	upsellData.options = upsellData.options.map((option) => ({
		...option,
		key: option.id,
	}))

	return upsellData
}

export const getLegKitProps = (data: PropData): StandardCardProps | undefined => {
	const legKitData = getLegKitData(data)
	if (!legKitData) return undefined

	return {
		type: 'standard-cards',
		selectorKey: 'leg-kit',
		title: 'Optional leg kit',
		description:
			"The Base doesn't replace your existing bed frame, it fits over it. However, if you'd like to replace your bed frame with the Base, add the 12 piece leg kit to have it stand on its own.",
		modalButtons: [
			{
				modalKey: 'legkit-modal',
				text: 'Do I need a leg kit?',
			},
		],
		data: {
			selectionKey: 'leg-kit',
			options: [
				{
					key: 'none',
					type: 'standard',
					data: {
						title: 'No leg kit',
						description: 'Keep your current bed frame',
					},
				},
				{
					key: 'adjustable',
					type: 'standard',
					data: {
						title: 'Add leg kit',
						subtitle: legKitData?.price,
						description: 'Replace your current bed frame with the Base',
					},
				},
			],
		},
	}
}

const getCurrentPlanData = (data: PropData, currency: Currency): PlanData => {
	const currentPlan = determineSubscriptionType(data.subscriptionInformation)

	const currentBenefits = getSubscriptionFeatures(currentPlan)
	const yearlyCost = getSubscriptionYearlyCost(currentPlan)[currency]
	const free = yearlyCost === 0

	const showStandardPlan = currentPlan === SubscriptionType.OPTIONAL_ERA_CHURNED || currentPlan === SubscriptionType.NO_SUBSCRIPTION

	const currentPriceMonthly = free ? `${PriceManager.formatPriceToCurrencyNoDecimal(yearlyCost / 12, currency)}/mo` : 'Included'
	const currentPrice = free ? `${PriceManager.formatPriceToCurrencyNoDecimal(yearlyCost, currency)} billed annually` : ''

	return {
		currentBenefits,
		yearlyCost,
		showStandardPlan,
		currentPrice,
		currentPriceMonthly,
	}
}

const getMembershipPricing = (variant: ProductVariant, data: PropData): MembershipPricing => {
	return {
		yearly: toPriceString(variant.prices.price, data.currency),
		daily: PriceManager.formatPriceToCurrency(variant.prices.price / 365 / 100, data.currency),
		monthly: PriceManager.formatPriceToCurrencyNoDecimal(variant.prices.price / 12 / 100, data.currency),
	}
}

export const getAutoPilotProps = (data: PropData): StandardCardProps | undefined => {
	const { subscriptionInformation } = data
	const standard = addProductPrices(MembershipProducts.AutopilotStandard, data.priceStoreData)
	const enhanced = addProductPrices(MembershipProducts.AutopilotEnhanced, data.priceStoreData)

	const currentPlanData = getCurrentPlanData(data, data.currency)
	const standardPricing = getMembershipPricing(standard.variants['standard'], data)
	const enhancedPricing = getMembershipPricing(enhanced.variants['standard'], data)

	if (getShouldHideAutopilotSection(subscriptionInformation)) {
		return undefined
	}

	return {
		type: 'standard-cards',
		selectorKey: 'auto-pilot',
		title: 'Autopilot',
		description: 'Unlock the full features of the Pod with Autopilot. One plan for you and your partner.',
		modalButtons: [
			{
				modalKey: 'membership-modal',
				text: 'Need help choosing a plan?',
			},
		],
		data: {
			selectionKey: 'auto-pilot',
			options: [getStandardPlan(currentPlanData, standardPricing), getEnhancedPlan(enhancedPricing)],
		},
	}
}

const getEssentialsData = (data: PropData): PricingData | undefined => {
	const product = getEssentialsProduct(data.priceStoreData)
	if (!product) return undefined

	return getPricingData(product.variants[data.selectedVariant], data.currency)
}

export const getEssentialsBundleProps = (data: PropData): StandardCheckboxProps | undefined => {
	const pricingData = getEssentialsData(data)
	if (!pricingData) return undefined

	return {
		type: 'standard-checkboxes',
		selectorKey: 'essentials-bundle',
		title: 'Add an accessory and save more',
		description: 'The Sleep Essentials Bundle adds the latest in Sleep technology to your Pod.',
		data: {
			options: [
				{
					selectionKey: 'essentials-bundle',
					trueValue: 'white',
					falseValue: 'none',
					title: 'Sleep Essentials Bundle',
					description: 'Includes everything you need to pair with your Pod: one Pod Sheet Set (white), one Pod Protector, and two Air Pillows.',
					pricingData,
				},
			],
		},
	}
}

const getBaseProductData = (data: PropData, member = true): PricingData | undefined => {
	const product = getBaseProduct(member, data.priceStoreData)
	const variant = product?.variants[data.selectedVariant]
	return getPricingData(variant, data.currency, 'Get an extra ' + PriceManager.formatPriceToCurrencyNoDecimal((variant.prices.comparePrice - variant.prices.price) / 100, data.currency) + ' off')
}

export const getTheBaseProps = (data: PropData, member = true): StandardCheckboxProps | undefined => {
	const pricingData = getBaseProductData(data, member)
	if (!pricingData) return undefined

	return {
		type: 'standard-checkboxes',
		selectorKey: 'the-base',
		title: 'Complete your experience',
		description: 'Get all the benefits of the Pod with our 3-inch Base that fits between your mattress and bed frame',
		data: {
			options: [
				{
					selectionKey: 'the-base',
					trueValue: 'standard',
					falseValue: 'none',
					title: 'The Base',
					description: 'Fits between your current mattress and bed frame',
					icons: [
						{
							icon: 'CheckLight',
							text: 'Decreases back pain with a custom sleeping position',
						},
						{
							icon: 'CheckLight',
							text: 'Inclines automatically to reduce and stop snoring',
						},
						{
							icon: 'CheckLight',
							text: 'Preset and custom positions for reading and relaxing in bed',
						},
					],
					pricingData,
				},
			],
		},
	}
}

const getMattressData = (data: PropData): { numLayers: number; pricingData: PricingData } | undefined => {
	const mattressProduct = getMattressProduct(data.priceStoreData)
	if (!mattressProduct) return undefined
	const { numLayers, product } = mattressProduct

	const pricingData = getPricingData(product.variants[data.selectedVariant], data.currency)

	return {
		numLayers,
		pricingData,
	}
}

export const getEightMattressProps = (data: PropData): StandardCheckboxProps | undefined => {
	const mattressData = getMattressData(data)
	if (!mattressData) return undefined

	return {
		type: 'standard-checkboxes',
		selectorKey: 'eight-mattress',
		title: 'Add a mattress and save more',
		description: `With our ${mattressData.numLayers}-layer mattress, you can achieve the perfect mix of temperature control and comfort.`,
		modalButtons: [
			{
				modalKey: 'mattress-modal',
				text: 'Why add a mattress?',
			},
		],
		data: {
			options: [getMattressCheckboxProps(mattressData)],
		},
	}
}

export const getAllAccessoriesProps = (data: PropData): StandardCheckboxProps | undefined => {
	const mattressData = getMattressData(data)
	const legKitData = getLegKitData(data)
	const essentialsData = getEssentialsData(data)
	if (!mattressData && !legKitData && !essentialsData) return undefined

	return {
		type: 'standard-checkboxes',
		selectorKey: 'accessories',
		title: 'Accessories',
		description: 'Make the most of your sleep with extras designed for the Pod',
		data: {
			options: getAccessoriesProps(mattressData, legKitData, essentialsData),
		},
	}
}

export const getSummaryProps = (data: PropData, checkout: (sourceId: string) => Promise<void>): SummaryProps => {
	return {
		description: '',
		selectorKey: 'summary',
		title: '',
		type: 'summary',
		data: {
			loading: data.goingToCheckout,
			handleCheckout: () => checkout('selection-summary'),
			price: data.shopTotal.priceString,
			strikePrice: data.shopTotal.comparePriceString,
			savings: data.shopTotal.savingsAmountString,
			financingProvider: data.financingProvider,
			financingAmount: data.totalPriceFinancing,
			orderItems: data.orderItemsString,
			buttonText: 'Checkout',
			shippingText: getShippingText(
				data.podVariant,
				data.currentRegion,
				data.selectedSelections['eight-mattress'] as 'yes_mattress' | 'no_mattress',
				data.selectedSelections['the-base'] as 'standard' | 'none'
			),
			benefits: data.benefits,
		},
	}
}

export const getPodModelFragileProps = (data: PropData): SingleCardProps | undefined => {
	return {
		type: 'single-card',
		selectorKey: 'pod-model-fragile',
		title: `$${FragilePilotPrices[data.selectedVariant]}/mo`,
		description:
			'Enjoy the Pod 4 + Autopilot for one monthly fee. Keep the Pod as long as you’d like, no commitments, cancel anytime, and the application does not impact credit. Includes upgrades every 2 years.**',
		data: {
			selectionKey: 'pod-model',
			title: 'Pod 4',
			description: 'Includes a Cover and a Hub',
			checks: ['Cools and heats', 'Two zones', 'Fits any mattress', 'Control without a phone'],
		},
		modalButtons: [
			{
				modalKey: 'pod-model-fragile-modal',
				text: 'How does monthly rental work?',
			},
		],
	}
}

export const getAutoPilotFragileProps = (): SingleCardProps | undefined => {
	return {
		type: 'single-card',
		selectorKey: 'auto-pilot-fragile',
		title: 'Autopilot Plan',
		description: 'One plan for you and your partner. Autopilot is the intelligence behind the Pod that automatically helps you sleep longer and more deeply.',
		data: {
			title: 'Enhanced',
			description: 'Included in monthly rental',
			checks: ['Automatic temperature adjustments', 'Sleep & health reports', 'Vibration and thermal alarms', 'Snoring detection & mitigation'],
			selectionKey: 'auto-pilot',
		},
	}
}

export const trackViewAccessoryShop = (price: string, size: string, path: string, globalPromoCode: string, currentRegion: RegionShort, pod4UltraPrices: PricingDataFull) => {
	trackAccessoryShopPageVisit(
		{
			code: globalPromoCode,
			amount: pod4UltraPrices.discountNumber,
			region: currentRegion,
			price: price,
			size: size,
		},
		path as any
	)
}

export const getShopPricedProduct = (selectedPodModel: PodModel, priceStoreData: PriceStoreData) => {
	const podProductCore = getPodProductCore(selectedPodModel)
	return addProductPrices(podProductCore, priceStoreData)
}
