import { useStore } from 'stores/StoreProvider'
import { getDiscount, promoVanityPreventsMattress } from '.'

/**
 * @deprecated Use only for debugging
 */
export const usePromoStoreDump = () => {
	const { discountCode, promoBarTitle, promoBarLink, showTimer } = useStore((state) => state.promo)

	return {
		discountCode,
		promoBarTitle,
		promoBarLink,
		showTimer,
	}
}

export const useDiscount = () => {
	const { discountCode } = useStore((state) => state.promo)
	return getDiscount(discountCode)
}

export const useVanityPreventsMattress = () => {
	const { discountCode } = useStore((state) => state.promo)
	return promoVanityPreventsMattress(discountCode)
}
