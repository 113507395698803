'use client'

import { FC, ReactNode, useEffect, useState } from 'react'

interface ClientOnlyProps {
	children: ReactNode
}
/**
 * Wrapper to prevent rehydration bugs. Using this wrapper will prevent the component from being statically built.
 */
export const ClientOnly: FC<ClientOnlyProps> = ({ children }) => {
	const [hasMounted, setHasMounted] = useState(false)

	useEffect(() => {
		setHasMounted(true)
	}, [])

	if (!hasMounted) {
		return null
	}

	return <>{children}</>
}
