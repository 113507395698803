import { PromoBar, PromoMessage, SiteSettings } from 'config/types'
import { discountCodes } from 'promos'
import { RegionShort } from 'utils/internationalization'

export const promoBlackList = ['/sleep-fitness-challenge', '/rewards', '/miami', '/miami/']

const mattressBlacklist = ['tim']

export const getDiscount = (code: string) => {
	return discountCodes[code]
}

export const promoFindExactMatch = (it: string, currentPage: string, currentPageWithSlash: string) => {
	return it === currentPage || it === currentPageWithSlash || it + '/' === currentPageWithSlash
}

export const promoFindSubStringMatch = (it: string, currentPage: string, currentPageWithSlash: string) => {
	return currentPage.endsWith(it) || currentPageWithSlash.endsWith(it)
}

export const promoFindPageKey = (promoMessages: PromoBar, currentPage: string, currentPageWithSlash: string) => {
	const promoMessageKeys = Object.keys(promoMessages)
	return promoMessageKeys.find((it) => promoFindExactMatch(it, currentPage, currentPageWithSlash)) || promoMessageKeys.find((it) => promoFindSubStringMatch(it, currentPage, currentPageWithSlash))
}

export const getPromoDetails = (promoData: PromoMessage | undefined) => {
	const promoDetails = { message: '', link: '', showTimer: null }
	if (promoData) {
		promoDetails.message = promoData.message
		promoDetails.link = promoData.link?.to || ''
		promoDetails.showTimer = promoData.showTimer
	}
	return promoDetails
}

export const promoNormalizePath = (currentPage: string) => {
	const path = currentPage.split('?')[0]
	const trailingSlashRemoved = path.endsWith('/') ? path.slice(0, -1) : path
	return { normalizedPath: trailingSlashRemoved, pathWithSlash: trailingSlashRemoved + '/' }
}

export const promoVanityPreventsMattress = (discountCode: string) => {
	return (
		mattressBlacklist.includes(discountCode) ||
		mattressBlacklist.includes(discountCode + '50') ||
		mattressBlacklist.includes(discountCode.toUpperCase()) ||
		mattressBlacklist.includes(discountCode.toUpperCase() + '50')
	)
}

export const getPromoBar = (currentPage: string, settings: SiteSettings, currentRegion: RegionShort) => {
	if (!settings) {
		return null
	}

	const promoMessages = settings.promoBar
	if (promoMessages) {
		return getPromoBarFromCurrentPath(promoMessages, currentPage, currentRegion)
	}

	return null
}

export const getPromoBarFromCurrentPath = (promoMessages: PromoBar, currentPage: string, currentRegion: RegionShort) => {
	// We return null if no current page is provided, or if the current page
	// is found in the promotional blacklist
	if (!currentPage || promoBlackList.indexOf(currentPage) > -1) {
		return null
	}

	const fullCurrentPage = currentRegion !== 'us' && currentRegion !== '' ? `/${currentRegion}${currentPage}` : currentPage
	// We normalize the current page's path, removing any trailing slashes
	const { normalizedPath: normalizedPath1, pathWithSlash: pathWithSlash1 } = promoNormalizePath(fullCurrentPage)
	const { normalizedPath: normalizedPath2, pathWithSlash: pathWithSlash2 } = promoNormalizePath(currentPage)

	// We look for an exact match with the normalized path within the promotional messages
	// Then, if we cannot find one, we look for a substring match
	const pageKey = promoFindPageKey(promoMessages, normalizedPath1, pathWithSlash1) || promoFindPageKey(promoMessages, normalizedPath2, pathWithSlash2)

	// If a promotional message exists for this specific page, it is assigned to pagePromo
	// otherwise, this variable is set to null
	const pagePromo = (normalizedPath1.length > 0 || normalizedPath2.length > 0) && pageKey ? promoMessages[pageKey] : null

	const sitePromo = promoMessages['*']

	const { message: siteMessage, link: siteLink, showTimer: siteTimer } = getPromoDetails(sitePromo)
	const { message: pageMessage, link: pageLink, showTimer: pageTimer } = getPromoDetails(pagePromo)

	// if page promotional details exist, they override the site promotional details
	const promoMessage = pageMessage || siteMessage
	const promoLink = pageMessage ? pageLink : siteLink
	const showTimer = pageTimer != null ? pageTimer : siteTimer

	// TODO: consider refactoring to remove the ambiguity raised by !=
	// page/siteTimer is typically undefined, and in Javascript, `undefined == null`,
	// however, `undefined !== null`.

	// Updating the class fields

	return {
		promoMessage,
		promoLink,
		showTimer,
	}
}
