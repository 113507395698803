import default_settings from 'config/default'
import { getActiveSitePromo } from 'config/site_promos'
import { SiteSettings, VanityData } from 'config/types'
import { EMAIL_PROMO_QUERY, getUrlPromoSettings, SMS_PROMO_QUERY } from 'config/url_promos'
import { createVanitySettings, fillMessagesWithBasePrice, fillMessagesWithCoverPrice, fillMessagesWithDiffPrice, fillMessagesWithPodPrice, mergeVanityIntoSiteSettings } from 'config/utils'
import { getVanityData } from 'config/vanity'
import { PriceManager } from 'prices'
import { Currency } from 'prices/types'
import { BundleProducts, MattressProducts, PodCoverProducts, TheBaseProducts } from 'products/allProducts'
import { discountCodes } from 'promos'
import { RegionShort } from 'utils/internationalization'

export const getURLQueryPromo = () => {
	const email = localStorage.getItem('email')
	const phone = localStorage.getItem('phone')
	if (phone) {
		return SMS_PROMO_QUERY
	} else if (email) {
		return EMAIL_PROMO_QUERY
	}
	return window.location.search
}

export const getMetricRegion = (region: RegionShort) => {
	if (region === 'us') {
		return 'us'
	}
	return 'metric'
}

export const getVanitySetup = (vanity_data?: VanityData, return_settings?: boolean) => {
	const vanity = typeof window !== 'undefined' && !return_settings ? getVanityData() ?? vanity_data : vanity_data

	const isVanity = !!vanity
	return { isVanity, vanity }
}

export const processSettings = (currentRegion: RegionShort, return_settings: boolean, vanity?: VanityData) => {
	const defaultSettings = default_settings
	const promoBarCombined = structuredClone(defaultSettings.promoBar)

	const siteSettings = defaultSettings
	const activeSitePromo = getActiveSitePromo(currentRegion)
	const activeSiteSale = structuredClone(activeSitePromo)

	const vanitySettings = return_settings ? vanity : createVanitySettings(vanity)
	if (vanitySettings?.promoBar) {
		const defaultPromoBarMessage = vanitySettings.promoBar['*'].message
		const promoBarMessage = defaultPromoBarMessage.includes('with code') ? defaultPromoBarMessage : defaultPromoBarMessage + ` with code <strong>${vanitySettings.promoCode.toUpperCase()}</strong>`
		vanitySettings.promoBar['*'].message = promoBarMessage
		vanitySettings.promoBar['/pod-cover'] = structuredClone(vanitySettings.promoBar['*'])
		vanitySettings.promoBar['/product/pod-cover'] = structuredClone(vanitySettings.promoBar['*'])
		vanitySettings.promoBar['/pod-prospecting'] = structuredClone(vanitySettings.promoBar['*'])
		vanitySettings.promoBar['/pod-prospecting-yt'] = structuredClone(vanitySettings.promoBar['*'])
		vanitySettings.promoBar['/notboring-prospecting'] = structuredClone(vanitySettings.promoBar['*'])
	}

	return { siteSettings, promoBarCombined, activeSiteSale, vanitySettings }
}

export const fillMessages = (globalPromoCode: string, siteSettings: SiteSettings, currentRegion: RegionShort, currency: Currency) => {
	let globalPodSale = 0
	let globalCoverSale = 0

	const sleepEssentialsBundleId = BundleProducts.SleepEssentialBundle.variants['queen'].id
	const pod4CoverUltraId = PodCoverProducts.Pod4Ultra.variants['queen'].id
	const mattressId = currentRegion === 'ca' ? MattressProducts.ThreeLayer.variants['caqueen'].id : MattressProducts.FiveLayerDiscounted.variants['queen'].id

	if (globalPromoCode !== '') {
		globalCoverSale = discountCodes[globalPromoCode][pod4CoverUltraId].USD as number
		globalPodSale = (discountCodes[globalPromoCode][sleepEssentialsBundleId].USD as number) ?? 0
	}

	const currentCoverSale = discountCodes[siteSettings.promoCode][pod4CoverUltraId].USD as number
	const currentPodSale = discountCodes[siteSettings.promoCode][sleepEssentialsBundleId].USD as number
	let podDiscount = (discountCodes[siteSettings.promoCode][sleepEssentialsBundleId][currency] ?? 0) + (discountCodes[siteSettings.promoCode][mattressId][currency] ?? 0)
	let coverDiscount = discountCodes[siteSettings.promoCode][pod4CoverUltraId][currency]
	const baseDiscount = discountCodes[siteSettings.promoCode][TheBaseProducts.TheBase.variants['queen'].id]

	if (globalPodSale + globalCoverSale > currentPodSale + currentCoverSale) {
		if (globalPodSale > currentPodSale) {
			podDiscount = discountCodes[globalPromoCode][sleepEssentialsBundleId][currency]
		}

		if (globalCoverSale > currentCoverSale) {
			coverDiscount = discountCodes[globalPromoCode][pod4CoverUltraId][currency]
		}
	}

	if (currency !== 'USD' && currency !== 'CAD') {
		podDiscount = coverDiscount
	} else {
		podDiscount += coverDiscount
	}
	if (podDiscount) {
		fillMessagesWithPodPrice(siteSettings, PriceManager.formatPriceToCurrencyNoDecimal(podDiscount, currency))
	}
	if (baseDiscount) {
		fillMessagesWithBasePrice(siteSettings, PriceManager.formatPriceToCurrencyNoDecimal(baseDiscount[currency], currency))
	}
	if (coverDiscount) {
		fillMessagesWithCoverPrice(siteSettings, PriceManager.formatPriceToCurrencyNoDecimal(coverDiscount, currency))

		if ((currentCoverSale as number) > globalCoverSale) {
			fillMessagesWithDiffPrice(siteSettings, PriceManager.formatPriceToCurrencyNoDecimal((currentCoverSale as number) - globalCoverSale, currency))
		}
	}
}

export const processSiteSettings = (currentRegion: RegionShort, return_settings: boolean, referral_session_code: string | null, vanity_data?: VanityData) => {
	const { isVanity, vanity } = getVanitySetup(vanity_data, return_settings)
	const result = processSettings(currentRegion, return_settings, vanity)
	const activeSiteSale = result.activeSiteSale
	let promoBarCombined = result.promoBarCombined
	let siteSettings = result.siteSettings
	const vanitySettings = result.vanitySettings

	const urlSettings = typeof window !== 'undefined' && !isVanity ? getUrlPromoSettings(referral_session_code ? '?referral=code' : getURLQueryPromo()) : null
	const globalPromoCode = activeSiteSale ? activeSiteSale.promoCode : null
	if (activeSiteSale) {
		// merge promo bar obj together
		promoBarCombined = structuredClone({ ...promoBarCombined, ...activeSiteSale.promoBar })
		siteSettings = { ...default_settings, ...activeSiteSale, promoCode: globalPromoCode ?? siteSettings.promoCode }
		siteSettings.promoBar = promoBarCombined
	}

	if (vanitySettings && !urlSettings) {
		promoBarCombined = structuredClone({ ...promoBarCombined, ...vanitySettings.promoBar })
		siteSettings = mergeVanityIntoSiteSettings(siteSettings, vanitySettings)
		siteSettings.promoBar = promoBarCombined
	}

	if (urlSettings) {
		const currentPriority = siteSettings.promoBar?.priority ?? 0
		const urlSettingsPriority = urlSettings.promoBar?.priority ?? 0
		promoBarCombined = structuredClone(urlSettingsPriority > currentPriority ? urlSettings.promoBar : { ...promoBarCombined, ...urlSettings.promoBar })
		siteSettings = { ...siteSettings, ...urlSettings, promoCode: urlSettings.promoCode ?? siteSettings.promoCode }
		siteSettings.promoBar = promoBarCombined
		siteSettings.promoCode = urlSettings.promoCode ?? siteSettings.promoCode
	}

	if (currentRegion !== 'us') {
		Object.keys(siteSettings.promoBar ?? {}).forEach((k) => {
			const key = siteSettings.promoBar[k]
			if (key && key.message) {
				key.message = key.message.replace('Bundle and save up to', 'Save up to')
			}
		})
	}

	if (siteSettings.saleName) {
		Object.keys(siteSettings.promoBar).forEach((key) => {
			if (key === 'priority') {
				return
			}

			if (siteSettings.promoBar[key] != null && siteSettings.promoBar[key].message) {
				siteSettings.promoBar[key].message = siteSettings.promoBar[key]?.message?.replace('{activeSaleName}', siteSettings.saleName)
			}
		})
	}

	const currency = PriceManager.getRegionCurrency(currentRegion)
	if (siteSettings.promoCode && discountCodes[siteSettings.promoCode] && currency) {
		fillMessages(globalPromoCode, siteSettings, currentRegion, currency)
	}

	if (referral_session_code && urlSettings) {
		siteSettings.promoBar = structuredClone({ ...urlSettings.promoBar })
		Object.keys(siteSettings.promoBar ?? {}).forEach((k) => {
			if (k === 'priority') {
				return
			}
			const key = siteSettings.promoBar[k]
			if (key) {
				siteSettings.promoBar[k]!.message = key.message.replace('XYZ', referral_session_code)
			}
		})
	}

	return { siteSettings, activeSiteSale, globalPromoCode, isVanity, vanity }
}
