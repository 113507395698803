import { SubscriptionResponse } from 'components/_utils/subscriptionUtils'
import { datadogReportError } from 'events/datadog'
import { safeError } from 'events/error.utils'
import { useEffect, useState } from 'react'

const INTERVAL_LIMIT = 40
export const useGetMemberDataFromAppInjection = (): { subscriptionInformation: SubscriptionResponse | null; limitReached: boolean } => {
	const [subscriptionInformation, setSubscriptionInformation] = useState<SubscriptionResponse | null>(null)
	const [limitReached, setLimitReached] = useState(false)

	useEffect(() => {
		const queryAuthToken = getTokenFromBrowser()

		const processAuthToken = (authToken: string): void => {
			getUserSubscriptionInfo(authToken)
				.then((subscriptionInfo) => {
					sessionStorage?.setItem('subscriptionInformation', JSON.stringify(subscriptionInfo))
					setSubscriptionInformation(subscriptionInfo)
					setLimitReached(true)
				})
				.catch(() => {
					setLimitReached(true)
				})
		}

		if (queryAuthToken !== null) {
			processAuthToken(queryAuthToken)
			return
		}

		let intervalCounter = 0
		const interval = setInterval(() => {
			const authToken = getTokenFromBrowser()
			if (++intervalCounter > INTERVAL_LIMIT) {
				clearInterval(interval)
				setLimitReached(true)
				return
			}

			if (authToken === null) return

			// If we have an auth token, we don't need to refresh anymore.
			// We just want to wait until we get a response back from the auth endpoint.
			// If the user is valid, we'll get it, otherwise we'll redirect to login.
			intervalCounter = 0
			clearInterval(interval)
			processAuthToken(authToken)
		}, 100)

		return () => clearInterval(interval)
	}, [])

	useEffect(() => {
		const sessionStorageSubscriptionInformation = sessionStorage?.getItem('subscriptionInformation')
		if (sessionStorageSubscriptionInformation !== null) {
			try {
				setSubscriptionInformation(JSON.parse(sessionStorageSubscriptionInformation) as SubscriptionResponse)
				setLimitReached(true)
			} catch (error) {
				datadogReportError(safeError(error), 'Pod4 stories to get user data')
			}
		}
	}, [])

	return {
		subscriptionInformation,
		limitReached,
	}
}

const getUserSubscriptionInfo = async (authToken: string): Promise<SubscriptionResponse | null> => {
	const apiFetch = await fetch(`/api/membership-details/auth?jwt=${authToken}`, {
		method: 'GET',
	}).catch((error: unknown) => {
		datadogReportError(safeError(error), 'Pod4 stories to get user data')
	})

	if (!apiFetch) {
		return null
	}

	return (await apiFetch.json()) as SubscriptionResponse | null
}

/**
 * @returns The auth token from the browser.
 */
export const getTokenFromBrowser = (): string | null => {
	if (typeof window === 'undefined') {
		return null
	}

	const searchParams = new URLSearchParams(window.location.search)
	const authToken = searchParams.get('authToken')
	const token = searchParams.get('token')
	if (token !== null) {
		return token
	}

	if (authToken !== null) {
		return authToken
	}

	const sessionAuthToken = sessionStorage.getItem('authToken')
	if (sessionAuthToken !== null) {
		return sessionAuthToken
	}

	const sessionToken = sessionStorage.getItem('token')
	if (sessionToken !== null) {
		return sessionToken
	}

	const localStorageAuthToken = localStorage.getItem('authToken')
	if (localStorageAuthToken !== null) {
		return localStorageAuthToken
	}

	const localStorageToken = localStorage.getItem('token')
	if (localStorageToken !== null) {
		return localStorageToken
	}

	return null
}
