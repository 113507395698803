import { useStore } from 'stores/StoreProvider'
import { EU_REGIONS, regionIsMetric } from 'utils/internationalization'

/**
 * @deprecated Use only for debugging
 */
export const useSettingsStoreDump = () => {
	const currentRegion = useStore((state) => state.settings.currentRegion)
	const currentCountry = useStore((state) => state.settings.currentCountry)
	const currentPage = useStore((state) => state.settings.currentPage)
	const currentQuery = useStore((state) => state.settings.currentQuery)
	const settings = useStore((state) => state.settings.settings)
	const windowHistoryLength = useStore((state) => state.settings.windowHistoryLength)
	const windowLocationHref = useStore((state) => state.settings.windowLocationHref)
	const isPodProductPageFirstLoad = useStore((state) => state.settings.isPodProductPageFirstLoad)
	const siteSaleIsActive = useStore((state) => state.settings.siteSaleIsActive)
	const globalPromoCode = useStore((state) => state.settings.globalPromoCode)
	const navBarTransparency = useStore((state) => state.settings.navBarTransparency)
	const isVanity = useStore((state) => state.settings.isVanity)
	const coverStickyHeight = useStore((state) => state.settings.coverStickyHeight)
	const localizeReady = useStore((state) => state.settings.localizeReady)

	return {
		currentRegion,
		currentCountry,
		currentPage,
		currentQuery,
		settings,
		windowHistoryLength,
		windowLocationHref,
		isPodProductPageFirstLoad,
		siteSaleIsActive,
		globalPromoCode,
		navBarTransparency,
		isVanity,
		coverStickyHeight,
		localizeReady,
	}
}

export const useCurrentRegion = () => {
	const currentRegion = useStore((state) => state.settings.currentRegion)
	return currentRegion
}

export const useIsUS = () => {
	const currentRegion = useStore((state) => state.settings.currentRegion)
	return currentRegion === ''
}

export const useIsCanada = () => {
	const currentRegion = useStore((state) => state.settings.currentRegion)
	return currentRegion === 'ca'
}

export const useIsUK = () => {
	const currentRegion = useStore((state) => state.settings.currentRegion)
	return currentRegion === 'uk'
}

/**
 * @deprecated Currently includes regions like SE & DK, should be removed, use isEU instead
 */
export const useIsEULegacy = () => {
	const currentCountry = useStore((state) => state.settings.currentCountry)
	const currentRegion = useStore((state) => state.settings.currentRegion)
	return currentRegion === 'eu' || EU_REGIONS.includes(currentCountry)
}

export const useIsEU = () => {
	const currentRegion = useStore((state) => state.settings.currentRegion)
	return currentRegion === 'eu'
}

export const useIsAU = () => {
	const currentRegion = useStore((state) => state.settings.currentRegion)
	return currentRegion === 'au'
}

export const useIsSE = () => {
	const currentRegion = useStore((state) => state.settings.currentRegion)
	return currentRegion === 'se'
}

export const useIsDK = () => {
	const currentRegion = useStore((state) => state.settings.currentRegion)
	return currentRegion === 'dk'
}

export const useIsAE = () => {
	const currentRegion = useStore((state) => state.settings.currentRegion)
	return currentRegion === 'ae'
}

export const useIsCH = () => {
	const currentRegion = useStore((state) => state.settings.currentRegion)
	return currentRegion === 'ch'
}

export const useIsMX = () => {
	const currentRegion = useStore((state) => state.settings.currentRegion)
	return currentRegion === 'mx'
}

export const useIsUAE = () => {
	const currentRegion = useStore((state) => state.settings.currentRegion)
	return currentRegion === 'ae'
}

export const useRegion = () => {
	const isCanada = useIsCanada()
	const isUK = useIsUK()
	const isEU = useIsEU()
	const isAU = useIsAU()
	const isSE = useIsSE()
	const isDK = useIsDK()
	const isAE = useIsAE()
	const isCH = useIsCH()
	const isMX = useIsMX()
	const isUAE = useIsUAE()

	if (isCanada) {
		return 'CA'
	}
	if (isUK) {
		return 'UK'
	}
	if (isEU) {
		return 'EU'
	}
	if (isAU) {
		return 'AU'
	}
	if (isSE) {
		return 'SE'
	}
	if (isDK) {
		return 'DK'
	}
	if (isUAE) {
		return 'AE'
	}
	if (isCH) {
		return 'CH'
	}
	if (isMX) {
		return 'MX'
	}
	return 'USA'
}

export const useDefaultProduct = () => {
	const isEU = useIsEU()
	const isUK = useIsUK()

	if (isEU) {
		return 'EU Large Double'
	}
	if (isUK) {
		return 'UK King'
	}
	return 'Queen'
}

export const useMetricRegion = () => {
	const currentRegion = useStore((state) => state.settings.currentRegion)
	return regionIsMetric(currentRegion)
}

export const useSaleEndDate = () => {
	const settings = useStore((state) => state.settings.settings)
	const discountCode = useStore((state) => state.promo.discountCode)

	let endDate = settings.endDate
	if (!endDate || discountCode === 'members') {
		endDate = ''
	}
	return endDate
}

export const useLocalizeLanguage = () => {
	const currentRegion = useStore((state) => state.settings.currentRegion)
	return currentRegion === 'eu' && typeof window !== 'undefined' ? window.Localize?.getLanguage() ?? 'en' : 'en'
}
