import { useStore } from 'stores/StoreProvider'

/**
 * @deprecated Use only for debugging
 */
export const useNavStoreDump = () => {
	const { stickyAtcVisible, route, collapsed } = useStore((state) => state.nav)

	return {
		stickyAtcVisible,
		route,
		collapsed,
	}
}
