import { ICheckout } from 'app/api/estimate_invoice/route'
import { Currency } from 'prices/types'
import { ProductCore } from 'products/types'
import { getProductByVariantId, getProductVariantByVariantId } from 'products/utils'
import { InvoiceLineItem, StripeCheckout } from './types'

export const INVOICE_EXPAND_FIELDS: string[] = ['payment_intent', 'lines.data.discounts', 'lines.data.price', 'lines.data.price.product', 'customer']

export const convertShopifyToStripe = (input: ICheckout): StripeCheckout => {
	const lines = input.lines.map((line) => {
		const attributes: Record<string, string> = line.attributes.reduce((acc, it) => ({ ...acc, [it.key]: it.value }), {})
		const product = getProductByVariantId(line.variantId)
		if (!product) return null

		// TODO, we probably shouldn't be casting product to ProductCore here, but this is just for an incremental change
		const variant = getProductVariantByVariantId(product as ProductCore, line.variantId)
		if (!variant) return null

		const price = variant.id as string
		return {
			price,
			quantity: line.quantity,
			metadata: attributes,
		}
	})

	return {
		// email: input.email,
		currency: input.currency?.toLowerCase() as Currency,
		coupons: input.coupons?.map((it) => it.trim().toUpperCase()).filter((it) => !!it && it.length > 1) ?? [],
		lines: lines.filter(Boolean) as InvoiceLineItem[],
	}
}
