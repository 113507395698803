import { CartLineItem } from 'cart'
import { datadogReportError, datadogReportEvent } from 'events/datadog'
import { safeError } from 'events/error.utils'

// Define types for Twitter tracking
type TwitterEventName = 'tw-nw40a-ocwxn' | 'tw-nw40a-o2a9m' | 'tw-nw40a-o1zr9'
type TwitterEventParams = Record<string, unknown>

declare global {
	interface Window {
		twq?: (command: 'event', eventName: TwitterEventName, parameters?: TwitterEventParams) => void
	}
}

const trackTwq = (eventName: TwitterEventName, parameters: TwitterEventParams = {}): boolean => {
	if (typeof window !== 'undefined' && window.twq) {
		window.twq('event', eventName, parameters)
		return true
	}
	return false
}

export const twPageView = (): void => {
	try {
		if (trackTwq('tw-nw40a-ocwxn', {})) {
			datadogReportEvent('success', 'twPageView', {})
		}
	} catch (e: unknown) {
		datadogReportError(safeError(e), 'Twitter Page View')
	}
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const twAddToCart = (item: CartLineItem): void => {
	try {
		// Uncomment and use this version if you want to track detailed cart data
		// const params = {
		// 	value: item.price,
		// 	currency: item.prices.currency,
		// 	contents: [
		// 		{
		// 			content_id: item.variantId,
		// 			num_items: item.quantity,
		// 			content_name: item.productTitle,
		// 		},
		// 	],
		// }
		if (trackTwq('tw-nw40a-o2a9m', {})) {
			datadogReportEvent('success', 'twAddToCart', {})
		}
	} catch (e: unknown) {
		datadogReportError(safeError(e), 'Twitter Add To Cart')
	}
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const twInitiateCheckout = (items: CartLineItem[]): void => {
	try {
		// Uncomment and use this version if you want to track detailed checkout data
		// const totalPrice = items.reduce((total, lineItem) => total + lineItem.price, 0)
		// const params = {
		// 	value: totalPrice,
		// 	currency: items[0].prices.currency,
		// 	contents: items.map((item) => ({
		// 		content_id: item.variantId,
		// 		num_items: item.quantity,
		// 		content_name: item.productTitle,
		// 	})),
		// }
		if (trackTwq('tw-nw40a-o1zr9', {})) {
			datadogReportEvent('success', 'twInitiateCheckout', {})
		}
	} catch (e: unknown) {
		datadogReportError(safeError(e), 'Twitter Initiate Checkout')
	}
}
