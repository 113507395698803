import { POD_LANDING_URL_STRIPPED } from 'constants/index'

export interface Product {
	name: string
	quantity: number
	price: number
	sku: number
	totalAmount: number
}

// home page, pod landing + subnav, and sale
export const browsingPages: string[] = [
	'/',
	'/' + POD_LANDING_URL_STRIPPED,
	'/how-it-works',
	'/reviews',
	'sleep-science',
	'/financing',
	// '/eight-plus-membership',
	'/sale',
	'/podlanding',
]

export enum CaptureLocation {
	CHECKOUT = 'checkout',
	SHOPIFY_CHECKOUT = 'Shopify checkout contact',
	FOOTER = 'footer',
	HOSTS = 'b2b-subscribers',
	MODAL = 'modal',
	OTHER = 'other',
	PODPROFULL = 'podProFull',
	PODPROCALIKING = 'podProCaliKing',
	VIPACCESS = 'VIPaccess',
	UKSUPERKING = 'podProCoverUKSuperKing',
	COACHING = 'coaching',
	MAGIC = 'magic',
	REFERRAL = 'referral',
	DRAWER = 'drawer',
	LEVELS = 'levels',
	T1 = 'T1',
}

// add an event to an aray of events in localStorage
export const addEventToLocalStorage = (key: string, event: unknown): void => {
	let eventArr = localStorage.getItem(key)
	const eventAsString = JSON.stringify(event)

	if (eventArr === null) {
		eventArr = '[' + eventAsString + ']'
	} else {
		// update event arr
		// Notably, we do not use JSON.parse here because it's expensive and we want to minimize blocking
		eventArr = eventArr.slice(0, -1) + ',' + eventAsString + ']'
	}

	// update localStorage
	localStorage.setItem(key, eventArr)
}

export const getAllEventsFromLocalStorage = (key: string): unknown[][] | null => {
	const events: string | null = localStorage.getItem(key)
	if (events === null) {
		return null
	}
	return JSON.parse(events) as unknown[][]
}

export const removeHiddenCharsFromString = (str: string): string => {
	// replace non ascii
	return str.replace(/[\u{0080}-\u{FFFF}]/gu, '')
}
