import { lens } from '@dhmk/zustand-lens'
import { Cart, CartManager, LineItem } from 'cart'
import { MainStoreFront } from 'cart/storeFront'
import { amAppCheckoutError, trackCartMembershipUpdate } from 'events/amplitude'
import { trackCartClose, trackInitiateCheckout, trackRemoveFromCart, trackUpdateCartItem } from 'events/index'
import { klaviyoTrackLatestCart } from 'events/klaviyo'
import { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime'
import { PriceManager } from 'prices'
import { ProductManager } from 'products'
import { MembershipProducts, SupplementProducts } from 'products/allProducts'
import { ProductShopDataType } from 'products/types'
import { isMembershipVariantId, isSleepElixirSubscriptionVariantId, mapUrlToShop } from 'products/utils'
import { getCountryCodeFromCurrency, getCurrency, getVariantPrices } from 'stores/prices/prices.utils'
import { getShopPageProducts } from 'stores/product/product.utils'
import { loadExternalScript, setPageScroll } from 'utils'
import { RegionShort } from 'utils/internationalization'
import type { RootState } from '../root.types'
import { CartSlice } from './cart.types'
import { createCheckoutAndRedirect, getCartHasMembership, getCartItemVariants, getCartSleepElixirLineItems, getFullCartItems } from './cart.utils'

export const createCartSlice = () => {
	const initStoreFront = new MainStoreFront()
	const initCartManager = new CartManager(initStoreFront)

	const slice = lens<CartSlice, RootState>((set, get, api) => ({
		cart: CartManager.ZeroCart,
		isCartOpen: false,
		showInterstitial: false,
		updatingCart: false,

		cartManager: initCartManager,

		eventHandler: () => {
			set({ showInterstitial: false, updatingCart: false }, undefined, 'cart/eventHandler')
		},

		setCart: (cart: Cart) => {
			set({ cart }, undefined, 'cart/setCart')
			get().cartManager.saveCart(cart)
		},

		initCart: () => {
			const { cartManager, setCart } = get()
			set({ updatingCart: true }, undefined, 'cart/initCart')
			const countryCode = getCountryCodeFromCurrency(getCurrency(api.getState().settings.currentRegion))
			const newCart = cartManager.getCart(countryCode)

			setCart(newCart)
			if (!newCart.id && typeof window !== 'undefined') {
				window.localStorage.removeItem('cartId')
			}
			if (newCart.createdAt) {
				const before = new Date(newCart.createdAt)
				const now = new Date()
				const differenceDays = (now.getTime() - before.getTime()) / (1000 * 60 * 60 * 24)
				if (differenceDays > 30) {
					setCart(CartManager.ZeroCart)
				} else {
					get().syncShippingTimelines()
				}
			}

			api.getState().prices.syncPrices()
			set({ updatingCart: false }, undefined, 'cart/initCart')

			const itemVariants = getCartItemVariants(get())
			const coverShop = api.getState().product.coverShop
			const currentRegion = api.getState().settings.currentRegion
			const productManager = api.getState().product.productManager
			if (checkForCurrentShopPageItemInCart(itemVariants, { coverShop, currentRegion, productManager })) {
				get().setCartOpen()
			}
		},

		syncShippingTimelines: () => {
			const { cart, cartManager } = get()
			const countryCode = getCountryCodeFromCurrency(getCurrency(api.getState().settings.currentRegion))
			cartManager.syncShippingTimelines(cart, countryCode)
		},

		toggleCartOpen: () => {
			if (get().isCartOpen) {
				trackCartClose()
				get().closeCart()
			} else {
				get().setCartOpen()
			}
		},

		closeCart: () => {
			set({ isCartOpen: false }, undefined, 'cart/closeCart')
			setPageScroll(true)
		},

		setCartOpen: () => {
			set({ isCartOpen: true }, undefined, 'cart/setCartOpen')
			setPageScroll(false)
		},

		setShowInterstitial: (show: boolean) => {
			set({ showInterstitial: show }, undefined, 'cart/setShowInterstitial')
		},

		addItems: async (lineItems: LineItem[]) => {
			loadExternalScript('https://tag.simpli.fi/sifitag/1d04a5e0-b517-013a-4abf-0cc47a1f72a4', 'ott_atc')

			const { cart, updatingCart, cartManager, syncShippingTimelines } = get()
			const currentRegion = api.getState().settings.currentRegion

			if (cart.id && updatingCart) {
				return
			}
			set({ updatingCart: true }, undefined, 'cart/addItems')

			let newCart = null

			// Ensure that we never add two memberships to the cart
			let filteredLineItems = lineItems
			if (getCartHasMembership(get())) {
				filteredLineItems = lineItems.filter((item) => {
					return Object.values(MembershipProducts).every((product) => product.variants.standard.id !== item.variantId)
				})

				// if there's already a membership in the cart and a sleep elixir subscription is being added, swap it for the one-time special sleep elixir variant
				if (filteredLineItems.some((item) => isSleepElixirSubscriptionVariantId(item.variantId))) {
					filteredLineItems = filteredLineItems.map((it) => {
						if (isSleepElixirSubscriptionVariantId(it.variantId)) {
							return { ...it, variantId: SupplementProducts.SleepElixir.variants.onetime.id }
						}
						return it
					})
				}
			}

			const sleepElixirLineItems = getCartSleepElixirLineItems(get())
			const countryCode = getCountryCodeFromCurrency(getCurrency(api.getState().settings.currentRegion))
			if (sleepElixirLineItems?.length > 0 && filteredLineItems.some((item) => isMembershipVariantId(item.variantId))) {
				// if there's already a sleep elixir subscription in the cart, swap it for the one-time special sleep elixir variant
				const sleepElixirSwapItems = sleepElixirLineItems.map((it) => ({ ...it, variantId: SupplementProducts.SleepElixir.variants.onetime.id }))

				newCart = cartManager.removeLineItemsFromCart(cart, sleepElixirLineItems, countryCode)
				newCart = await cartManager.addLineItemsToCart(newCart, sleepElixirSwapItems, countryCode)
			}

			newCart = await cartManager.addLineItemsToCart(cart, filteredLineItems, countryCode)

			set({ cart: newCart }, undefined, 'cart/addItems')
			api.getState().prices.syncPrices()
			set({ updatingCart: false }, undefined, 'cart/addItems')
			klaviyoTrackLatestCart(cartManager.getCartString(newCart))

			syncShippingTimelines()
		},

		removeItems: (lineItems: LineItem[]) => {
			const { updatingCart, cartManager, cart } = get()
			const currentRegion = api.getState().settings.currentRegion

			if (!lineItems.length) {
				return
			}
			if (!updatingCart) {
				set({ updatingCart: true }, undefined, 'cart/removeItems')
				const countryCode = getCountryCodeFromCurrency(getCurrency(api.getState().settings.currentRegion))
				const newCart = cartManager.removeLineItemsFromCart(cart, lineItems, countryCode)

				get().setCart(newCart)
				lineItems
					.filter((it) => it.id)
					.forEach((it) => {
						try {
							trackRemoveFromCart(
								it,
								PriceManager.formatPrices(getVariantPrices(it.variantId, api.getState().prices.allPrices, api.getState().prices.priceManager), PriceManager.getRegionCurrency(currentRegion))
							)
						} catch (error) {
							console.error(error)
						}
					})

				api.getState().prices.syncPrices()
				set({ updatingCart: false }, undefined, 'cart/removeItems')
				klaviyoTrackLatestCart(cartManager.getCartString(newCart))
			}
		},

		updateItems: (lineItems: LineItem[], action: string) => {
			const { updatingCart, cartManager, cart } = get()
			const currentRegion = api.getState().settings.currentRegion
			const countryCode = getCountryCodeFromCurrency(getCurrency(currentRegion))

			if (!updatingCart) {
				set({ updatingCart: true }, undefined, 'cart/updateItems')
				const newCart = cartManager.updateLineItemsInCart(cart, lineItems, countryCode)

				get().setCart(newCart)
				lineItems
					.filter((it) => it.id)
					.forEach((it) => {
						try {
							trackUpdateCartItem(
								it,
								PriceManager.formatPrices(getVariantPrices(it.variantId, api.getState().prices.allPrices, api.getState().prices.priceManager), PriceManager.getRegionCurrency(currentRegion)),
								action
							)
						} catch (error) {
							console.error(error)
						}
					})
				api.getState().prices.syncPrices()
				set({ updatingCart: false }, undefined, 'cart/updateItems')
			}
		},

		swapPlan: (plan: any) => {
			const allPlans = Object.values(MembershipProducts).map((o) => o.variants.standard.id)
			get().removeItems(allPlans.map((id) => ({ variantId: id, quantity: 1, attributes: [] })))
			get().addItems([
				{
					variantId: plan.variantId,
					quantity: 1,
					attributes: [],
					sellingPlanId: plan.sellingPlanId,
				},
			])
			trackCartMembershipUpdate(plan.title)
		},

		redirectToCheckout: (router?: AppRouterInstance) => {
			const { setShowInterstitial, cart } = get()
			const currentRegion = api.getState().settings.currentRegion

			const discountCode = api.getState().promo.discountCode

			const allPrices = api.getState().prices.allPrices
			const priceManager = api.getState().prices.priceManager
			const fullCartItems = getFullCartItems(cart, PriceManager.getRegionCurrency(currentRegion), allPrices, priceManager)

			try {
				setShowInterstitial(true)
				set({ updatingCart: true }, undefined, 'cart/redirectToCheckout')
				trackInitiateCheckout(fullCartItems)

				const region = currentRegion
				const pathname = createCheckoutAndRedirect(region, discountCode, fullCartItems)
				if (pathname) {
					if (router) {
						router.push(pathname)
					} else {
						window.location.href = pathname
					}
				}
			} catch (error) {
				amAppCheckoutError(`Error redirecting to checkout: ${error}`)
			}

			api.getState().shop.setGoingToCheckout(false)
		},
	}))

	if (typeof window !== 'undefined') {
		window.addEventListener('refreshCartPersisted', slice.eventHandler)

		window.onpageshow = () => {
			setTimeout(() => window.dispatchEvent(new Event('refreshCartPersisted')), 500)
		}
	}

	return slice
}

const checkForCurrentShopPageItemInCart = (
	itemVariants: (number | string)[],
	{ coverShop, currentRegion, productManager }: { coverShop: ProductShopDataType; currentRegion: RegionShort; productManager: ProductManager }
) => {
	if (typeof window !== 'undefined' && window.location.pathname.includes('product/')) {
		const shop = mapUrlToShop(window.location.pathname)
		const shopData = getShopPageProducts(coverShop, currentRegion, productManager, shop)

		for (const product of shopData.products) {
			const variants = Object.values(product.variants)
			for (const variant of variants) {
				if (itemVariants.includes(variant.id)) {
					return true
				}
			}
		}
	}
	return false
}
