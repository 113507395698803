// Event names moved from timerevents/eventNames.tsx
export const emailCapModalTriggerDone = 'emailCapModalTriggered'
export const emailCapShortcutButtonTriggerDone = 'emailCapShortcutButtonTriggerDone'

export const secondaryEmailCapModalTriggerDone = 'secondaryEmailCapModalTriggered'

export const tertiaryEmailCapModalTriggerDone = 'tertiaryEmailCapModalTriggered'

export const assistedSaleModalTriggerDone = 'assistedSaleModalEventTriggered'

export const referralWelcomeTriggerDone = 'referralWelcomeTriggered'

export const lastEventTime = 'last_modal_time'

// Array of paths that should be excluded from email capture modal
// These paths are stored without trailing slashes and will have slashes added when returned
const ecapExcludedPages = [
	'checkout/contact',
	'checkout/payment',
	'referral',
	'international',
	'member-accessories',
	'manuals',
	'accessibility',
	'return-policy',
	'warranty',
	'press',
	'product/premium-mattress',
	'member-referrals',
	'brooke',
	'product',
]

/**
 * Returns an array of excluded pages for email capture modal with consistent slash formatting
 * Each path will start with a slash and end with a slash
 */
export const getEcapExcludedPages = () => {
	return ecapExcludedPages.map((page) => `/${page}/`)
}
