import { useCurrency } from 'stores/prices'
import { useStore } from 'stores/StoreProvider'
import { RegionShort } from 'utils/internationalization'
import {
	createCheckoutAndRedirect,
	getCartBaseUpgrades,
	getCartDiscountedMattresses,
	getCartHasMembership,
	getCartHasMemberUpgradeInCart,
	getCartHasPodOrCover,
	getCartHasSleepElixir,
	getCartHasTechCover,
	getCartItemNames,
	getCartItemVariants,
	getCartNumAccessories,
	getCartNumBundles,
	getCartNumCovers,
	getCartNumItems,
	getCartNumPods,
	getCartNumPodsAndCovers,
	getCartNumPodsAndCoversLines,
	getCartSleepElixirLineItems,
	getCartSubtotal,
	getCartTotal,
	getCombinedCartItemsForAbTest,
	getFullCartItems,
	getIndexOfCurrentMembership,
	getMostExpensivePodOrCoverInCart,
} from './cart.utils'

/**
 * @deprecated Debug only
 */
export const useCartStoreDump = () => {
	const cart = useStore((state) => state.cart.cart)
	const isCartOpen = useStore((state) => state.cart.isCartOpen)
	const showInterstitial = useStore((state) => state.cart.showInterstitial)
	const updatingCart = useStore((state) => state.cart.updatingCart)

	return {
		cart,
		isCartOpen,
		showInterstitial,
		updatingCart,
	}
}

export const useCartTotal = () => {
	const cart = useStore((state) => state.cart.cart)
	const currency = useCurrency()
	const allPrices = useStore((state) => state.prices.allPrices)
	const priceManager = useStore((state) => state.prices.priceManager)

	return getCartTotal(getFullCartItems(cart, currency, allPrices, priceManager))
}

export const useCartSubtotal = () => {
	const cart = useStore((state) => state.cart.cart)
	const currency = useCurrency()
	const allPrices = useStore((state) => state.prices.allPrices)
	const priceManager = useStore((state) => state.prices.priceManager)

	return getCartSubtotal(getFullCartItems(cart, currency, allPrices, priceManager))
}

export const useMostExpensivePodOrCoverInCart = () => {
	const fullCartItems = useGetFullCartItems()
	const cartManager = useStore((state) => state.cart.cartManager)
	return getMostExpensivePodOrCoverInCart(fullCartItems, cartManager)
}

export const useCartNumItems = () => {
	const cartManager = useStore((state) => state.cart.cartManager)
	const cart = useStore((state) => state.cart.cart)
	return getCartNumItems({ cartManager, cart })
}

export const useGetFullCartItems = () => {
	const cart = useStore((state) => state.cart.cart)
	const currency = useCurrency()
	const allPrices = useStore((state) => state.prices.allPrices)
	const priceManager = useStore((state) => state.prices.priceManager)

	return getFullCartItems(cart, currency, allPrices, priceManager)
}

export const useCartItemNames = () => {
	const cartManager = useStore((state) => state.cart.cartManager)
	const fullCartItems = useGetFullCartItems()
	return getCartItemNames(cartManager, fullCartItems)
}

export const useCartItemVariants = () => {
	const cartManager = useStore((state) => state.cart.cartManager)
	const cart = useStore((state) => state.cart.cart)
	return getCartItemVariants({ cartManager, cart })
}

export const useCartHasPodOrCover = () => {
	const cartManager = useStore((state) => state.cart.cartManager)
	const cart = useStore((state) => state.cart.cart)
	return getCartHasPodOrCover({ cartManager, cart })
}

export const useCartHasSleepElixir = () => {
	const cartManager = useStore((state) => state.cart.cartManager)
	const cart = useStore((state) => state.cart.cart)
	return getCartHasSleepElixir({ cartManager, cart })
}

export const useCartSleepElixirLineItems = () => {
	const cartManager = useStore((state) => state.cart.cartManager)
	const cart = useStore((state) => state.cart.cart)
	return getCartSleepElixirLineItems({ cartManager, cart })
}

export const useCartNumPods = () => {
	const cartManager = useStore((state) => state.cart.cartManager)
	const cart = useStore((state) => state.cart.cart)
	return getCartNumPods({ cartManager, cart })
}

export const useCartNumCovers = () => {
	const cartManager = useStore((state) => state.cart.cartManager)
	const cart = useStore((state) => state.cart.cart)
	return getCartNumCovers({ cartManager, cart })
}

export const useCartHasTechCover = () => {
	const cartManager = useStore((state) => state.cart.cartManager)
	const cart = useStore((state) => state.cart.cart)
	return getCartHasTechCover({ cartManager, cart })
}

export const useCartHasMembership = () => {
	const cartManager = useStore((state) => state.cart.cartManager)
	const cart = useStore((state) => state.cart.cart)
	return getCartHasMembership({ cartManager, cart })
}

export const useCartHasMemberUpgradeInCart = () => {
	const cartManager = useStore((state) => state.cart.cartManager)
	const cart = useStore((state) => state.cart.cart)
	return getCartHasMemberUpgradeInCart({ cartManager, cart })
}

export const useCartNumAccessories = () => {
	const cartManager = useStore((state) => state.cart.cartManager)
	const cart = useStore((state) => state.cart.cart)
	return getCartNumAccessories({ cartManager, cart })
}

export const useCartNumBundles = () => {
	const cartManager = useStore((state) => state.cart.cartManager)
	const cart = useStore((state) => state.cart.cart)
	return getCartNumBundles({ cartManager, cart })
}

export const useCartNumPodsAndCovers = () => {
	const cartManager = useStore((state) => state.cart.cartManager)
	const cart = useStore((state) => state.cart.cart)
	return getCartNumPodsAndCovers({ cartManager, cart })
}

export const useCartNumPodsAndCoversLines = () => {
	const cartManager = useStore((state) => state.cart.cartManager)
	const cart = useStore((state) => state.cart.cart)
	return getCartNumPodsAndCoversLines({ cartManager, cart })
}

export const useIndexOfCurrentMembership = () => {
	const cartManager = useStore((state) => state.cart.cartManager)
	const cart = useStore((state) => state.cart.cart)
	return getIndexOfCurrentMembership({ cartManager, cart })
}

export const useActiveCart = () => {
	const cart = useStore((state) => state.cart.cart)
	return cart
}

export const useCartDiscountedMattresses = () => {
	const cartManager = useStore((state) => state.cart.cartManager)
	const cart = useStore((state) => state.cart.cart)
	return getCartDiscountedMattresses({ cartManager, cart })
}

export const useCartBaseUpgrades = () => {
	const cartManager = useStore((state) => state.cart.cartManager)
	const cart = useStore((state) => state.cart.cart)
	return getCartBaseUpgrades({ cartManager, cart })
}

export const useCombinedCartItemsForAbTest = () => {
	const fullCartItems = useGetFullCartItems()
	const cartManager = useStore((state) => state.cart.cartManager)
	return getCombinedCartItemsForAbTest(cartManager, fullCartItems)
}

export const useCreateCheckoutAndRedirect = (region: RegionShort, discountCode: string) => {
	const fullCartItems = useGetFullCartItems()
	return createCheckoutAndRedirect(region, discountCode, fullCartItems)
}
