import { datadogReportError } from 'events/datadog'
import { safeError } from 'events/error.utils'

export const trackEmailSubscribe = (email: string): void => {
	try {
		window['uetq'] ??= new Array<
			| 'set'
			| {
					pid: {
						em: string
						ph?: string
					}
			  }
		>()
		window['uetq'].push('set', {
			pid: {
				em: email,
				// 'ph': phoneNumberVariable
			},
		})
	} catch (e: unknown) {
		datadogReportError(safeError(e), 'Bing Email Subscribe', { email })
	}
}
