import { ACCESSORIES_FILTER } from 'constants/accessories'
import { AccessoriesList } from 'constants/types'
import { getUnitForRegion } from 'constants/units'
import cloneDeep from 'lodash.clonedeep'
import { getRegionPod4DefaultKey, getRegionPod4UltraDefaultKey } from 'prices'
import { PriceManager, SUBSCRIPTION_REGION_MAP, SubscriptionKeys } from 'prices/priceManager'
import { Currency } from 'prices/types'
import { coverImperial, coverMetric, coverPerfectImperial, coverPerfectMetric, getFeaturesWithDimensions } from 'products/productFeatures'
import { allProductsList } from 'products/utils'
import { regionIsEU, RegionShort } from 'utils/internationalization'
import * as productCatalog from './allProducts'
import * as productFeatures from './productFeatures'
import {
	airLiteComforterImages,
	carbonAirPillowImages,
	foundationImages,
	mattressImages,
	pod3CoverImages,
	pod4CartridgeImages,
	podProCoverOriginalImages,
	podProCoverPerfectImages,
	podSheetDarkGraySetImages,
	podSheetSetImages,
	protectorImages,
	sleepEssentialBundleImages,
	theBaseImages,
} from './productImages'
import { ProductCore, ProductShopDataType, ShopPages } from './types'

export class ProductManager {
	public static cloneProductWithAttributes(product: ProductCore, customAttributes: { [key: string]: string | boolean }) {
		const newProduct = cloneDeep(product) as ProductCore
		if (customAttributes && typeof customAttributes === 'object') {
			newProduct.custom = customAttributes
		}
		return newProduct
	}
	constructor(private readonly priceManager: PriceManager) {}

	public getCompleteVariant(product: ProductCore, variantName: string) {
		if (!(variantName in product.variants)) {
			throw new Error(`Variant name ${variantName} not found in product ${product.name}`)
		}

		// const fullProduct = this.withPrices(product)
		const fullProduct = product
		const variant = fullProduct.variants[variantName]

		return {
			productDescription: fullProduct.description,
			variantName: variant.name,
			productTitle: fullProduct.name,
			variantId: variant.id,
			productId: fullProduct.id,
			handle: fullProduct.handle,
			sellingPlanId: fullProduct.sellingPlanId ?? null,
		}
	}

	public getBaseShopProducts(region: RegionShort) {
		const isUk = region === 'uk'
		const isEu = regionIsEU(region)
		const isCA = region === 'ca'
		const isAU = region === 'au'
		const isSE = region === 'se'
		const isDK = region === 'dk'
		const isAE = region === 'ae'
		const isCH = region === 'ch'
		const isMX = region === 'mx'

		const data: ProductShopDataType = {
			products: [productCatalog.TheBaseProducts.TheBase],
			features: [],
			images: [theBaseImages],
			defaultProductSelected: 0,
			variantSelectComponentType: 'cards',
			variantSelectLabel: 'Size',
			dropdownItems: [
				{
					title: 'US Sizes',
					items: [
						{ value: 'queen', label: 'Queen' },
						{ value: 'king', label: 'King' },
						{ value: 'caliking', label: 'Cali King' },
					],
					defaultVariantSelected: 'queen',
				},
			],
		}

		if (isCA) {
			data.dropdownItems = [
				{
					title: 'Canada Sizes',
					items: [
						{ value: 'caqueen', label: 'CA Queen' },
						{ value: 'caking', label: 'CA King' },
					],
					defaultVariantSelected: 'caqueen',
				},
			]
		}
		if (isUk) {
			data.dropdownItems = [
				{
					title: 'UK Sizes',
					items: [
						{ value: 'uksuperking', label: 'UK Super King' },
						{ value: 'ukemperor', label: 'UK Emperor' },
					],
					defaultVariantSelected: 'ukking',
				},
			]
		}
		if (isEu) {
			data.dropdownItems = [
				{
					title: 'Europe Sizes',
					items: [
						{ value: 'eusuperking', label: 'Super King' },
						{ value: 'euemperor', label: 'Emperor' },
					],
					defaultVariantSelected: 'eudouble',
				},
			]
		}
		if (isAU) {
			data.dropdownItems = [
				{
					title: 'Australian Sizes',
					items: [
						{ value: 'auqueen', label: 'AU Queen' },
						{ value: 'auking', label: 'AU King' },
					],
					defaultVariantSelected: 'auqueen',
				},
			]
		}
		if (isSE) {
			data.dropdownItems = [
				{
					title: 'Swedish Sizes',
					items: [
						{ value: 'sesuperking', label: 'Super King' },
						{ value: 'seemperor', label: 'Emperor' },
					],
					defaultVariantSelected: 'sesuperking',
				},
			]
		}
		if (isDK) {
			data.dropdownItems = [
				{
					title: 'Danish Sizes',
					items: [
						{ value: 'dksuperking', label: 'Super King' },
						{ value: 'dkemperor', label: 'Emperor' },
					],
					defaultVariantSelected: 'dksuperking',
				},
			]
		}
		if (isAE) {
			data.dropdownItems = [
				{
					title: 'UAE Sizes',
					items: [
						{ value: 'aeking', label: 'King' },
						{ value: 'aeemperor', label: 'Emperor' },
					],
					defaultVariantSelected: 'aeking',
				},
			]
		}
		if (isCH) {
			data.dropdownItems = [
				{
					title: 'Swiss Sizes',
					items: [
						{ value: 'chsuperking', label: 'Super King' },
						{ value: 'chemperor', label: 'Emperor' },
					],
					defaultVariantSelected: 'chsuperking',
				},
			]
		}
		if (isMX) {
			data.dropdownItems = [
				{
					title: 'Mexican Sizes',
					items: [
						{ value: 'mxqueen', label: 'MX Queen' },
						{ value: 'mxking', label: 'MX King' },
					],
					defaultVariantSelected: 'mxqueen',
				},
			]
		}
		return data
	}

	public getSleepElixirShopProducts() {
		const data: ProductShopDataType = {
			products: [productCatalog.SupplementProducts.SleepElixir],
			features: [],
			images: [theBaseImages],
			defaultProductSelected: 0,
			variantSelectComponentType: 'cards',
			variantSelectLabel: 'Options',
			dropdownItems: [
				{
					title: 'Options',
					items: [
						{ value: 'standard', label: 'One month supply' },
						{ value: 'monthly', label: 'Monthly' },
					],
					defaultVariantSelected: 'standard',
				},
			],
		}
		return data
	}

	public getCoverShopProducts(region: RegionShort) {
		const isUk = region === 'uk'
		const isEu = regionIsEU(region)
		const isCA = region === 'ca'
		const isAU = region === 'au'
		const isSE = region === 'se'
		const isDK = region === 'dk'

		const data: ProductShopDataType = {
			products: [productCatalog.PodCoverProducts.Pod2ProCoverStandard, productCatalog.PodCoverProducts.Pod3CoverStandard, productCatalog.PodCoverProducts.Pod3CoverPerfect],
			features: [
				{ title: 'Product features', items: getFeaturesWithDimensions(coverImperial, productFeatures.cover2StandardFeatures) },
				{ title: 'Product features', items: getFeaturesWithDimensions(coverImperial, productFeatures.cover3Features) },
				{ title: 'Product features', items: getFeaturesWithDimensions(coverPerfectImperial, productFeatures.cover3PerfectFeatures) },
			],
			images: [podProCoverOriginalImages, pod3CoverImages, podProCoverPerfectImages],
			defaultProductSelected: 2,
			modelSelectComponentType: 'cards',
			modelSelectProps: {
				labels: [
					{
						title: 'Pod 2 Cover',
						description: `Fits ${getUnitForRegion('10"-11"', region)} mattresses`,
						badgeText: null,
						id: 'pod_pro_cover',
					},
					{
						title: 'Pod 3 Cover',
						description: `Fits ${getUnitForRegion('10"-11"', region)} mattresses. Now with increased comfort and improved tracking`,
						badgeText: null,
						id: 'pod_3_cover',
					},
					{
						title: 'Pod 3 Cover with PerfectFit',
						description: `Fits all beds. Now with increased comfort and improved tracking`,
						badgeText: 'Most Popular',
						id: 'pod_3_cover_perf',
					},
				],
				legendLabel: 'Select Cover type',
			},
			variantSelectComponentType: 'dropdown',
			variantSelectLabel: 'Size',
			dropdownItems: [
				{
					title: 'US Sizes',
					items: [
						{ value: 'full', label: 'Full' },
						{ value: 'queen', label: 'Queen' },
						{ value: 'king', label: 'King' },
						{ value: 'caliking', label: 'Cali King' },
					],
					defaultVariantSelected: 'queen',
				},
			],
			shipping: {
				full: ['Canada', 'UK'],
				queen: ['Canada', 'UK'],
				king: ['Canada', 'UK'],
				caliking: ['UK'],
				eulargedouble: ['EU'],
				eudouble: ['EU'],
				eusuperking: ['EU'],
			},
			whatsInTheBoxSrc: 'https://eightsleep.imgix.net/pod-cover-winb.png?v=1671044162',
		}

		// data.images = data.images.map(() => podProCoverMembershipImages)
		if (isCA) {
			data.dropdownItems = [
				{
					title: 'Canada Sizes',
					items: [
						{ value: 'cafull', label: 'CA Full' },
						{ value: 'caqueen', label: 'CA Queen' },
						{ value: 'caking', label: 'CA King' },
					],
					defaultVariantSelected: 'caqueen',
				},
			]
			data.features = [
				{ title: 'Product features', items: getFeaturesWithDimensions(coverMetric, productFeatures.cover2StandardFeatures) },
				{ title: 'Product features', items: getFeaturesWithDimensions(coverMetric, productFeatures.cover3Features) },
				{ title: 'Product features', items: getFeaturesWithDimensions(coverPerfectMetric, productFeatures.cover3PerfectFeatures) },
			]
		}
		if (isUk) {
			data.dropdownItems = [
				{
					title: 'UK Sizes',
					items: [
						{ value: 'ukdouble', label: 'UK Double (135x190\xA0cm)' },
						{ value: 'ukking', label: 'UK King (150x200\xA0cm)' },
						{ value: 'uksuperking', label: 'UK Super King (180x200\xA0cm)' },
					],
					defaultVariantSelected: 'ukdouble',
				},
				{
					title: 'US Sizes',
					items: [
						{ value: 'full', label: 'US Full', sublabel: '(Not available in UK)' },
						{ value: 'queen', label: 'US Queen', sublabel: '(Not available in UK)' },
						{ value: 'king', label: 'US King', sublabel: '(Not available in UK)' },
						{ value: 'caliking', label: 'US Cali King', sublabel: '(Not available in UK)' },
					],
					defaultVariantSelected: 'queen',
				},
			]
			data.features = [
				{ title: 'Product features', items: getFeaturesWithDimensions(coverMetric, productFeatures.cover2StandardFeatures) },
				{ title: 'Product features', items: getFeaturesWithDimensions(coverMetric, productFeatures.cover3Features) },
				{ title: 'Product features', items: getFeaturesWithDimensions(coverPerfectMetric, productFeatures.cover3PerfectFeatures) },
			]
		}
		if (isEu) {
			data.dropdownItems = [
				{
					title: 'Europe Sizes',
					items: [
						{ value: 'eudouble', label: 'Double (135x190\xA0cm)' },
						{ value: 'eulargedouble', label: 'Large Double (160x200\xA0cm)' },
						{ value: 'eusuperking', label: 'Super King (180x200\xA0cm)' },
					],
					defaultVariantSelected: 'eudouble',
				},
				{
					title: 'US Sizes',
					items: [
						{ value: 'full', label: 'US Full', sublabel: '(Not available in Europe)' },
						{ value: 'queen', label: 'US Queen', sublabel: '(Not available in Europe)' },
						{ value: 'king', label: 'US King', sublabel: '(Not available in Europe)' },
						{ value: 'caliking', label: 'US Cali King', sublabel: '(Not available in Europe)' },
					],
					defaultVariantSelected: 'queen',
				},
			]
			data.features = [
				{ title: 'Product features', items: getFeaturesWithDimensions(coverMetric, productFeatures.cover2StandardFeatures) },
				{ title: 'Product features', items: getFeaturesWithDimensions(coverMetric, productFeatures.cover3Features) },
				{ title: 'Product features', items: getFeaturesWithDimensions(coverPerfectMetric, productFeatures.cover3PerfectFeatures) },
			]
		}
		if (isAU) {
			data.dropdownItems = [
				{
					title: 'Australian Sizes',
					items: [
						{ value: 'audouble', label: 'AU Double' },
						{ value: 'auqueen', label: 'AU Queen' },
						{ value: 'auking', label: 'AU King' },
					],
					defaultVariantSelected: 'auqueen',
				},
				{
					title: 'US Sizes',
					items: [
						{ value: 'full', label: 'US Full', sublabel: '(Not available in Europe)' },
						{ value: 'queen', label: 'US Queen', sublabel: '(Not available in Europe)' },
						{ value: 'king', label: 'US King', sublabel: '(Not available in Europe)' },
						{ value: 'caliking', label: 'US Cali King', sublabel: '(Not available in Europe)' },
					],
					defaultVariantSelected: 'queen',
				},
			]
			data.features = [
				{ title: 'Product features', items: getFeaturesWithDimensions(coverMetric, productFeatures.cover2StandardFeatures) },
				{ title: 'Product features', items: getFeaturesWithDimensions(coverMetric, productFeatures.cover3Features) },
				{ title: 'Product features', items: getFeaturesWithDimensions(coverPerfectMetric, productFeatures.cover3PerfectFeatures) },
			]
		}
		if (isSE) {
			data.dropdownItems = [
				{
					title: 'Swedish Sizes',
					items: [
						{ value: 'sedouble', label: 'Double (135x190\xA0cm)' },
						{ value: 'seking', label: 'Large Double (160x200\xA0cm)' },
						{ value: 'sesuperking', label: 'Super King (180x200\xA0cm)' },
					],
					defaultVariantSelected: 'seking',
				},
				{
					title: 'US Sizes',
					items: [
						{ value: 'full', label: 'US Full', sublabel: '(Not available in Europe)' },
						{ value: 'queen', label: 'US Queen', sublabel: '(Not available in Europe)' },
						{ value: 'king', label: 'US King', sublabel: '(Not available in Europe)' },
						{ value: 'caliking', label: 'US Cali King', sublabel: '(Not available in Europe)' },
					],
					defaultVariantSelected: 'queen',
				},
			]
			data.features = [
				{ title: 'Product features', items: getFeaturesWithDimensions(coverMetric, productFeatures.cover2StandardFeatures) },
				{ title: 'Product features', items: getFeaturesWithDimensions(coverMetric, productFeatures.cover3Features) },
				{ title: 'Product features', items: getFeaturesWithDimensions(coverPerfectMetric, productFeatures.cover3PerfectFeatures) },
			]
		}
		if (isDK) {
			data.dropdownItems = [
				{
					title: 'Danish Sizes',
					items: [
						{ value: 'dkdouble', label: 'Double (135x190\xA0cm)' },
						{ value: 'dkking', label: 'Large Double (160x200\xA0cm)' },
						{ value: 'dksuperking', label: 'Super King (180x200\xA0cm)' },
					],
					defaultVariantSelected: 'dkking',
				},
				{
					title: 'US Sizes',
					items: [
						{ value: 'full', label: 'US Full', sublabel: '(Not available in Europe)' },
						{ value: 'queen', label: 'US Queen', sublabel: '(Not available in Europe)' },
						{ value: 'king', label: 'US King', sublabel: '(Not available in Europe)' },
						{ value: 'caliking', label: 'US Cali King', sublabel: '(Not available in Europe)' },
					],
					defaultVariantSelected: 'queen',
				},
			]
			data.features = [
				{ title: 'Product features', items: getFeaturesWithDimensions(coverMetric, productFeatures.cover2StandardFeatures) },
				{ title: 'Product features', items: getFeaturesWithDimensions(coverMetric, productFeatures.cover3Features) },
				{ title: 'Product features', items: getFeaturesWithDimensions(coverPerfectMetric, productFeatures.cover3PerfectFeatures) },
			]
		}
		return data
	}

	public getAccessoriesProducts(region: RegionShort): AccessoriesList[] {
		const isUk = region === 'uk'
		const isEu = regionIsEU(region)
		const isCA = region === 'ca'
		const isAU = region === 'au'
		const isAE = region === 'ae'
		const isCH = region === 'ch'
		const isMX = region === 'mx'

		if (isUk || isEu || isAU || isAE || isCH || isMX) {
			return []
		}

		if (isCA) {
			return [
				{
					name: ACCESSORIES_FILTER.MadeForPod,
					items: [
						{
							...this.getCompleteVariant(productCatalog.MattressProducts.ThreeLayer, 'caqueen'),
							badge: '',
							productTitle: 'Mattress',
							productDescription: ['Premium mattress designed for the Pod'],
							productImage: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir-v2/Export+-+Mattress.png',
							buttonTo: '/product/premium-mattress',
						},
					],
				},
			]
		}

		const items = [
			{
				name: ACCESSORIES_FILTER.MadeForPod,
				items: [
					{
						...this.getCompleteVariant(productCatalog.MattressProducts.FiveLayerDiscounted, 'queen'),
						badge: '',
						productTitle: 'Mattress',
						productDescription: ['Premium mattress designed for the Pod'],
						productImage: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir-v2/Export+-+Mattress.png',
						buttonTo: '/product/premium-mattress',
					},
					{
						...this.getCompleteVariant(productCatalog.BundleProducts.SleepEssentialBundle, 'full'),
						badge: '',
						productDescription: ['Completes your perfect Pod setup'],
						productImage: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir-v2/Export+-+Sleep+Essentials+Bundle.png',
						buttonTo: '/product/the-sleep-essentials-bundle',
						saveText: 'Save ###',
					},
					{
						...this.getCompleteVariant(productCatalog.ProtectorProducts.PodProtector, 'queen'),
						productDescription: ['Water and dust-proof protector'],
						productImage: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir-v2/Export+-+Pod+Protector.png',
						buttonTo: '/product/waterproof-protector',
					},
					{
						...this.getCompleteVariant(productCatalog.SheetProducts.SheetSet, 'full'),
						productDescription: ['Percale weave, moisture wicking'],
						productImage: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir-v2/Export+-+Sheet+Set.png',
						productImageDark: 'https://eightsleep.imgix.net/sheets-03.png?v=1695051873',
						buttonTo: '/product/the-pod-sheet-set',
					},
					{
						...this.getCompleteVariant(productCatalog.PillowProducts.CarbonAirPillow, 'onepack'),
						productDescription: ['Premium memory foam pillow'],
						productImage: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir-v2/Export+-+Air+Pillow.png',
						buttonTo: '/product/the-air-pillow',
						badge: 'Best seller',
					},
					{
						...this.getCompleteVariant(productCatalog.ComforterProducts.AirLiteComforter, 'queen'),
						productDescription: ['Breathable and soft comforter'],
						productImage: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir-v2/Export+Air+Lite+Comforter.png',
						buttonTo: '/product/air-lite-comforter',
					},
				],
			},
			{
				name: ACCESSORIES_FILTER.WindDown,
				items: [
					// {
					// 	...this.getCompleteVariant(productCatalog.CanalProducts.CanopyHumidifier, 'white'),
					// 	productDescription: ['A completely reimagined humidifier that promotes healthy skin and alleviates symptoms of cold and flu.'],
					// 	productImage: 'https://eightsleep.imgix.net/canopy.webp?v=1658850509',
					// 	buttonTo: '/product/canopy-humidifier',
					// },
				],
			},
			{
				name: ACCESSORIES_FILTER.BoostYourRecovery,
				items: [],
			},
		]

		return items
	}

	public getShopPageProducts(url: ShopPages, region: RegionShort): ProductShopDataType {
		let data: ProductShopDataType
		switch (url) {
			case 'sleep-elixir':
				data = this.getSleepElixirShopProducts()
				return data
			case 'the-base':
				data = this.getBaseShopProducts(region)
				return data
			case 'pod4-insert':
				data = {
					products: [productCatalog.CartridgeProducts.Pod4Cartridge],
					features: null,
					images: [pod4CartridgeImages],
					defaultProductSelected: 0,
					variantSelectComponentType: 'cards',
					variantSelectLabel: 'Size',
					dropdownItems: [],
				}
				return data
			case 'cover':
				data = this.getCoverShopProducts(region)
				return data
			case 'pillow':
				data = {
					products: [productCatalog.PillowProducts.CarbonAirPillow, productCatalog.PillowProducts.CarbonAirPillowKing],
					features: [
						{ title: 'About the Air Pillow?', items: productFeatures.pillowFeatures },
						{ title: 'About the Air Pillow?', items: productFeatures.kingPillowFeatures },
					],
					images: [carbonAirPillowImages, carbonAirPillowImages],
					defaultProductSelected: 1,
					modelSelectComponentType: 'radio_buttons',
					modelSelectProps: {
						labels: [
							{
								title: 'Queen',
								id: 'queen',
							},
							{
								title: 'King',
								id: 'king',
							},
						],
						legendLabel: 'Size',
					},
					variantSelectComponentType: 'cards',
					variantSelectLabel: 'Quantity',
					dropdownItems: [
						{
							title: 'US Sizes',
							items: [
								{ value: 'onepack', label: 'One Pack' },
								{ value: 'twopack', label: 'Two Pack' },
							],
							defaultVariantSelected: 'twopack',
						},
					],
				}
				return data
			case 'foundation':
				data = {
					products: [productCatalog.FoundationProducts.SimpleFoundation],
					features: null,
					images: [foundationImages],
					defaultProductSelected: 0,
					variantSelectComponentType: 'cards',
					variantSelectLabel: 'Size',
					dropdownItems: [
						{
							title: 'US Sizes',
							items: [
								{ value: 'full', label: 'Full' },
								{ value: 'queen', label: 'Queen' },
								{ value: 'king', label: 'King' },
								{ value: 'caliking', label: 'Cali King' },
							],
							defaultVariantSelected: 'queen',
						},
					],
				}
				return data
			case 'sheets':
				data = {
					products: [productCatalog.SheetProducts.SheetSet, productCatalog.SheetProducts.SheetSetGray],
					features: [
						{ title: 'About the Pod Sheet Set', items: productFeatures.sheetsFeatures },
						{ title: 'About the Pod Sheet Set', items: productFeatures.sheetsGrayFeatures },
					],
					images: [podSheetSetImages, podSheetDarkGraySetImages],
					defaultProductSelected: 0,
					modelSelectComponentType: 'color_picker',
					modelSelectProps: {
						labels: [
							{
								title: 'White',
								id: 'white',
								description: null,
								badgeText: null,
								color: '#f6f6f6',
							},
							{
								title: 'Gray',
								id: 'gray',
								description: null,
								badgeText: null,
								color: '#b8b8b8',
							},
						],
						legendLabel: 'Select your size',
					},
					variantSelectComponentType: 'cards',
					variantSelectLabel: 'Select a pack',
					dropdownItems: [
						{
							title: 'US Sizes',
							items: [
								{ value: 'full', label: 'Full' },
								{ value: 'queen', label: 'Queen' },
								{ value: 'king', label: 'King' },
								{ value: 'caliking', label: 'Cali King' },
							],
							defaultVariantSelected: 'queen',
						},
					],
				}
				return data
			case 'comforter':
				data = {
					products: [productCatalog.ComforterProducts.AirLiteComforter],
					features: null,
					images: [airLiteComforterImages],
					defaultProductSelected: 0,
					variantSelectComponentType: 'cards',
					variantSelectLabel: 'Size',
					dropdownItems: [
						{
							title: 'US Sizes',
							items: [
								{ value: 'full', label: 'Full' },
								{ value: 'queen', label: 'Queen' },
								{ value: 'king', label: 'King' },
								{ value: 'caliking', label: 'Cali King' },
							],
							defaultVariantSelected: 'queen',
						},
					],
				}
				return data
			case 'protector':
				data = {
					products: [productCatalog.ProtectorProducts.PodProtector],
					features: null,
					images: [protectorImages],
					defaultProductSelected: 0,
					variantSelectComponentType: 'cards',
					variantSelectLabel: 'Size',
					dropdownItems: [
						{
							title: 'US Sizes',
							items: [
								{ value: 'full', label: 'Full' },
								{ value: 'queen', label: 'Queen' },
								{ value: 'king', label: 'King' },
								{ value: 'caliking', label: 'Cali King' },
							],
							defaultVariantSelected: 'queen',
						},
					],
				}
				return data
			case 'essentials-bundle':
				data = {
					products: [productCatalog.BundleProducts.SleepEssentialBundle],
					features: null,
					images: [sleepEssentialBundleImages],
					defaultProductSelected: 0,
					variantSelectComponentType: 'cards',
					variantSelectLabel: 'Size',
					dropdownItems: [
						{
							title: 'US Sizes',
							items: [
								{ value: 'full', label: 'Full' },
								{ value: 'queen', label: 'Queen' },
								{ value: 'king', label: 'King' },
								{ value: 'caliking', label: 'Cali King' },
							],
							defaultVariantSelected: 'king',
						},
					],
				}
				return data
			case 'premium-mattress':
				data = {
					products: [region === 'ca' ? productCatalog.MattressProducts.ThreeLayer : productCatalog.MattressProducts.FiveLayerDiscounted],
					features: null,
					images: [mattressImages],
					defaultProductSelected: 0,
					variantSelectComponentType: 'cards',
					variantSelectLabel: 'Size',
					dropdownItems: [
						region === 'ca'
							? {
									title: 'CA Sizes',
									items: [
										{ value: 'cafull', label: 'CA Full' },
										{ value: 'caqueen', label: 'CA Queen' },
										{ value: 'caking', label: 'CA King' },
									],
									defaultVariantSelected: 'caqueen',
							  }
							: {
									title: 'US Sizes',
									items: [
										{ value: 'full', label: 'Full' },
										{ value: 'queen', label: 'Queen' },
										{ value: 'king', label: 'King' },
										{ value: 'caliking', label: 'Cali King' },
									],
									defaultVariantSelected: 'queen',
							  },
					],
				}
				return data
			default:
				data = this.getCoverShopProducts(region)
				return data
		}
	}

	public getPageProducts(url: string, region: RegionShort, saleName: string): AccessoriesList[] {
		const memberPage: AccessoriesList[] = [
			{
				name: ACCESSORIES_FILTER.NewPodTechnology,
				items: [
					{
						...this.getCompleteVariant(productCatalog.PodCoverProducts.Pod4, getRegionPod4DefaultKey(region)),
						buttonTo: '/product/pod4',
						productDescription: [`Gift the latest Pod to yourself (or a loved one)`],
						mobileDescription: [`Gift the latest Pod to yourself (or a loved one)`],
						ctaText: region === '' || region === 'ca' ? `Up to %%% off` : `Up to ### off`,
						badge: `Members ${saleName}`,
						large: true,
						productImage: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/bfcm_2024/accessories/pod4.png',
					},
					{
						...this.getCompleteVariant(productCatalog.PodCoverProducts.Pod4Ultra, getRegionPod4UltraDefaultKey(region)),
						buttonTo: '/product/pod-cover',
						productDescription: [`Gift the latest Pod to yourself (or a loved one)`],
						mobileDescription: [`Gift the latest Pod to yourself (or a loved one)`],
						ctaText: region === '' || region === 'ca' ? `Up to %%% off` : `Up to ### off`,
						badge: `Members ${saleName}`,
						large: true,
						productImage: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/bfcm_2024/accessories/pod4ultra.png',
					},
				],
			},
			{
				name: ACCESSORIES_FILTER.TheBase,
				items: [
					{
						...this.getCompleteVariant(productCatalog.TheBaseProducts.TheBase, getRegionPod4UltraDefaultKey(region)),
						buttonTo: '/product/the-base',
						productDescription: [`Gift the latest Pod to yourself (or a loved one)`],
						mobileDescription: [`Gift the latest Pod to yourself (or a loved one)`],
						ctaText: region === '' || region === 'ca' ? `Up to %%% off` : `Up to ### off`,
						badge: `Members ${saleName}`,
						large: true,
						productImage: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir-v2/Export+-+Base.png',
					},
				],
			},
			...this.getAccessoriesProducts(region),
		]

		const accessoriesPage: AccessoriesList[] = [
			{
				name: ACCESSORIES_FILTER.NewPodTechnology,
				items: [
					{
						...this.getCompleteVariant(productCatalog.TheBaseProducts.TheBase, getRegionPod4UltraDefaultKey(region)),
						productTitle: 'Base: Pod 4 add-on ',
						buttonTo: '/product/the-base',
						badge: '',
						productDescription: [`Gift the latest Pod to yourself (or a loved one)`],
						mobileDescription: [`Gift the latest Pod to yourself (or a loved one)`],
						ctaText: region === '' || region === 'ca' ? `Up to %%% off` : `Up to ### off`,
						large: true,
						productImage: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir-v2/Export+-+Base.png',
					},
					{
						...this.getCompleteVariant(productCatalog.SupplementProducts.SleepElixir, 'monthly'),
						buttonTo: '/product/sleep-elixir',
						badge: '',
						productDescription: [`Sleep Elixir helps you ease into sleep, faster, by leveraging over 600 million hours of sleep data and 100% natural ingredients. `],
						mobileDescription: [`Sleep Elixir helps you ease into sleep, faster, by leveraging over 600 million hours of sleep data and 100% natural ingredients. `],
						large: true,
						productImage: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir-v2/Export+-+Sleep+Elixir.png',
						priceSuffix: '/mo',
						productTitle: 'Sleep Elixir',
					},
				],
			},
			...this.getAccessoriesProducts(region),
		]

		switch (url) {
			case 'member-accessories':
				return memberPage
			case 'accessories':
				return accessoriesPage
			default:
				return []
		}
	}

	public getProductFromID(productID: string | number): ProductCore | null {
		for (const product of allProductsList) {
			if (product.id === productID.toString()) {
				return product
			}
		}
		return null
	}

	public getCartDescription(product: ProductCore, currency: Currency): string[] {
		const formattedDescription: string[] = []
		for (const line of product.cartDescription || []) {
			const append = ''

			if (SUBSCRIPTION_REGION_MAP[product.handle] && SUBSCRIPTION_REGION_MAP[product.handle][PriceManager.getCurrencyRegion(currency)]) {
				const membershipKey = product.handle as SubscriptionKeys
				const price = PriceManager.getCurrencySubscriptionPrice(membershipKey, currency)
				const text = (append + line).replaceAll('XX', price)
				formattedDescription.push(text)
			} else {
				formattedDescription.push(append + line)
			}
		}
		return formattedDescription
	}

	public getDefaultVariantForRegion(region: RegionShort) {
		if (region === 'au') {
			return 'auqueen'
		} else if (regionIsEU(region)) {
			return 'eudouble'
		} else if (region === 'ca') {
			return 'caqueen'
		} else if (region === 'uk') {
			return 'ukdouble'
		}
		return 'queen'
	}
}
