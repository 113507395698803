import { lens } from '@dhmk/zustand-lens'
import { PriceManager } from 'prices/priceManager'
import { getRegionCover, getRegionMattress, getRegionPod4, getRegionPod4Ultra, getRegionSleepEssentialsBundle } from 'prices/utils'
import { getCartHasPodOrCover } from 'stores/cart/cart.utils'
import { RegionShort } from 'utils/internationalization'
import { RootState } from '../root.types'
import { PriceSlice } from './prices.types'
import { ProductPriceMap } from 'prices/types'

export const createPriceSlice = (initRegion: RegionShort, initPriceManager: PriceManager, initAllPrices: ProductPriceMap) => {
	const newCoverPrices = initPriceManager.getVariantPrices(initAllPrices, getRegionCover(initRegion).id)
	const newPod4Prices = initPriceManager.getVariantPrices(initAllPrices, getRegionPod4(initRegion).id)
	const newPod4UltraPrices = initPriceManager.getVariantPrices(initAllPrices, getRegionPod4Ultra(initRegion).id)
	const newPodPrices = initPriceManager.getVariantPrices(initAllPrices, getRegionMattress(initRegion)?.id)
	const newSleepEssentialsBundlePrices = initPriceManager.getVariantPrices(initAllPrices, getRegionSleepEssentialsBundle(initRegion)?.id)

	return lens<PriceSlice, RootState>((set, get, api) => ({
		priceManager: initPriceManager,

		setPriceManager: (priceManager: PriceManager) => {
			set({ priceManager }, undefined, 'prices/setPriceManager')
		},

		allPrices: initAllPrices,
		coverPrices: newCoverPrices,
		pod4Prices: newPod4Prices,
		pod4UltraPrices: newPod4UltraPrices,
		podPrices: newPodPrices,
		sleepEssentialsBundlePrices: newSleepEssentialsBundlePrices,

		syncPrices: () => {
			const { priceManager } = get()

			const currentRegion = api.getState().settings.currentRegion
			const globalPromoCode = api.getState().settings.globalPromoCode
			const hasPodOrCover = getCartHasPodOrCover(api.getState().cart)
			const discountCode = api.getState().promo.discountCode

			priceManager.setAccessoriesDiscount(hasPodOrCover && discountCode !== 'members')

			const newAllPrices = priceManager.applyDiscountToAll(discountCode, globalPromoCode, currentRegion)

			api.getState().shop.setPodShopData()

			set(
				{
					allPrices: newAllPrices,
					coverPrices: priceManager.getVariantPrices(newAllPrices, getRegionCover(currentRegion).id),
					pod4Prices: priceManager.getVariantPrices(newAllPrices, getRegionPod4(currentRegion).id),
					pod4UltraPrices: priceManager.getVariantPrices(newAllPrices, getRegionPod4Ultra(currentRegion).id),
					podPrices: priceManager.getVariantPrices(newAllPrices, getRegionMattress(currentRegion)?.id),
					sleepEssentialsBundlePrices: priceManager.getVariantPrices(newAllPrices, getRegionSleepEssentialsBundle(currentRegion)?.id),
				},
				undefined,
				'prices/syncPrices'
			)
		},
	}))
}
