import { lens } from '@dhmk/zustand-lens'
import { LineItem } from 'cart'
import { SubscriptionResponse } from 'components/_utils/subscriptionUtils'
import { ModalKey } from 'components/Phantom/_shop/Modals/modalMapping'
import { SelectorKey } from 'components/Phantom/_shop/Selectors'
import { sendTrackingEvents } from 'components/WebEv/Shop/context/checkoutUtils'
import { getErrorMessage } from 'components/WebEv/Shop/utils/errorMessages'
import { amCheckoutWithFragile, amTrackBaseAddedToCheckout, amTrackBaseSelected } from 'events/amplitude'
import { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime'
import { createCartLineItem, isPodBedProductId, isPodCoverProductId, OFFERED_MEMBERSHIPS } from 'products/utils'
import { PaymentOption, SelectionKey, Selections } from 'shop/Shop.types'
import { getCartHasMembership, getCartHasMemberUpgradeInCart, getCartNumCovers, getCartNumPods, getIndexOfCurrentMembership } from 'stores/cart/cart.utils'
import { getCurrency, getPrices } from 'stores/prices/prices.utils'
import {
	getCurrentSelectionEntries,
	getCurrentSelectors,
	getDefaultProductId,
	getFilteredUpsellSelections,
	getPodVariant,
	getSelectedUpsells,
	getSelectedVariant,
	getShopPricedProduct,
	getShouldHideAutopilotSection,
	getStripeLineItems,
	shouldShowShopBanner,
	trackViewShopPage,
} from '.'
import { RootState } from '../root.types'
import { PodModel, PriceStoreData, ShopSlice } from './shop.types'

export const createShopSlice = (initData: { pathname: string; priceStoreData: PriceStoreData }) => {
	const initialProductId = getDefaultProductId(initData.pathname)
	const initialShowShopBanner = shouldShowShopBanner(initData.pathname)

	const saved = typeof window !== 'undefined' ? sessionStorage.getItem('subscriptionInformation') : undefined
	const savedSubscriptionInformation = saved ? JSON.parse(saved) : undefined

	const initialPodData = getShopPricedProduct(initialProductId, initData.priceStoreData)
	const initialSelectedVariant = getSelectedVariant(initData.priceStoreData.currency, undefined)
	const initHighlightedSelector = getCurrentSelectors(initialProductId, 'cash')[0]

	const store = lens<ShopSlice, RootState>((set, get, api) => ({
		paymentOption: 'cash' as PaymentOption,
		podData: initialPodData,
		selectedSelections: {
			'pod-model': initialProductId,
			'leg-kit': 'none',
			'auto-pilot': 'autopilot_enhanced',
			'essentials-bundle': undefined,
			'eight-mattress': undefined,
			'the-base': undefined,
			size: initialSelectedVariant,
		},
		error: null,
		activeModal: '' as ModalKey,
		highlightedSelector: initHighlightedSelector,
		subscriptionInformation: savedSubscriptionInformation,
		goingToCheckout: false,
		showShopBanner: initialShowShopBanner,

		setPaymentOption: (paymentOption: PaymentOption) => set({ paymentOption }, undefined, 'shop/setPaymentOption'),
		setShowShopBanner: (showShopBanner: boolean) => set({ showShopBanner }, undefined, 'shop/setShowShopBanner'),
		setGoingToCheckout: (goingToCheckout: boolean) => set({ goingToCheckout }, undefined, 'shop/setGoingToCheckout'),

		setSelectedSelections: (selections: Partial<Selections>) => set({ selectedSelections: { ...get().selectedSelections, ...selections } }, undefined, 'shop/setSelectedSelections'),
		setPodShopData: () => {
			const currentRegion = api.getState().settings.currentRegion
			const currency = getCurrency(currentRegion)
			const priceManager = api.getState().prices.priceManager
			const allPrices = api.getState().prices.allPrices
			const selectedPodModel = get().selectedSelections['pod-model']

			const product = getShopPricedProduct(selectedPodModel, { priceManager, allPrices, currency })
			const productID = product.id as PodModel
			const productVariants = product.variants
			const selectedVariant = getSelectedVariant(currency, get().selectedSelections['size'])

			if (!productVariants[selectedVariant]) {
				set(
					{
						selectedSelections: {
							...get().selectedSelections,
							size: undefined,
						},
					},
					undefined,
					'shop/setSelectedSelections'
				)
			}

			set(
				{
					podData: product,
					selectedSelections: { ...get().selectedSelections, 'pod-model': productID },
				},
				undefined,
				'shop/setPodShopData'
			)
		},

		handleSelectionChange: (selectorKey: SelectorKey, selectionKey: SelectionKey, optionKey: string) => {
			set(
				{
					selectedSelections: {
						...get().selectedSelections,
						[selectionKey]: optionKey,
					},
				},
				undefined,
				'shop/handleSelectionChange'
			)
			get().setPodShopData()

			const currentRegion = api.getState().settings.currentRegion

			if (selectorKey === 'size' || selectorKey === 'pod-model') {
				trackViewShopPage({
					pod4UltraPrices: getPrices(api.getState().prices.pod4UltraPrices, currentRegion),
					model: get().selectedSelections['pod-model'],
					selectedVariant: getSelectedVariant(getCurrency(currentRegion), get().selectedSelections['size']),
					currentRegion,
					globalPromoCode: api.getState().settings.globalPromoCode,
					podData: get().podData,
				})
			}

			if (selectionKey === 'the-base' && optionKey === 'standard') {
				amTrackBaseSelected()
			}

			if (get().error && get().error.associatedSelectionId === selectionKey) {
				// TODO, there could be more finesse to be made here, depending on how we structure the error interface.
				//  Right now, the assumption is that if a selection is made on a section that has an error, we should probably remove the error.
				set({ error: null }, undefined, 'shop/handleSelectionChange')
			}

			if (selectorKey !== get().highlightedSelector) {
				// If the selector that changed is not the highlighted selector (i.e. the one closest to the viewport)
				// we should scroll to the selector that changed

				// Disable this behavior on mobile due to the lack of screen real-estate.
				// We don't want to constantly be shoving the user away from product imagery.
				if (typeof window !== 'undefined' && window.innerWidth < 1024) {
					return
				}

				const currentSelectors = getCurrentSelectors(get().selectedSelections['pod-model'], get().paymentOption)
				const index = currentSelectors.findIndex((selector) => selector === selectorKey)
				if (index !== -1 && typeof window !== 'undefined') {
					const selector = currentSelectors[index]
					const element = document.getElementById(`selector-${selector}`)
					if (element) {
						element.scrollIntoView({ behavior: 'smooth', block: 'center' })
					}
				}
			}
		},

		setHighlightedSelector: (selectorKey: SelectorKey) => {
			set({ highlightedSelector: selectorKey }, undefined, 'shop/setHighlightedSelector')
		},

		setSubscriptionInformation: (info?: SubscriptionResponse) => {
			let newSubscriptionInformation = info
			if (!newSubscriptionInformation && typeof window !== 'undefined') {
				const saved = sessionStorage.getItem('subscriptionInformation')
				newSubscriptionInformation = saved ? JSON.parse(sessionStorage.getItem('subscriptionInformation')) : undefined
			}
			set({ subscriptionInformation: newSubscriptionInformation }, undefined, 'shop/setSubscriptionInformation')
			get().setPodShopData()
		},

		checkout: async (sourceId: string, router?: AppRouterInstance) => {
			const allRequiredSteps = get().checkRequiredSelections()
			if (!allRequiredSteps) return

			set({ goingToCheckout: true }, undefined, 'shop/checkout')

			// TODO this is a huuuge chain of interwoven store logic that I had to unwrap.
			// We could probably do this much better.
			const podData = get().podData
			const selectedSelections = get().selectedSelections
			const currentRegion = api.getState().settings.currentRegion
			const subscriptionInformation = get().subscriptionInformation
			const priceManager = api.getState().prices.priceManager
			const allPrices = api.getState().prices.allPrices

			const currency = getCurrency(currentRegion)
			const selectedVariant = getSelectedVariant(currency, selectedSelections['size'])
			const podVariant = getPodVariant(podData, selectedVariant)

			const selections = getCurrentSelectionEntries(selectedSelections)
			const shouldHideAutopilotSection = getShouldHideAutopilotSection(subscriptionInformation)

			const filteredSelections = getFilteredUpsellSelections(selections, selectedSelections, shouldHideAutopilotSection)
			const selectedUpsells = getSelectedUpsells(filteredSelections, selectedSelections, podData, { priceManager, allPrices, currency })

			const itemsToAdd: LineItem[] = getStripeLineItems(podVariant, subscriptionInformation, selectedUpsells, selectedVariant)
			const itemsToRemove: LineItem[] = []

			const addedMembership = true
			const cartManager = api.getState().cart.cartManager
			const cart = api.getState().cart.cart

			if (getCartHasMembership({ cartManager, cart })) {
				const index = getIndexOfCurrentMembership({ cartManager, cart })
				const currentMembership = OFFERED_MEMBERSHIPS()[index]
				if (currentMembership) {
					itemsToRemove.push({
						variantId: currentMembership.variants.standard.id,
						sellingPlanId: currentMembership.sellingPlanId,
						quantity: 1,
						attributes: [],
					})
				}
			}

			sendTrackingEvents(createCartLineItem(podVariant, podData, { addMembership: addedMembership }), sourceId)

			const isValidPod = isPodBedProductId(podData.id)
			const isValidCover = isPodCoverProductId(podData.id)
			const hasMemberUpgrade = getCartHasMemberUpgradeInCart({ cartManager, cart })
			const isValidPodOrCoverAndNotUpgrade = (isValidPod || isValidCover) && !hasMemberUpgrade

			const hasBaseUpgrade = get().selectedSelections['the-base'] === 'standard'
			if (hasBaseUpgrade) {
				amTrackBaseAddedToCheckout()
			}

			if (get().paymentOption === 'fragile') {
				const selectedVariant = getSelectedVariant(getCurrency(api.getState().settings.currentRegion), get().selectedSelections['size'])
				const size = selectedVariant === 'caliking' ? 'cali-king' : selectedVariant
				const currentPromoCode = api.getState().promo.discountCode?.toUpperCase()
				let fragileURL = `https://rent.eightsleep.com/eightsleep/checkout?collectionId=pod4-${size}`
				if (currentPromoCode) {
					fragileURL += `&esCode=${currentPromoCode}`
				}
				amCheckoutWithFragile({
					size,
					currentPromoCode,
				})
				window.location.href = fragileURL
				return
			}

			api.getState().cart.removeItems(itemsToRemove)
			await api.getState().cart.addItems(itemsToAdd)
			{
				const cartManager = api.getState().cart.cartManager
				const cart = api.getState().cart.cart

				const cartHasMembership = getCartHasMembership({ cartManager, cart })
				const cartNumCovers = getCartNumCovers({ cartManager, cart })
				const cartNumPods = getCartNumPods({ cartManager, cart })

				if (isValidPodOrCoverAndNotUpgrade && cartHasMembership && cartNumCovers < 2 && cartNumPods < 2) {
					api.getState().cart.redirectToCheckout(router)
				} else {
					api.getState().cart.toggleCartOpen()
				}
			}
			set({ goingToCheckout: false }, undefined, 'shop/checkout')
		},

		checkRequiredSelections: () => {
			const requiredSelectionIDs: SelectionKey[] = ['size', 'auto-pilot']

			const selectionsMade = Object.keys(get().selectedSelections)
			const missingSelections = requiredSelectionIDs.filter((id) => !selectionsMade.includes(id) || get().selectedSelections[id] === undefined)

			if (missingSelections.length !== 0) {
				const missingSelection = missingSelections[0]
				const missingSelectionElement = document.getElementById(`selector-${missingSelection}`)
				if (missingSelectionElement) {
					missingSelectionElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
				}
				set({ error: { associatedSelectionId: missingSelection, errorText: `${getErrorMessage(missingSelection)}` } }, undefined, 'shop/checkRequiredSelections')
				return false
			}

			return true
		},

		selectModal: (modalKey: ModalKey) => {
			set({ activeModal: modalKey }, undefined, 'shop/selectModal')
		},

		closeModal: () => {
			set({ activeModal: '' }, undefined, 'shop/closeModal')
		},
	}))

	return store
}
