import { CartLineItem } from 'cart/types'
import { PaymentMethodID } from 'components/Checkout2023/types'
import { SITE_DOMAIN } from 'constants/index'
import { datadogReportEvent } from 'events/datadog'
import { safeError } from 'events/error.utils'
import { PodCoverProducts } from 'products/allProducts'
import { getProductCategory } from 'products/productPages'
import { stripePodCoverIds } from 'products/utils'
import { FragilePilotPrices } from 'shop/utils/fragilePilotPrices'
import Stripe from 'stripe'
import { TAX_PRODUCT } from 'stripe_lib'
import { addEventToLocalStorage, getAllEventsFromLocalStorage } from 'utils/events'
import { formatURL } from 'utils/formatters'

const KLAVIYO_EVENT_STORE = 'klaviyoEvents'

enum KlaviyoEventType {
	IDENTIFY = 'identify',
	TRACK = 'track',
}

export const sendQueuedKlaviyoEvents = (email: string): void => {
	const utmParamMap: Record<string, string> = {
		utm_source: 'utm_source_subscribe_session',
		utm_medium: 'utm_medium_subscribe_session',
		utm_campaign: 'utm_campaign_subscribe_session',
		utm_content: 'utm_content_subscribe_session',
		utm_term: 'utm_term_subscribe_session',
	}
	const userUtms: Record<string, string> = {}

	if (typeof window === 'undefined') {
		return
	}

	try {
		// identify the user by email
		window.klaviyo?.unshift(['identify', { $email: email }])
		// parse utms from current session storage
		for (const utm of Object.keys(utmParamMap)) {
			const utmValue = sessionStorage.getItem(utm)
			if (utmValue !== null && utmValue.length > 0) {
				const klaviyoProfileProperty = utmParamMap[utm]
				userUtms[klaviyoProfileProperty] = utmValue
				sessionStorage.removeItem(utm)
			}
		}

		// if they have utms from current session, send to klaviyo
		if (Object.entries(userUtms).length > 0) {
			window.klaviyo?.push(['identify', userUtms])
		}

		// send queued events from localstorage
		const queuedEvents = getAllEventsFromLocalStorage('klaviyoEvents')
		if (queuedEvents) {
			queuedEvents.forEach((event) => {
				window.klaviyo?.push(event)
			})
			// remove the store once the events are sent
			localStorage.removeItem(KLAVIYO_EVENT_STORE)
		}
		datadogReportEvent('success', 'Klaviyo - Queued Events', queuedEvents)
	} catch (e) {
		datadogReportEvent('failure', 'Klaviyo - Queued Events', safeError(e))
	}
}

export const klaviyoTrackLatestCart = (cartString: string): void => {
	klaviyoSetUserProperties({ last_cart: cartString })
}

export const klaviyoTrackQuizAnswers = (quizData: Record<string, unknown>): void => {
	try {
		const email = localStorage.getItem('email')
		klaviyoEvent(KlaviyoEventType.TRACK, 'Ecap Quiz', quizData)
		window.klaviyo?.push(['identify', { $email: email, ...quizData }])
	} catch (e) {
		datadogReportEvent('failure', 'Klaviyo - Track Quiz Answers', safeError(e))
	}
}

export const klaviyoTrackSleepQuizAnswers = ({
	email,
	sleepPersona,
	quizData,
	firstName,
	partnerName,
	code,
	amount,
	locale,
	sleepPattern,
	timeToFallAsleep,
	sleepDuration,
}: {
	email: string
	sleepPersona: string
	quizData: Record<string, unknown>
	firstName: string
	partnerName: string
	code: string
	amount: string
	locale: string
	sleepPattern?: string
	timeToFallAsleep?: string
	sleepDuration?: string
}): void => {
	try {
		klaviyoEvent(KlaviyoEventType.TRACK, 'Sleep Quiz', {
			...quizData,
			sleepPersona,
			firstName,
			partnerName,
			code,
			amount,
			locale,
			sleepPattern,
			timeToFallAsleep,
			sleepDuration,
		})
		window.klaviyo?.push([
			'identify',
			{
				$email: email,
				sleepPersona,
				firstName,
				partnerName,
				code,
				amount,
				locale,
				sleepPattern,
				timeToFallAsleep,
				sleepDuration,
				...quizData,
			},
		])
	} catch (e) {
		datadogReportEvent('failure', 'Klaviyo - Track Sleep Quiz Answers', safeError(e))
	}
}

const klaviyoSetUserProperties = (userProperties: Record<string, unknown>): void => {
	klaviyoEvent(KlaviyoEventType.IDENTIFY, null, userProperties)
}

const klaviyoEvent = (eventType: KlaviyoEventType, eventName: string | null, eventProperties?: Record<string, unknown>): void => {
	if (typeof window === 'undefined') {
		return
	}
	let event = null

	if (eventType === KlaviyoEventType.TRACK) {
		event = eventProperties ? [eventType, eventName, eventProperties] : [eventType, eventName]
	} else if (eventType === KlaviyoEventType.IDENTIFY) {
		event = [eventType, eventProperties]
	} else {
		datadogReportEvent('failure', 'Klaviyo - Missing Event Type', {
			message: 'Missing klaviyo event type',
		})
		return
	}

	// if the user has an email, identify the user and send to klaivyo; otherwise, save in localStorage
	try {
		const email = localStorage.getItem('email')
		if (email === null) {
			addEventToLocalStorage(KLAVIYO_EVENT_STORE, event)
		} else {
			window.klaviyo?.push(['identify', { $email: email }])
			window.klaviyo?.push(event)
		}
		datadogReportEvent('success', eventName ?? 'identify', event)
	} catch (e) {
		datadogReportEvent('failure', eventName ?? 'identify', safeError(e))
	}
}

export const kyTriggerCartAbandonedFlowHandler = (): void => {
	const email = localStorage.getItem('email')
	const cartId = sessionStorage.getItem('klaviyo_cart_id')
	if (email !== null && cartId !== null) {
		klaviyoEvent(KlaviyoEventType.TRACK, 'Trigger Cart Abandoned Flow')
	}
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const kyEmailAvailableHandler = (email: string, location: string): void => {
	sendQueuedKlaviyoEvents(email)
	kyTriggerCartAbandonedFlowHandler()
}

export const kyClickedFAQ = (question: string): void => {
	klaviyoEvent(KlaviyoEventType.TRACK, 'Click - FAQ - The Pod', { Question: question })
}

export const kyVistedReturnPolicyPage = (): void => {
	klaviyoEvent(KlaviyoEventType.TRACK, 'Visited - /return-policy/')
}

export const kyVistedWarrantyPage = (): void => {
	klaviyoEvent(KlaviyoEventType.TRACK, 'Visited - /warranty/')
}

export const kyVistedReviewsPage = (): void => {
	klaviyoEvent(KlaviyoEventType.TRACK, 'Visited - /reviews/')
}

// btoa(unescape(encodeURIComponent(str)))
export const kyTrackShippingAddress = (addressObj: Record<string, string>): void => {
	const userProperties = {
		s_firstName: btoa(addressObj['firstName']),
		first_name: addressObj['firstName'],
		s_lastName: btoa(addressObj['lastName']),
		s_address1: btoa(addressObj['address1']),
		s_address2: btoa(addressObj['address2']),
		s_city: btoa(addressObj['city']),
		s_province: btoa(addressObj['province']),
		s_country: btoa(addressObj['country']),
		s_phone: btoa(addressObj['phone']),
		s_zip: btoa(addressObj['zip']),
	}
	klaviyoSetUserProperties(userProperties)
}

export const kyViewedMenopauseCampaign = (): void => {
	const userProperties = {
		menopauseCampaignVisitor: true,
	}
	klaviyoEvent(KlaviyoEventType.IDENTIFY, null, userProperties)
	klaviyoEvent(KlaviyoEventType.TRACK, 'Trigger Menopause Flow')
}

export const kyAddToCart = (item: CartLineItem): void => {
	const _item = {
		...item,
		price: (Math.round(item.price) / 100).toFixed(2),
		line_price: (Math.round(item.price) / 100).toFixed(2),
		product_url: SITE_DOMAIN + window.location.pathname,
	}

	klaviyoEvent(KlaviyoEventType.TRACK, 'Added Item to Cart', { Item: _item })
}

export const kyPageView = (): void => {
	try {
		klaviyoEvent(KlaviyoEventType.TRACK, 'Page View', { URL: formatURL(window.location.href) })
	} catch (e) {
		datadogReportEvent('failure', 'Klaviyo - Page View', safeError(e))
	}
}

export const emailCapShown = (): void => {
	try {
		klaviyoEvent(KlaviyoEventType.TRACK, 'Blog Ecap View', { URL: formatURL(window.location.href) })
	} catch (e) {
		datadogReportEvent('failure', 'Klaviyo - Email Cap Shown', safeError(e))
	}
}

export const trackT1EcapSignUp = (email: string, quote: string, image: string, name: string, code: string, link: string): void => {
	try {
		const event = { quote, image, name, code, link }
		klaviyoEvent(KlaviyoEventType.TRACK, 'Vanity Ecap Signup', event)
		window.klaviyo?.push(['identify', { $email: email }])
	} catch (e) {
		datadogReportEvent('failure', 'Klaviyo - Track T1 Ecap Signup', safeError(e))
	}
}

export const klaviyoTrackLatestStripeCart = (email: string, invoice: Stripe.UpcomingInvoice, currency: string, paymentMethod: PaymentMethodID): void => {
	try {
		const cartString = invoice.lines.data.map((li) => `${li.price?.id ?? ''}:${li.quantity ?? 0}`).join(',')
		const truemedSelected = paymentMethod === 'truemed'
		const financingSelected = paymentMethod === 'affirm' || paymentMethod === 'klarna'
		const isMembershipName = (s: string | null): boolean => {
			if (s === null) {
				return false
			}
			const n = s.toLowerCase()
			return n.includes('membership') || n.includes('autopilot')
		}

		const membership = invoice.lines.data.find((li) => li.plan) || invoice.lines.data.find((li) => isMembershipName(li.description))

		let membershipType = 'none'
		if (membership) {
			membershipType = membership.description?.toLocaleLowerCase().includes('enhanced') ?? false ? 'enhanced' : 'standard'
		}

		const checkoutData = {
			truemedSelected: truemedSelected,
			financingSelected: financingSelected,
			membershipType: membershipType,
			totalCartValue: invoice.total,
			checkoutUrl: `https://www.eightsleep.com/cart2?items=${cartString}`,
			stripe_cart: cartString,
			currency: currency?.toLowerCase(),
			products: invoice.lines.data.map((item) => {
				return {
					id: (item.price?.product as Stripe.Product).id,
					name: item.description,
					size: item.price?.nickname,
					quantity: item.quantity,
					imageUrl: (item.price?.product as Stripe.Product).images[0],
					category: getProductCategory(item.description ?? ''),
				}
			}),
			product_names: invoice.lines.data.map((item) => item.description),
			product_ids: invoice.lines.data.map((item) => (item.price?.product as Stripe.Product).id),
			line_items: invoice.lines.data.map((item) => `${item.description ?? ''} - ${item.price?.nickname ?? ''}`),
			totalDiscount: invoice.total_discount_amounts?.filter((it) => it.amount > 0).reduce((acc, curr) => acc + curr.amount, 0),
		}

		klaviyoEvent(KlaviyoEventType.TRACK, 'Stripe Checkout - Viewed', checkoutData)
		window.klaviyo?.push(['identify', { $email: email }])
	} catch (e) {
		datadogReportEvent('failure', 'Klaviyo - Track Stripe Checkout', safeError(e))
	}
}

export const klaviyoTrackStripeCheckoutInit = (): void => {
	try {
		if (typeof window !== 'undefined' && localStorage.getItem('email') !== null) {
			klaviyoEvent(KlaviyoEventType.TRACK, 'Stripe Checkout - Initialized')
			window.klaviyo?.push(['identify', { $email: localStorage.getItem('email') }])
		}
	} catch (e) {
		datadogReportEvent('failure', 'Klaviyo - Track Stripe Checkout Init', safeError(e))
	}
}

export const klaviyoTrackStripePurchaseComplete = (email: string, invoiceId: string): void => {
	try {
		klaviyoEvent(KlaviyoEventType.TRACK, 'Stripe Checkout - Completed')
		window.klaviyo?.push(['identify', { $email: email, invoiceId: invoiceId }])
	} catch (e) {
		datadogReportEvent('failure', 'Klaviyo - Track Stripe Checkout Purchase Complete', safeError(e))
	}
}

const invoiceItemsToRestResponse = (items: Stripe.ApiList<Stripe.InvoiceLineItem>): Record<string, unknown>[] => {
	return items.data.map((item) => {
		return {
			id: item.id,
			amont: item.amount,
			amount_excluding_tax: item.amount_excluding_tax,
			currency: item.currency,
			description: item.description,
			discount_amounts: item.discount_amounts,
			discounts: item.discounts,
			plan: item.plan
				? {
						id: item.plan.id,
						amount: item.plan.amount,
						billing_scheme: item.plan.billing_scheme,
						currency: item.plan.currency,
						interval: item.plan.interval,
						interval_count: item.plan.interval_count,
						product: item.plan.product,
				  }
				: null,
			lookup_key: item.price?.lookup_key,
			price_metadata: item.price?.metadata,
			nickname: item.price?.nickname,
			product_id: (item.price?.product as Stripe.Product).id,
			price_id: item.price?.id,
			// This is a weird type. I don't want to break production, so I'm casting it around, but for some reason
			// TS says "Product" doesn't have an "attributes" property. But it does, at least, according to the API docs.
			// See here: https://docs.stripe.com/api/products/object#product_object-attributes
			product_attributes: (item.price?.product as unknown as { attributes: Record<string, string> }).attributes,
			images: (item.price?.product as Stripe.Product).images,
			product_metadata: (item.price?.product as Stripe.Product).metadata,
			unit_amount: item.price?.unit_amount,
			quantity: item.quantity,
			tax_amounts: item.tax_amounts,
			tax_rates: item.tax_rates,
		}
	})
}

const getFragileMonthlyPrice = (items: Stripe.InvoiceLineItem[]): number => {
	const BASE_PRODUCT = PodCoverProducts.Pod4.variants
	const VARIANTS = ['full', 'queen', 'king', 'caliking']

	for (const variant of VARIANTS) {
		const price = items.find((item) => item.price?.id === BASE_PRODUCT[variant as keyof typeof BASE_PRODUCT]?.id)
		if (price && variant in FragilePilotPrices) {
			return FragilePilotPrices[variant as keyof typeof FragilePilotPrices] ?? 0
		}
	}
	return 0
}

export const invoiceToRestResponse = (invoice: Stripe.Invoice | Stripe.UpcomingInvoice): Record<string, unknown> => {
	const items = invoiceItemsToRestResponse(invoice.lines)
	const pod = items.find((item) => stripePodCoverIds.includes(item.price_id as string))
	let podTimeline = ''

	if (pod && invoice.metadata && 'shippingTimeline2' in invoice.metadata) {
		try {
			const json = JSON.parse(invoice.metadata.shippingTimeline2) as Record<string, string>
			const t = Object.values(json)[0]
			podTimeline = `${pod.description as string} ${t}`
		} catch (e) {
			datadogReportEvent('failure', 'Klaviyo - Invoice To Rest Response', safeError(e))
		}
	}

	podTimeline = podTimeline.replace('Ships', 'ships by')

	const result: Record<string, unknown> = {
		id: 'id' in invoice ? invoice.id : null,
		order_number: invoice.number,
		pod_timeline: podTimeline,
		order_confirmation_url:
			'id' in invoice ? 'https://www.eightsleep.com/thank_you/' + invoice.id : '',
		amount_due: invoice.amount_due / 100,
		amount_shipping: invoice.amount_shipping / 100,
		created: invoice.created,
		currency: invoice.currency,
		custom_fields: invoice.custom_fields,
		customer_address: invoice.customer_address,
		customer_email: invoice.customer_email,
		customer_name: invoice.customer_name,
		customer_phone: invoice.customer_phone,
		customer_shipping: invoice.customer_shipping,
		customer_billing: (
			(invoice.payment_intent as Stripe.PaymentIntent)?.latest_charge as Stripe.Charge
		)?.billing_details,
		discounts: invoice.discounts,
		metadata: invoice.metadata,
		items: items.map((item) => {
			const i: Record<string, unknown> = {
				...item,
				amont: (item.amount as number) / 100,
				amount_excluding_tax: (item.amount_excluding_tax as number) / 100,
				unit_amount: (item.unit_amount as number) / 100,
			}
			const priceId = item.price_id as string
			const metaKey = `${priceId}_timeline`
			if (invoice !== null && invoice.metadata !== null && metaKey in invoice.metadata) {
				i.shippingTimeline = invoice.metadata[metaKey]
			}
			return i
		}),
		customer: (invoice.customer as Stripe.Customer)?.id,
		client_secret: (invoice.payment_intent as Stripe.PaymentIntent)?.client_secret,
		shipping_cost: invoice.shipping_cost,
		shipping_details: invoice.shipping_details,
		status: invoice.status,
		subscription: invoice.subscription,
		subscription_details: invoice.subscription_details,
		subtotal: invoice.subtotal / 100,
		subtotal_excluding_tax: (invoice.subtotal_excluding_tax ?? 0) / 100,
		total: invoice.total / 100,
		total_discount_amounts: invoice.total_discount_amounts,
		total_discount: invoice.total_discount_amounts !== null ? invoice.total_discount_amounts.reduce((acc, curr) => acc + curr.amount, 0) / 100 : 0,
		total_excluding_tax: (invoice.total_excluding_tax ?? 0) / 100,
		total_tax_amounts: invoice.total_tax_amounts,
		replacement: invoice.metadata !== null && 'replacement' in invoice.metadata ? invoice.metadata.replacement === 'true' : false,
		fragile: invoice.metadata !== null && 'fragile_order_id' in invoice.metadata,
		fragile_monthly_price: getFragileMonthlyPrice(invoice.lines.data),
	}

	const taxItem = invoice.lines.data.find((it) => it.price?.product === TAX_PRODUCT)
	if (taxItem !== undefined && taxItem.amount > 0) {
		result.tax = taxItem.amount / 100
	}

	return result
}
