'use client'

import { getNormalizedURL } from 'components/_utils/urlUtils'
import { vanityConfig } from 'config/vanity'
import { useParams, usePathname } from 'next/navigation'
import { PriceManager } from 'prices/priceManager'
import { ProductPriceMap } from 'prices/types'
import { createContext, FC, PropsWithChildren, useContext, useRef } from 'react'
import { RegionShort } from 'utils/internationalization'
import { useStore as zustandUseStore } from 'zustand'
import ProductPrices from '../product_prices.json'
import { getCurrency } from './prices/prices.utils'
import { RootState } from './root.types'
import { processSiteSettings } from './settings/settings.utils'
import { createStore } from './useStore'

export type StoreAPI = ReturnType<typeof createStore>

export const StoreContext = createContext<StoreAPI | undefined>(undefined)

export const StoreProvider: FC<PropsWithChildren> = ({ children }) => {
	const pathname = usePathname()
	const params = useParams()
	const storeRef = useRef<StoreAPI>(null)

	if (!storeRef.current) {
		const { locale, t1 } = params
		const localeParam = (locale ?? '') as RegionShort
		const t1Param = t1 as string | undefined
		const currentRegion = localeParam === 'us' ? '' : localeParam

		const prices = ProductPrices as ProductPriceMap
		const priceManager = new PriceManager(prices)
		const currency = getCurrency(currentRegion)
		const currentPage = getNormalizedURL(currentRegion, pathname, params)

		const referral_session_code = typeof window !== 'undefined' ? sessionStorage.getItem('referral_session_code') : null
		const vanityData = t1Param ? vanityConfig[t1Param] : null
		const siteSettings = processSiteSettings(currentRegion, false, referral_session_code, vanityData)

		const globalPromoCode = siteSettings.globalPromoCode
		const allPrices = priceManager.applyDiscountToAll(siteSettings.siteSettings.promoCode, globalPromoCode, currentRegion)

		storeRef.current = createStore({ currentRegion, priceManager, allPrices, currency, isVanity: false, pathname, currentPage, t1Param, siteSettings })

		typeof window !== 'undefined' &&
			(window['dumpStore'] = () => {
				console.log(storeRef.current?.getState())
			})
	}

	return <StoreContext.Provider value={storeRef.current}>{children}</StoreContext.Provider>
}

export const useStore = <T,>(selector: (state: RootState) => T): T => {
	const context = useContext(StoreContext)

	if (!context) {
		throw new Error('useStore must be used within a StoreProvider')
	}

	return zustandUseStore(context, selector)
}
