import { SelectionKey, Selections } from 'shop/Shop.types'

export const isSelectionKeyUpsell = (key: SelectionKey): boolean => {
	return ['leg-kit', 'auto-pilot', 'essentials-bundle', 'eight-mattress', 'the-base'].includes(key)
}

export const isSelectionAllowed = (key: SelectionKey, selections: Selections, shouldHideAutopilot: boolean) => {
	if (key === 'leg-kit') {
		return selections['pod-model'] === 'pod_4_ultra' || (selections['the-base'] === 'standard' && selections['pod-model'] === 'pod_4')
	}

	if (key === 'the-base') {
		return selections['pod-model'] === 'pod_4'
	}

	if (key === 'auto-pilot' && shouldHideAutopilot) {
		return false
	}

	return true
}
