import { ProductManager } from 'products'
import { ProductShopDataType, ShopPages } from 'products/types'
import { RegionShort } from 'utils/internationalization'

export const getShopPageProducts = (coverShop: ProductShopDataType, currentRegion: RegionShort, productManager: ProductManager, shop: ShopPages) => {
	if (shop === 'cover' || shop === 'pod4' || shop === 'pod4ultra') {
		return coverShop
	}

	return productManager.getShopPageProducts(shop, currentRegion)
}
