import { lens } from '@dhmk/zustand-lens'
import type { RootState } from '../root.types'
import { ProductSlice } from './product.types'
import { RegionShort } from 'utils/internationalization'
import { ProductManager } from 'products'
import { PriceManager } from 'prices'

export const createProductSlice = (region: RegionShort, priceManager: PriceManager) => {
	const productManager = new ProductManager(priceManager)
	const coverShop = productManager.getCoverShopProducts(region)

	return lens<ProductSlice, RootState>((set, get, api) => ({
		activeProduct: null,
		coverShop,
		productManager,

		refreshProducts: () => {
			const coverShop = get().productManager.getCoverShopProducts(region)
			set({ coverShop }, undefined, 'product/refreshProducts')
		},
	}))
}
